.auth-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;

  .loader {
    width: 50px;
    height: 50px;
  }

  p {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
  }
}
