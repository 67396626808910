@import styles/helpers

.wrapper
    width: 100%
    height: 100vh
    place-items: center
    display: grid
    background: $dark

.inner_wrapper
    width: 450px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px

.inner
   display: flex
   flex-direction: column
   align-items: center
   gap: 16px

.progress 
    width: 100%

.title   
    font-weight: 400
    font-size: 24px
    line-height: 32px
    color: $white
