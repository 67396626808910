[class^="button"]
  display: inline-flex
  justify-content: center
  align-items: center
  height: 48px
  padding: 0 24px
  background: $p1
  border-radius: 24px
  +button-1
  text-align: center
  color: $n8
  transition: all .2s

  &:hover
    background: darken($p1, 15)

  &:disabled,
  &.disabled
    opacity: .5
    cursor: not-allowed

  svg
    fill: $n8
    transition: all .2s

  &:not([class^="button-circle"])
    svg
      &:first-child
        margin-right: 12px

      &:last-child
        margin-left: 12px

.btn-light-blue
  background: $blue6per !important
  color: $blue !important

  &:hover,
  &.active
    background: darken($blue6per, 8) !important

.button-stroke
  background: none
  box-shadow: 0 0 0 2px $n6 inset
  color: $n2

  svg
    fill: $n4

  +dark-common
    box-shadow: 0 0 0 2px $n3 inset
    color: $n8
    svg
      fill: $n8

  &:hover,
  &.active
    background: $n2
    box-shadow: 0 0 0 2px $n2 inset
    color: $n8

    svg
      fill: $n8

    +dark-common
      background: $n3
      box-shadow: 0 0 0 2px $n3 inset

.button-black
  background: $n1

  &:hover,
  &.active
    background: $n2

  +dark-common
    background: $n7
    color: $n2
    svg
      fill: $n2
    &:hover,
    &.active
      background: $n6

.button-red
  background: $p3

  &:hover,
  &.active
    background: darken($p3, 8)


.button-red-light
  background: $p3_6 !important
  color: $p3 !important

  &:hover,
  &.active
    background: darken($p3_6, 8) !important

.button-green
  background: $p4 !important

  &:hover,
  &.active
    background: darken($p4, 8) !important

.button-white
  background: $n8
  color: $n4
  border: 1px solid $n6

  svg
    fill: $n4

  &:hover
    background: $n7


.btn-grey
  background: $n20 !important
  color: $n4 !important

  svg
    fill: $n4 !important

  &:hover
    background: darken($n20, 8) !important

.btn-grey-1
  background: $n6 !important
  color: $n18 !important

  svg
    fill: $n18 !important

  &:hover
    background: darken($n6, 8) !important


.btn-light-grey
  background: $n7 !important
  color: $n4 !important

  &:hover,
  &.active
    background: darken($n7, 8) !important

.btn-outline-white
  background: $white !important
  color: $n2 !important

  svg
    fill: $n2 !important

  &:hover
    background: $n7 !important

.btn-outline-green
  background: transparent !important
  color: $p4 !important
  border: 1px solid $p4 !important

  svg
    fill: $p4 !important

.btn-outline-blue
  background: transparent !important
  color: $p1 !important
  border: 1px solid $p1 !important

  svg
    fill: $p1 !important

.button-small
  height: 40px
  border-radius: 20px
  padding: 0 16px
  font-size: 14px

.btn-h-auto
  padding: 6px 15px !important
  height: auto !important
  min-height: auto !important

.button-circle
  flex: 0 0 48px
  width: 48px
  height: 48px
  padding: 0
  border-radius: 50%

.button-circle-stroke
  flex: 0 0 48px
  width: 48px
  height: 48px
  padding: 0
  border-radius: 50%
  box-shadow: 0 0 0 2px $n6 inset
  background: transparent
  transition: all .2s

  svg
    fill: $n4

  &:hover
    background: $n2
    box-shadow: 0 0 0 2px $n2 inset

    svg
      fill: $n8

  +dark-common
    box-shadow: 0 0 0 2px $n3 inset
    &:hover
      background: $n3

.button-circle-stroke.button-small
  flex: 0 0 40px
  width: 40px
  height: 40px
