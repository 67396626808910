.title {
    text-align: center !important;
    line-height: 40px;
}

.description{
    text-align: center !important;
    line-height: 21px;
    font-size: 14px;
}

.loginBtn{
    padding-left: 5px;
}