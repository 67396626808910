@import "../styles/helper.scss";

.ContinueInMobile{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    height: 100vh;
    background:$n31;
    color: $white;
    &__logo{
        width: 64px;    
        height:64px;
        padding: 8px;
        border-radius: 8px;
        background: $n72;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__article{
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        &__subHeading{
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }
        &__continueMobile{
            background: $blue;
            max-width:358px;
            height:48px;
            padding: 12px 20px 12px 20px;
            border-radius:4px;
            background: $blue;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
        }
        &__continueWeb{
            background: $blue;
            width:358px;
            height:48px;
            padding: 12px 20px 12px 20px;
            border-radius:4px;
            background: $n72;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}