@import ../../styles/helpers

.musicChart
    width: 100%
    display: flex
    flex-direction: column
    background: $n8
    border-radius: 8px
    border: 1px solid $n6
    padding: 16px
    gap: 16px
    +dark
        background: $dark
        border-color: $n2
    &_technical
        display: flex
        flex-direction: row
        align-items: center
        gap: 8px
        font-weight: 500
        font-size: 16px
        line-height: 24px
        color: $n10
    &_line
        width: 24px
        height: 4px
        background: $n10
        border-radius: 16px
    &_chart
        width: 100%
        display: flex
        gap: 8px
        height: 500px