// exchange-dashboard {
//   .sidebar-container {
//     // display: none;
//   }
//   .mint-app {
//     grid-template-columns: auto;
//   }

//   grid-template-columns: auto;
//   .ipo-tiles {
//     padding-bottom: 100px;
//   }
//   .header__name {
//     display: none;
//   }
//   .theme_icon {
//     display: none;
//   }
// }
.ipoContainer{
    .ipo-tiles{
        height: calc(100vh - 124px);
    }
}
.ipoContainer{
    .ipo-tiles{
        height: calc(100vh - 124px);
    }
}