@import "../../../../styles/helper";

.transaction-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.25s ease-in;
  padding: 16px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 8px;
  border-bottom: 0.5px solid #CCD2E0;
  background-color: $white;
  border: 1px solid $n6;
  @include dark {
    background-color: $n19;
    border: 1px solid $n12;
  }
  &:hover{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: .4s all ease-in-out;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  // padding: 5px;

  &:hover {
    .transaction-info-type {
      color: #f5af45;
    }
  }

  .transaction-card {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;

    span.icon {
      width: 48px;
      height: 48px;
      border-radius: 50px;
      background: rgba(0, 0, 0, 0.06);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      @include dark {
        background: #ffffff0f;
      }

      i {
        font-size: 28px;
      }

      i.ri-arrow-down-line {
        color: $n13;
      }
      i.ri-arrow-up-line {
        color: $n29;
      }
      .ri-money-dollar-circle-line {
        &.green {
          color: $n13;
        }
        &.red {
          color: $n29;
        }
      }
    }

    .transaction-details {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      gap: 4px;
      padding: 10px 0;

      .transaction-info-status {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .wireTag {
          background: #ED89271F;
          color: #EF9943;
          font-size: 10px;
          font-weight: 500;
          padding: 2px 8px;
          border-radius: 4px;
          line-height: 14px;
          margin-left: 8px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-transform: capitalize;
          display: flex;
          align-items: center;
        }
      }

      .transaction-info-details {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .status-flex {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          display: flex;
          align-items: center;
        }

        .status-dot {
          width: 8px;
          height: 8px;
          border-radius: 24px;
        }

        .processing {
          background-color: $s5;
        }

        .cancelled {
          background-color: $n23;
        }

        .completed {
          background-color: $n13;
        }

        .failure {
          background-color: $n24;
        }
      }
    }
  }

  .transaction-bank-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: $n11;
    }

    .text-capitalize {
      text-transform: capitalize;
    }
  }
}
