@import ../../styles/helpers
.notificationIconContainer
    position: relative
    cursor: pointer
    &__dot
        height: 10px
        width: 10px
        border-radius: 24px
        background: $n13
        position: absolute
        left: 22px
        top: 0px
.notification_icon
    position: relative
    margin-right: 10px
    fill: $n4
    &:hover
      transition: 0.4s all ease-in-out
      svg
      fill: $n2
      +dark
       fill: $n8
    +dark
        fill: $n4

