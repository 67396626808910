@import "./font-size";
@import "./colors";
@import "./border-radius";
@import "./line-heights";
@import "./margin";
@import "./padding";

* {
  font-family: "Poppins", sans-serif !important;
}
