@import "../../../../styles/helper";

.biometric-approval {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dark;
  &__wrapper {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: calc(var(--base-padding) * 3) calc(var(--base-padding) * 2);
    border-radius: calc(var(--base-border-radius) * 0.5);
  }
  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: $white;
  }
  &__description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #878c99;
  }
  &__number {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #e6eaf5;
  }
  &__specification {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #747e99;
  }
  &__mobile-number {
    color: var(--color-primary-dark);
  }

  button {
    background: #282a2e;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    color: #33b87a;
    width: 100%;
  }

  img {
    align-self: center;
    height: 69.34px;
  }

  .resent-link {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #878c99;
    align-self: center;
    i {
      color: #f5c462;
      cursor: pointer;
    }
  }
}
