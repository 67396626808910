@import "../../../../styles/helper";

.biometric-approval-exchange {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  

  .success_screen{
    .biometric-approval__row{
      gap: 12px;
      text-align: center;

      .biometric-approval__title{
        margin-top: -40px;
      }

      img.biometric-approval__success-icon{
        width: 180px;
        height: 180px;
      }
    }
  }

  .biometric-approval__wrapper {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 25px 15px;
    border-radius: calc(var(--base-border-radius) * 0.5);

    .biometric-approval__row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;


      .align-center {
        text-align: center;
      }

      .flex-row{
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        .circle-loader{
          width: fit-content !important;
        }
      }

      .text-center{
        display: block;
        width: 100%;
        text-align: center;
      }

      .biometric-approval__title {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
      }

      .biometric-approval__description {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #878c99;
      }

      .biometric-approval__number {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .biometric-approval__specification {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #747e99;
      }

      .biometric-approval__mobile-number {
        color: var(--color-primary-dark);
      }

      button {
        background: #33b87a;
        border-radius: 8px;
        padding: 16px;
        cursor: pointer;
        color: #FCFCFD;
        width: 100%;
      }

      img {
        align-self: center;
        height: 69.34px;
      }

      .resent-link {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #878c99;
        align-self: center;

        i {
          color: #f5c462;
          cursor: pointer;
        }
      }
    }
  }
}