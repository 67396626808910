@import "../../../../styles/helper.scss";

// Mixin for common styles
@mixin overview-styles {
  color: $blue !important;
  @include dark {
    color: $n10 !important;
  }

  &::before {
    background-color: $blue !important;
    @include dark {
      background-color: $n10 !important;
    }
  }
}

private-assets {
  .nav_link {
    border-bottom: 2px solid transparent !important;
    font-weight: 500 !important;

    &:hover {
      color: $blue !important;
      border-bottom-color: $blue !important;

      @include dark {
        border-bottom-color: $n10 !important;
        color: $n10 !important;
      }
    }
  }

  // Titles and points
  [class*="overview_title"],
  [class*="dataRoom_title"],
  [class*="do_title"],
  [class*="solution_title"],
  [class*="invest_title"],
  [class*="prominent_title"],
  [class*="Media_title"],
  [class*="team_title"],
  [class*="MarketOverview_title"],
  [class*="Traction_title"],
  [class*="problem_title"] {
    @include overview-styles;

    [class*="overview_point"],
    [class*="dataRoom_point"],
    [class*="do_point"],
    [class*="solution_point"],
    [class*="invest_point"],
    [class*="prominent_point"],
    [class*="Media_point"],
    [class*="team_point"],
    [class*="MarketOverview_point"],
    [class*="Traction_point"],
    [class*="problem_point"] {
      @include overview-styles;
    }
  }

  [class*="asset-detail_active"] {
    color: $blue !important;
    border-bottom-color: $blue !important;

    @include dark {
      border-bottom-color: $n10 !important;
      color: $n10 !important;
    }
  }

  .section-slides {
    .title {
      @include overview-styles;
    }
    .point__heading {
      @include overview-styles;
    }
  }
}
.Modal__kyc{
  .asset-details-navbar{
    top:66px;
  }
}
.private-modal-captable{
  .Modal__closeButton{
        position: sticky;
    background-color: #ffffff;
    margin-top: -14px !important;
    float: right;
    height: 75px;
  }
}
