@import "../../styles/helper.scss";

.access-denied {
  @include dark {
    background: #141416;
  }
  .logo1 {
    padding: 0 16px;
    @include dark {
      border-bottom: 1px solid #23262f;
    }
    img {
      height: 100%;
      width: 210px;
    }
  }
  .access--denied {
    max-width: 516px;
    margin: 0 auto;
    text-align: center;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 20px;
      font-weight: 500;
      @include dark {
        color: white;
      }
      margin-top: 20px;
    }
    button {
      @include dark {
        background: #ffffff06;
        color: #bcc3d6;
      }
      font-size: 16px;
      padding: 0 24px;
      height: 52px;
      background: #f0f0f0;
    }
    p {
      color: #a2a7b8;
      font-size: 14px;
      line-height: 1.4;
      margin: 12px 0;
    }
  }
}
