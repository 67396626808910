@import "../../styles/helper";

.notification-icon{
    position: relative;
    margin-right: 20px;
    fill: $n2;
        @include dark {
            fill:$white
        }
}
.notification_container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1;
}
.notifications-tabs {
    display: flex;
    width: 600px;
    border-bottom: 1px solid $n49;
    gap: 10px;
    @include dark{
      border-bottom: 1px solid $n12 !important;
      
    }

    &_item {
      font-size: 14px;
      font-weight: 600;
      color: $n4;
      cursor: pointer;
      padding: 10px 15px;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
      text-transform: capitalize;
      position: sticky;
      &:hover{
        color: $blue;
        border-bottom-color: $blue;
        transition: 0.4s all ease-in-out;
        @include dark{
          border-bottom-color: $n10;
          color: $n10;
        }
      }
      &.active {
        color: $blue;
        border-bottom-color: $blue;
        z-index: 100;
        @include dark{
          border-bottom-color: $n10;
          color: $n10;
        }
      }
    }

    @include dark {
      border-bottom-color: $n51;
    }
  }
.newNotification-container{
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  &__btn{
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 32px;
    height: 24px;
    width: 121px;
    background: $n57;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $n60;
    @include dark{
      color: $n16;
      background:$n12;
    }
  }
  
  
}   
.notification-empty-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 600px;
  height: 80vh;
  gap: 8px;
  }

.infiniteScroll__container{
  overflow-y: scroll;
  flex: 1 1;
  overflow-x: hidden;
  .infinite-scroll-component{
    overflow-x: hidden !important;
  }
}