@import "styles/helper.scss";

.highcharts-axis {
  &.highcharts-xaxis {
    .highcharts-axis-line {
      @include dark {
        stroke: $white;
      }
    }
  }
}
