@import "../../../../../styles/helper";

.auction-loan-form {
  display: grid;
  gap: 24px;

  &__container {
    display: flex;
    border: 1px solid #ccd2e0;
    padding: 8px 16px;
    border-radius: 8px;
    gap: 32px;

    &.disabled-input {
      .auction-loan-form__radio {
        cursor: not-allowed;
      }
      input {
        cursor: not-allowed;
      }
    }
  }

  &__heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #747e99;
    display: grid;
    gap: 4px;
  }

  &__radio {
    display: flex;
    gap: 6px;
    cursor: pointer;
    align-items: start;
    width: 50%;

    input {
      cursor: pointer;
    }
  }

  &__radio-btn {
    margin-top: 6px;
  }

  &__span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__details {
    display: flex;
    flex-direction: column;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      @include dark {
        color: $white;
      }
    }
  }

  &__note {
    background: $n52;
    border: 1px solid $n53;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 15px;
    gap: 17px;

    &.red {
      border: 1px solid $n24;
      background: $n24_12;

      .auction-loan-form__note__bellIcon {
        color: $n24;
      }
    }

    &__bellIcon {
      color: $n53;
      font-size: 20px;
      margin-top: -8px;
    }

    &__text {
      color: $n37;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      @include dark {
        color: $white
      }
    }
  }
}

.auction-loan-table {

  &__container {
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px #0000003d;
    display: flex;
    flex-direction: column;
    max-height: 400px;

    .empty-table {
      height: 200px !important;
    }

    .react-table-responsive-container {
      width: 10px;
      min-width: 100%;
    }

    .react-table-responsive-wrapper {
      overflow: unset;
    }

    .fl-table {
      border-collapse: unset;

      thead th {
        color: #777e90;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        border-block: 1px solid $n6;
        @include dark {
          border-block: 1px solid $n3;
        }
      }

      tbody tr {
        background-color: transparent;

        &.row-color {
          background-color: $n24_12;;
        }
      }
    }

    .react-table-responsive__head-row {
      background-color: #fff !important;
      border-bottom: 1px solid #d9e0f2 !important;
      border-top: 1px solid #d9e0f2;
      @include dark {
        background: $dark !important;
      }
    }

    .react-table-responsive__head tr th {
      overflow-y: scroll;
      text-overflow: unset;
      max-width: unset;
    }

    .text-elipsis {
      max-width: unset !important;
      overflow: unset !important;
      text-overflow: unset !important;
    }
  }

  &__desc {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;

    p {
      color: #1f1f1f;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @include dark {
        color: $white;
      }
    }

    span {
      color: #5c5c5c;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      @include dark {
        color: $white;
      }
    }

    .button {
      font-weight: 500;
      font-size: 14px;
      border-radius: 4px;
      Padding: 12px 16px 12px 16px !important;
    }

  }
}

.auction-loan-file-section {
  display: flex;
  justify-content: space-between;
  gap: 24px;

  &__FileBrowse {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 4px;
  }

  &__heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $n47;

  }

  &__container {
    border: 1px solid $n54;
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    height: 72px;
    padding: 12px 16px;
    border-radius: 4px;

    &__fileLogo {
      background: $n6;
      padding: 8px;
      height: 34px;
      width: 34px;
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include dark {
        background: $n12;
      }

      img {
        width: 22px;
      }
    }

  }

  &__browse {
    padding: 12px 16px;
    border-radius: 4px;
    background: $blue6per;
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    width: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: column;
    cursor: pointer;
  }

  .loanFileInput {
    background-color: transparent;
    opacity: 0;
    height: 1px;
    width: 1px;
    cursor: pointer;
  }
}


.auction-loan-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auction-loan-file-container {
  display: grid;
  vertical-align: middle;
  margin-top: 8px;
  height: 268px;
  border-radius: 8px;
  gap: 8px;
  background: $n39;
  border: 1px dashed $n15;
  cursor: pointer;
  width: 432px;
  @include dark {
    background: $n19;
    border: 1px dashed $n34;
  }
}

.auction-loan {
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  border-bottom: 1px solid $n6;
  @include dark {
    background: $n19;
    border-bottom: 1px solid $n34;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    @include dark {
      color: $n14;
    }
  }

  &__desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    @include dark {
      color: $n16;
    }
  }
}

.auction-loan-data-room {
  display: flex;
  flex-flow: column nowrap;
  padding: 16px 24px 16px 24px;
  @include dark {
    background: $n19;
  }

  .authorised-dataroom-files-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;

    @include dark {
      color: $n14;
    }
  }
}

.auction-loan-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  border-bottom: 1px solid $n12;

  p {
    color: $n15;
  }
}

.auction-loan-content {
  position: relative;
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  span {
    font-size: 64px;
    color: $n28;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @include dark {
      color: $n14;
    }
  }

  small {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $n41;
    margin-top: -4px;
    @include dark {
      color: $n15;
    }
  }

  .image_browse {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    opacity: 0;
    cursor: pointer;
  }
}

.file-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auction-loan-files {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1 1;

  .auction-loan-details {
    display: flex;
    gap: 12px;
    align-items: center;
    height: auto;
    flex: 1 1;
  }

  i {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $n42 !important;
  }

  small {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: $n22 !important;
  }
}

.auction-loan-files-description-container {
  display: flex;
  gap: 4px;
  margin-right: 10px;
  flex: 1 1;
}

.auction-loan-file-names {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  color: $n14;
}

.fileName {
  color: $n22 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  @include dark {
    color: $n14 !important;
  }
}

.fileSize {
  color: $n15;
}

.auction-loan-files-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1 1;

  &__upper {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    width: 100%;

    &__uploading {
      flex: 1 1;
      display: flex;
      justify-content: space-between;
    }
  }

  .auction-loan-file-loading-status {
    font-size: 12px;
    font-weight: 500;
    color: $n41;
    text-transform: capitalize;
    padding-left: 4px;
  }
}

.auction-loan-loading-placeholder {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background-color: lightgrey;

  .auction-loan-loading-percentage {
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    z-index: 10;
    transition: all 0.25s ease-in;
    background: $n40;
  }
}

.auction-loan-file {
  color: $n15 !important;
  cursor: pointer;

  &__bin {
    color: $n41 !important;
  }
}

.fileLogo {
  background: $n6;
  padding: 8px;
  height: 48px;
  width: 48px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include dark {
    background: $n12;
  }

  img {
    width: 22px;
  }
}


.auction-loan-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.auction-loan-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
