@import styles/helpers

.form
    background: $dark
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    width: 100%
    padding: 0 9px
        
.nav
    display: flex
    justify-content: center
    margin-bottom: 32px
    .link
        display: flex
        padding: 6px 12px
        border-radius: 14px
        background: none
        +button-2
        color: $n4
        transition: all .2s
        &:hover
            color: $n3
            +dark
                color: $n8
        &.active
            background: $n3
            color: $n8
            +dark
                background: $n3
                color: $n8
        &:not(:last-child)
            margin-right: 24px

.container
    margin-bottom: 32px
.edit
    padding: 2px
    text-align: left
    margin-bottom: 16px
    flex-direction: column
    color: $n5
    width: 100%
    font-weight: 500
    span
        color: $white

.editNumber
    color: $n10 !important
    cursor: pointer
    margin-left: 5px
    

.resend
    margin-bottom: 16px
    display: flex
    justify-content: center
    color: $white
    padding: 12px 24px 12px 24px
    &:hover
        opacity: 0.5
        cursor: pointer
        transition: .4s all ease-in-out


.editBtn    
    cursor: pointer
    margin-left: 4px
    text-decoration: underline 
    width: 36px 
    color: #F5C462
    &:hover
        color: $p1 
        font-weight: 700

.line
    display: flex
    justify-content: center
    gap: 10px
    .field
        &:first-child
            flex-shrink: 0
            width: 128px
            margin-right: 8px
        &:nth-child(2)
            flex-grow: 1

.foot
    display: flex
    justify-content: space-between
    margin-top: 16px
    +caption-bold-2
    .link
        color: $p1
        transition: color .2s
        &:hover
            color: darken($p1, 10)

.scan
    +caption-bold-2
    color: $n4
    transition: color .2s
    &:hover
        color: $n2

.buttons
    width: 100%
    border-radius: 0 !important
    +dm
        width: 480px

.otpInput 
    background-color: transparent
    border: 1px solid $n6
    border-radius: 10px
    color: $white
    font-weight: 700
    height: 58px
    text-align: center
    width: 58px
    font-size: 14px
    &:focus
        border: 1px solid $n74


.verify_button
    display: flex
    justify-content: center
    margin-top: 12px
    width: 100%
        

.otp
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    max-width: 480px

.otp_detail
    font-weight: 600
    font-size: 26px
    color: $white
    text-align: left
    width: 100%
    +dm
        font-size: 32px

.back_button
    border-radius: 0 !important
    background: grey
    width: 100%
    &:hover
        background: rgba(245, 175, 69, 0.06)

.sendCodeTimer
    width: 45px
    color: $n10
    margin-left: 5px
