@import "../../../../../../styles/helper";

.slide-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.digit-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 108px;
  width: 220px;
  border-radius: 8px;
  gap: 8px;
  overflow: hidden;
}

.digit {
  position: relative;
  width: 60px;
  text-align: center;
  border-radius: 10px;
  font-size: 60px;
  font-weight: 600;
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  color: $white;
  background: #D6D6D6;
  box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.1); 
  @include dark{
    box-shadow:inset 0 4px 10px rgba(0, 0, 0, 0.4);
    background-color: #111;
  }
}

.current, .next {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 108px;
}

.current {
  transform: translateY(0);
}

.next {
  transform: translateY(108px); 
}

.sliding .current {
  animation: slide-up-current 0.5s forwards ease-in-out;
}

.sliding .next {
  animation: slide-up-next 0.5s forwards ease-in-out;
}

@keyframes slide-up-current {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-108px);
  }
}

@keyframes slide-up-next {
  0% {
    transform: translateY(108px); 
  }
  100% {
    transform: translateY(0); 
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  color: $dark;
  @include dark{
    color:$n9;
  }

}
