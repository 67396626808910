@import "../../../../styles/helper.scss";

.notificationSection {
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 12px 16px;
    border-bottom: 1px solid $n54;
    cursor: pointer;

    @include dark {
        border-bottom: 1px solid $n12;
    }

    @media (max-width: 425px) {
        max-width: 425px;
    }
}

.AuthorisedNotification {
    display: flex;
    justify-content: space-between;

    &__left {
        display: flex;
        gap: 12px;
        position: relative;

        &__icon {
            width: 40px;
            height: 40px;
            gap: 8px;
            border-radius: 4px;
            background: $n57;

            @include dark {
                background: $n12;
            }

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                color: $n47;

                @include dark {
                    color: $white;
                }
            }
        }

        &__readMark {
            height: 8px;
            width: 8px;
            border-radius: 24px;
            background: $n62;
            position: absolute;
            left: 38px;
            top: -2px;
        }

        &__title {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: $n58;

            @include dark {
                color: $white;
            }
        }

        &__Subtitle {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: $n59;
            margin-top: 4px;
            max-width: 290px;
            word-break: break-word;

            @include dark {
                color: $n15;
            }
        }

        &__action {
            display: flex;
            gap: 8px;
            margin-top: 12px;
            .coowner-btn-action {
                border-radius: 4px;
                font-size: 12px;
                padding: 10px 16px;
                height: unset;
                min-width: 75px;
                min-height: unset;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 24px;
        align-self: flex-start;

        &__status {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: $n15;
            text-transform: capitalize;
         
            @include dark {
                color: $n16;
            }
        }

        &__dot {
            height: 8px;
            width: 8px;
            border-radius: 24px;
            background: $n13;
       
        }

        &__time {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            text-align: right;
            color: $n11;
        }
    }
}

.processing {
    background-color: $s5;
}

.cancelled {
    background-color: $n23;
}

.completed {
    background-color: $n13;
}

.failure {
    background-color: $n24;
}