@import "../../../styles/helper";

.sba-stats-container {
  .auction-details-heading {
    padding: 0 20px;
    @include dark {
      color: white;
    }
  }
  @include dark {
    .react-table-responsive-wrapper {
      tr {
        border-bottom: 1px solid #23262f;
      }
      tr:last-child {
        border-bottom: none;
      }
    }
  }
}
.sbaLoanDetail {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  @include dark {
    border-color: #23262f;
    background: #17181b;
  }
  .sba-stats-container {
    .auction-details-heading {
      padding: 0 20px;
      @include dark {
        color: white;
      }
    }
  }
  .react-table-responsive-wrapper {
    tr:last-child {
      border-bottom: none;
    }
  }
}
