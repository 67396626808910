@import "../../../styles/helper.scss";

.chat-bidder-list-model {
  border-radius: 8px;
  width: 100%;
  position: relative;
  max-width: 400px !important;
  padding: unset !important;

  .Modal__wrapper {
    padding: unset !important;
  }

  .chat-bidder-header-container {
    padding: 15px;

    .chat-bidder-header {
      font-size: 20px;
      font-weight: 600;
    }

    .chat-bidder-desc {
      font-weight: 500;
      color: $n4;

      @include dark {
        color: $n5;
      }
    }
  }

  .chat-bid-points {
    padding: 15px;
    font-weight: 600;
    color: $n4;
    display: flex;
    justify-content: space-between;
    border-block: 1px solid $n6;

    span {
      color: $n13;
    }

    &.in-red {
      span {
        color: $p3;
      }
    }
    @include dark {
      color: $n5;
      border-block-color: $n3;
    }
  }

  .chat-bidder-list {
    padding-top: 5px;
    margin-bottom: 10px;
    height: 350px;
    overflow-y: auto;
  }

  .bidder-item {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .initials {
    background: $n28;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .timestamp {
    font-size: 12px;
    font-weight: 400;
    color: $n4;

    @include dark {
      color: $n5;
    }
  }

  .details {
    flex-grow: 1;
  }

  .chat-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $p1;
    padding: 8px 12px;
    border-radius: 4px;
    gap: 4px;
    font-weight: 500;
    i {
      font-size: 20px
    }
  }
}
