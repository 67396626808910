@import styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.dropdown
    position: relative
    z-index: 3
    &.active
        z-index: 10
        .head
            .arrow
                svg
                    transform: rotate(180deg)
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    position: relative
    height: 48px
    padding: 0 48px 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    background: $n8
    font-size: 14px
    font-weight: 500
    line-height: 48px
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: box-shadow .2s
    display: flex
    box-shadow: inset 0 0 0 1px $s5
    &:hover
        box-shadow: inset 0 0 0 2px $s5
    +dark
        box-shadow: inset 0 0 0 1px $s5
        background: $n19
    &:hover
        box-shadow: inset 0 0 0 2px $s5

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-size: 14px
    color: $s5
    font-weight: 700
    width: 100px
    text-overflow: ellipsis
    overflow: hidden
    


.arrow
    position: absolute
    top: 50%
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 50%
    transform: translateY(-50%)
    svg
        fill:$s5
        transition: transform .2s

    +dark
        svg
            fill:$s5

.body
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 12px
    border-radius: 12px
    background: $n8
    width: 298px
    box-shadow: inset 0 0 0 2px $n6, 0 4px 12px rgba($n2, .1)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    max-height: 200px
    overflow-y: auto
    &::-webkit-scrollbar
        width: 5px
        border-radius: 5px

    &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px grey
        border-radius: 10px

    /* Handle */
    &::-webkit-scrollbar-thumb
        background: $n11
        border-radius: 10px

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
        background: $n11
    +dark
        background: $n19
        box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n1, .1)

.option
    padding: 8px 16px
    font-size: 14px
    font-weight: 500
    transition: color .2s
    cursor: pointer
    color: black
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &:hover,
    &.selectioned
        color: $p1

.empty
    .head
        height: 26px
        padding: 0 36px 0 14px
        box-shadow: none
        background: none
        line-height: 26px
        &:hover
            box-shadow: none
    .arrow
        right: 12px
        width: 24px
        height: 24px
        box-shadow: none
    .option
        padding: 8px 14px

.selectUser
    width: 275px    
.userImage
    height: 36px
    width: 36px
    border-radius: 40px
.main
    width: 259px   
    display: flex
    justify-items: center
    align-items: center
    flex-direction: column

.userSection
    display: flex
    align-items: center 
    justify-content: space-between
    gap: 8px
    width: 284px 
    height: 65px
    margin-left: 16px
    color: $s5
    border: 1px solid transparent



.selectioned
    border:1px solid $n44
    border-radius: 8px
    height: 65px
    color: $s5
    background: $n7
    +dark
        background: $n12

.selectioned .details__name 
    color:$s5 !important  
.selectioned .arrowBox 
    display: block
    color:$s5 

.optionLeft
    display: flex
    gap: 8px    
    
.details
    display: flex 
    flex-direction: column 
    &__name
        color: $n3
        font-size: 14px
        font-weight: 700
        +dark
            color: $white
    &__id 
        color: $n15 
        font-size: 12px    
        width:174px
        text-overflow: ellipsis
        overflow: hidden

.detailsLeft
    display: flex
    justify-content: space-between
    align-items: center
    gap: 50px
    
.arrowBox
    height: 24px
    width: 24px
    border-radius: 24px
    background: $n35
    display: none


.arrowIcon 
    display: flex
    justify-content: center
    align-items: center 

.userValue
    display: flex
    justify-content: center
    align-items: center
    gap: 12px
.userValueImage
    width: 24px
    height: 24px
    border-radius: 40px

.userSection:hover .details__name 
    color: $s5

    