@import styles/helpers

.confirmationModalOuter
   max-width: 480px
.KYCReviewModal
  max-width: 768px !important
  padding: 24px
  border-radius: 0px !important
  +dark
    background: $n21 !important   
