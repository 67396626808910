@import styles/helpers

.loginSection
    display: flex
    height: 100%
    flex: 1 1 
    max-width: 1440px
    position: relative
    margin: 0 auto 

.loginContainer
    overflow-y: auto
    width: 100%

.LoginImageRight
    height: 100vh
    width: 100%
    display: grid
    place-items: center
    overflow: hidden
    position: relative
    &:after, &:before
        content: ""
        position: absolute
        top: 0
        bottom: 0
        width: 5%
        z-index: 2
    &:before
        left: 0
        background-image: linear-gradient(to right, $dark , transparent)
    &:after
        right: 0
        background-image: linear-gradient(to left, $dark , transparent)
    
.LoginBg
    filter: blur(48px)
    width: 100%
    height: 100vh
    z-index: 0
.LoginCards   
    width: 100%
    padding-left: 3rem
    padding-right: 3rem
    object-fit: cover
    object-position: top
    position: absolute
    // left: 40px
    z-index: 1
.wrapper
    width: 100%
    place-items: center
    display: grid
    padding: 70px 0
    +dm
        padding: 60px 0
.header
    display: flex
    width: 100%
    padding: 12px
    align-items: center
    justify-content: space-between
    +ds
        padding: 12px 24px
        margin-top: 8px
    +da
        padding: 12px 48px 
    span
        color: #4574F5
.inner_wrapper
    padding: 0px 15px
    width: 100%
    max-width: 480px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    +dm
        width: 480px
.disclaimer
    font-size: 10px
    padding: 16px 15px 40px
    line-height: 12px
    +dm
        padding: 16px 48px 80px
    a
        color: $n28
        cursor: pointer
.inner
   width: 100%
   display: flex
   flex-direction: column
   gap: 18px

.title_header
    display:flex
    flex-direction:column
    width:100%
    gap: 8px

.title   
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: $white
    +da
        font-size: 32px
        line-height: 48px


.sub_title
     font-weight: 500
     font-size: 12px
     line-height: 16px
     color: $n15
     +da
        font-size: 16px
        line-height: 24px

.brand_logo
    width: 150px
    height: 100%
    cursor: pointer
    +da
        width: 210px

.sub_title_small
    display: flex
    align-items: flex-end
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: $n15
    text-align: left
    cursor: pointer

.terms_and_privacy_wrapper
    display: flex
    justify-content: center
    text-align: center
    color: #4574F5
    gap: 12px

.middle_element
    border-left: 1px solid #36383D
    padding-left: 12px
    

.last_element
    text-align: right
    flex:1

.lists
    display: flex
    flex-direction: column
    gap: 16px

.list_item
     display: flex
     gap: 8px
     align-items: center
     background: rgba(51, 184, 122, 0.06)
     border-radius: 8px
     padding: 16px
     cursor: pointer
     color: $n13
     &:hover
       background: rgba(245, 175, 69, 0.06)
       color: $s5
       
.left_icon
    background: rgba(51, 184, 122, 0.12)
    border-radius: 24px
    width: 40px
    height: 40px
    display: grid
    place-items: center
    font-size: 24px
   

.right_icon
     font-size: 24px

.method_title
   font-weight: 600
   font-size: 16px
   line-height: 24px
   color: $n14
   flex: 1

    
.form_wrapper
    display: flex
    flex-direction: column
    gap: 24px
.input_wrapper
   border: 1px solid $n12
   border-radius: 4px
   height: 52px 
   display: flex 
   align-items: center
   position: relative
   
   &.country-label
       max-width:200px
   &.css-1s2u09g-control
     border-width: none !important
     border-style: none !important

.input_field
   caret-color: $white
   padding: 13px 8px
   background: transparent
   color: #fff
   letter-spacing: 2px
   font-size: 16px
   width: 100%
   -webkit-box-shadow: 0 0 0 1000px #282A2E inset !important
//    -webkit-text-fill-color: #fff !important  TODO: anish, remove after testing

.input_field:-webkit-autofill
  -webkit-text-fill-color: #fff
  -webkit-box-shadow: 0 0 0px 1000px #000 inset
  transition: background-color 5000s ease-in-out 0s

.inputField_error
    border: 0.5px solid var(--color-error)

.inputField_error_background
    -webkit-box-shadow: 0 0 0 1000px hsl(0deg 88% 67% / 6%) inset !important
    // -webkit-box-shadow: none !important
    // background-color: hsl(0deg 88% 67% / 6%)
    

.login_btn
    background: #0C87FD 
    border-radius: 4px
    width: 100%
    height: 52px
    font-size: 14px
    color: #fff
    font-weight: 500

.login_btn_disabled
    background: $n19
    border-radius: 4px
    width: 100%
    height: 52px
    font-size: 14px
    color: $n16
    font-weight: 500
    cursor: not-allowed

.login_btn_loading
    background-color: #0C87FD 
    height: 52px  

.back_btn
    background: gray
    border-radius: 4px
    width: 100%
    height: 52px
    font-size: 14px
    color: #fff
    font-weight: 500

.footer_text
   font-weight: 400
   font-size: 14px
   line-height: 20px
   color: $n11
   cursor: pointer

.footer_highlighter
    font-weight: 600
    +dark
        color: $white
    

.input_countryCode 
    font-weight: 500
    font-size: 16px
    margin-bottom: 2px
    

.footer
    display :flex 
    flex-direction: column
    gap:16px
    cursor: pointer !important

@media (max-width: 1024px) 
    .loginContainer
        padding: 20px
        max-width: 1440px
    .input_field
        width: 70%  
    .LoginImageRight
        display: none
    .loginContainer
        width: 100%           

