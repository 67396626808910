@import "../../../../../styles/helper";

.auction-form-image-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;

  img {
    width: 72px;
    height: 72px;
    margin-right: 16px;
    border-radius: 4px;
    background-color: #f0f4ff;
    border: 1px solid #d7dff5;
    object-fit: fill;
    background-position: center;
  }

  .auction-form-image {
    display: flex;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    background-color: #f0f4ff;
    border: 1px solid rgba(0, 97, 245, .48);
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    span {
      font-size: 18px;
      color: #4574f5;
    }

    @include dark {
      background-color: $dark;
    }
  }

  .auction-form-image-text {
    display: flex;
    flex-flow: column wrap;
    gap: 4px;
    align-self: center;
    &__subcontent{
      font-size: 12px !important;
    }

    .btn-browse{
      color: $blue;
      background-color: rgba(0, 97, 245, .16);
    }
  
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: #5d657a;
    }
    .auction-form-image-file{
      opacity: 0;
    }
    @include dark {
      p {
        color: $white;
      }
    }
  }
}

.cropImageIcon{
  padding: unset !important;
  .reactEasyCrop_CropArea{
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
  }
  .Modal__wrapper{
    width: 580px !important;
    height: 578px !important;
    padding: unset !important;
  }
  .Modal{
    padding: unset !important;
  }
  .crop-image-on-upload-modal__container {
    padding: 20px 24px 14px !important;
    border-bottom: 1px solid $n5 !important;
    @include dark {
      border-bottom: 1px solid $n4 !important;
    }
  }
  .crop-image-container{
    margin-left: 115px !important;
    margin-top: 16px !important;
    margin-bottom: unset !important;
  }
  .crop-image-action {
    padding-top: 16px !important;
  }

  .crop-image-on-upload-modal__listStyle{
    padding: unset !important;
  }
}
