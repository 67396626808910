@import "../../../../styles/helper";

.filter__container {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  @include dark {
    .input__view:hover{
      color: $white !important;
    }
  }
  &-search {
    display: flex;
    flex: 402px 0 0;
    flex-flow: row nowrap;
    gap: 10px;
    .filter-container {
      height: auto;
    }
    .input__group {
      height: 44px;
    }
  }

  @include dark {
    .input__text-fields {
      border-color: $n18;
      background-color: transparent;
      &:hover {
        border: inherits;
      }
    }

    .btn-grey {
      background-color: $n12 !important;
      color: $white !important;

      &:hover {
        background-color: darken($n12, 8) !important;
      }
    }

    .input__fields {
      color: $white;
      caret-color: $white;
    }
  }

  &-create {
    .asset-btn {
      border-radius: 8px;
    }
  }
}
