@import styles/helpers

.addWatchlistIcon
    cursor: pointer
    margin-left: 8px 
    .icon
        font-size: 25px
    .iconAdded
        color: $s3
    .iconRemoved
        color: $n4