.main-container{
  background: white !important;
  height: 100%;
  width: 100%;
  .token-denied-container {
    background: white !important;
  .brand_logo{
   height: 56px;
  }
  .token-denied-details {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .token-denied-image{
        margin-bottom: 24px;
        width: 177px;
        height: 140px;
    }

    p {
      text-align: center;
    }

    .text-url {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 5px;
      line-height: 32px;
      gap: 15px;
      color: rgba(54, 58, 69, 1);
    }

    .token-invalid {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 10px;
      color: rgba(124, 131, 152, 1);
    }
    .contact-email {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      background: rgba(0, 81, 204, 0.06); 
      border-radius: 32px;
      padding: 8px 16px;
      gap: 8px;
      color: rgba(81, 87, 103, 1)
    }

    .email-color {
      color: #3c65d6;
    }
    .accessdenied-logout{
     background: rgba(0, 0, 0, 0.06);
      font-weight: 500;
      border-color: transparent;
      font-size: 14px;
      line-height: 20px;
      margin-top: 20px;
      border-radius: 32px;
      padding: 10px 16px;
      height: 40px;
      width: 90px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

}