@import "../../../styles/helper.scss";

.chat-container {
  width: 400px;
  border: 1px solid #ffffff1f;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  background-color: #fff;
  color: #1f1f1f;
  z-index: 11;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  @include dark {
    background: #1f1f1f;
    color: #f5f5f5;
    box-shadow: 0px 0px 16px 4px #000000;
  }
}

.chat-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #0000001f;
  @include dark {
    border-bottom: 1px solid #ffffff1f;
    background: #1f1f1f;
  }

  .avatar {
    width: 32px;
    height: 32px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
    background-color: #1472ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-right: 10px;
    position: relative;

    .item-image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    .notification-badge {
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      background-color: #eb4747;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 22px;
      color: #fff;
      font-size: 10px;
      font-weight: 500;
      @include dark {
        border: 2px solid #1f1f1f;
      }
    }
  }

  .name {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
  }

  .arrow {
    font-size: 18px;
  }
}

.chat-content {
  max-height: 424px;
  background-color: var($white);
}

.search-input {
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  border: 1px solid #0000001f;
  background: #00000008;

  &:focus {
    border: 1px solid #007bff !important;
  }

  @include dark {
    border: 1px solid #ffffff1f;
    background: #ffffff08;
    caret-color: var($white) !important;
    color: white;
  }

  &:focus {
    outline: none;
  }
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #0000001f;
  //border-top: 1px solid #0000001f;
  @include dark {
    border-bottom: 1px solid #ffffff1f;
   // border-top: 1px solid #ffffff1f;
  }
  .empty-icon{
    position: absolute;
    right: 24px;
    top: 54%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #7a7a7a;
  }
}

.search-icon {
  position: absolute;
  right: 24px;
  top: 54%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #7a7a7a;
}

.chat-list {
  flex: 1;
  overflow-y: auto;

  .chat-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover,
    &.selected {
      background-color: #333;
    }

    .chat-avatar {
      width: 35px;
      height: 35px;
      background-color: #3a8ef6;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      margin-right: 10px;
    }

    .chat-details {
      flex: 1;

      .chat-name {
        font-weight: bold;
      }

      .chat-last-message {
        font-size: 0.9em;
        color: #aaa;
      }
    }

    .chat-time,
    .unread-count {
      margin-left: 10px;
    }

    .unread-count {
      background-color: #3a8ef6;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .no-results {
    padding-block: 10px;
    font-weight: 500;
    color: $n4;
    @include dark {
      color: $n5;
    }
  }
}

//Chat Modal scss

.chat-modal-container {
  color: #000;
  //height: 600px;
  width: 480px;
  position: fixed;
  right: 412px;
  bottom: 0;
  background: white;
  border: 1px solid #ffffff1f;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 999999;
  @include dark {
    background: #1f1f1f;
    color: #f5f5f5;
  }
}

.chat-modal-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #0000000f;
  @include dark {
    border-bottom: 1px solid #ffffff0f;
  }

  .chat-modal-avatar {
    width: 48px;
    height: 48px;
    background: #d6d6d6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var($white);
    position: relative;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;

    @include dark {
      background: $n3;
    }

    .chat-madal-notification-badge {
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      background-color: #109345;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 4px;
      left: 38px;
      color: #fff;
      font-size: 10px;
      font-weight: 500;

      &.offline {
        background-color: $n5;
      }

      @include dark {
        border: 2px solid #1f1f1f;

        &.offline {
          background-color: $n4;
        }
      }
    }
  }

  .chat-modal-details {
    flex-grow: 1;
    margin-left: 10px;

    .chat-modal-id {
      font-weight: 500;
      font-size: 16px;
      display: block;
    }

    .chat-modal-subtitle {
      font-size: 14px;
      color: #7a7a7a;
      font-weight: 500;
    }
  }

  .chat-modal-icons {
    display: flex;
    align-items: center;
    gap: 4px;

    .chat-close-icon {
      cursor: pointer;
    }

    .chat-open-icon {
      cursor: pointer;
    }

    .chat-modal-icon {
      margin: 0 5px;
      cursor: pointer;
      color: #666;
    }

    .chat-modal-close {
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
      color: #666;
    }
  }
}

.chat-modal-body {
  height: 400px;
  flex-grow: 1;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .chat-body {
    flex: 1;
    padding-block: 10px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    text-align: left;
    width: 100%;

    .chat-date {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 10px 0;
      font-size: 12px;
      color: #999999;

      &::before,
      &::after {
        content: "";
        flex: 1;
        border-top: 1px solid #0000001f;
        margin: 0 10px;
        @include dark {
          border-top: 1px solid #ffffff0f;
        }
      }
    }

    .chat-message-user {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      white-space: pre-wrap;

      .message-content {
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }

    .sender .message-content {
      background: #f5f5f5;
      color: #1f1f1f;
      align-self: flex-end;
      max-width: 75%;
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      border-radius: 10px 0 10px 10px;
      @include dark {
        background-color: #2e2e2e;
        color: #f5f5f5;
      }

      .message-time {
        color: #3d3d3d;
        font-size: 10px;
        margin-left: 10px;
        align-self: flex-end;
        @include dark {
          color: #d6d6d6;
        }
      }
    }

    .receiver .message-content {
      background: #f5f5f5;
      color: #1f1f1f;
      align-self: flex-start;
      max-width: 75%;
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      border-radius: 0px 10px 10px 10px;
      @include dark {
        background-color: #2e2e2e;
        color: #f5f5f5;
      }

      .message-time {
        color: #3d3d3d;

        font-size: 10px;
        align-self: flex-start;
        @include dark {
          color: #d6d6d6;
        }
      }
    }

    .circle-loader {
      height: 30px;
    }
  }

  .chat-modal-message-icon {
    margin-bottom: 20px;

    .chat-modal-icon-text {
      font-size: 3em;
      //background: #f5f5f5;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chat-modal-prompt {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .chat-modal-note {
    font-size: 12px;
    font-weight: 500;
    color: #7a7a7a;

    span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: #0061f5;
    }
  }
}

.chat-modal-footer {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .chat-modal-footer-box {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 12px;
    align-items: center;
  }

  .chat-modal-input {
    padding: 10px;
    border: 1px solid #0000001f;
    border-radius: 5px;
    font-size: 1em;
    background: #00000008;
    resize: none;
    color: unset;
    field-sizing: content;
    max-height: 55px;
    @include dark {
      border: 1px solid #ffffff1f;
      background: #ffffff08;
      caret-color: var($white) !important;
      color: var($white);
    }
  }

  .chat-modal-send-button {
    padding: 10px;
    background: #0061f5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    height: fit-content;
  }

  .chat-modal-reminder {
    margin-top: 10px;
    color: gray;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    span{
      font-weight: 600;
    }
  }
}

//assetchatmessages

.asset-info {
  display: flex;
  align-items: center;
  padding: 8px 12px 12px 12px;
  border-top: 1px solid #0000001f;
  @include dark {
    border-top: 1px solid #ffffff1f;
  }

  &__chat-list {
    padding: 10px;
    height: 288px;
    overflow-y: auto;

    .no-results {
      font-weight: 500;
      color: $n4;
      @include dark {
        color: $n5;
      }
    }
  }

  &__chat-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #0000001f;
    cursor: pointer;
    @include dark {
      border-bottom: 1px solid #ffffff1f;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__chat-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #d6d6d6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: relative;
    font-weight: 600;
    text-transform: uppercase;

    @include dark {
      background: $n3;
    }

    .asset-notification-badge {
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      background-color: #109345;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 4px;
      left: 38px;
      color: #fff;
      font-size: 10px;
      font-weight: 500;

      &.offline {
        background-color: $n5;
      }

      @include dark {
        border: 2px solid #1f1f1f;

        &.offline {
          background-color: $n4;
        }
      }
    }
  }

  &__chat-message-count {
    display: flex;
    justify-content: space-between;
  }

  &__chat-info {
    flex: 1;
    padding-inline: 10px;
  }

  &__chat-name {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }

  &__chat-last-message {
    color: #7a7a7a;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
    white-space: nowrap;
  }

  &__chat-time {
    font-size: 12px;
    color: #999;
    white-space: nowrap;
  }

  &__chat-unread-count {
    background: #007bff;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 10px;
  }

  .back-arrow {
    padding: 0 12px 0 0;
    cursor: pointer;
    color: #5c5c5c;
    @include dark {
      color: #b8b8b8;
    }
  }

  .asset-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;

    .item-image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      border: 1px solid #0000001f;
      @include dark {
        border-bottom: 1px solid #ffffff1f;
      }
    }

    .asset-text {
      font-size: 16px;
      padding: 0 12px;
      font-weight: 500 !important;
      line-height: 24px;
    }
  }
}
