// common styles
body
  min-width: 375px
  background: $n8
  +poppins
  font-size: 14px
  line-height: 1.7
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $n2
  +dark-body
    background: $n1
    color: $n8

a
  text-decoration: none

svg,
img
  vertical-align: middle

::-webkit-scrollbar
  width: 5px
  height: 5px

exchange-dashboard
  display: flex
  flex-direction: column
  height: 100dvh

.desktop
  &-hide
    +d
      display: none !important

  &-show
    display: none !important
    +d
      display: block !important

  &-text-right
    +d
      text-align: right

.tablet
  &-hide
    +t
      display: none !important

  &-show
    display: none !important
    +t
      display: block !important

.mobile
  &-hide
    +m
      display: none !important

  &-show
    display: none !important
    +m
      display: block !important

.fw-600
  font-weight: 600

.w-150
  width: 150px !important

.pl-10
  padding-left: 10px

.text-center
  text-align: center

.text-right
  text-align: right

.justify-content-center
  justify-content: center

.fs-20
  font-size: 20px !important

.fs-18
  font-size: 18px !important
  
.fs-20
  font-size: 20px !important

.fs-16
  font-size: 16px !important

.fs-14
  font-size: 14px !important

.text-capitalize
  text-transform: capitalize

.mt-10
  margin-top: 10px

.w-100
  width: 100%

.h-100
  height: 100%

.border-b-0
  border-bottom: 0 !important

.border-radius-0
  border-radius: 0 !important

.pointer
  cursor: pointer

.disabled-cur
  cursor: not-allowed !important

.text-lower
  text-transform: lowercase !important

.mb-0
  margin-bottom: 0

.mt-0
  margin-top: 0

.mb-10
  margin-bottom: 10px

.mb-15
  margin-bottom: 15px

.mr-10
  margin-right: 10px

.mb-20
  margin-bottom: 20px

.mb-30
  margin-bottom: 30px

.mb-50
  margin-bottom: 50px

.mt-30
  margin-top: 30px

.mt-20
  margin-top: 20px

.fs-24
  font-size: 24px !important

.pt-0
  padding-top: 0 !important

.py-0
  padding-block: 0 !important
