@import "../../../../styles/helper.scss";

.wallet-select-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  gap: 24px;

  .card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    width: 192px;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid $n6;
    @include dark {
      border: 2px solid #36383d;
    }

    &:hover {
      border: 2px solid #4574f5;
      position: relative;
    }

    &.active-card {
      border: 2px solid #4574f5;
      position: relative;

      &::after {
        position: absolute;
        content: "Selected";
        top: 90%;
        left: 35%;
        background-color: #4574f5;
        padding: 8px;
        border-radius: 4px;
        font-size: 9px;
        font-weight: 400;
        line-height: 12px;
        color: $white;
      }
    }

    span {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      border-radius: 136px;
      padding: 16px;
      background: #0000000f;

      i {
        font-size: 48px;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: #e6eaf5;
      }
    }
  }
}
