.layout-admin {
  overflow: hidden;
  display: flex;
  flex: 1 1;

  .admin-right-side {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
}
