@import ../../../../styles/helpers

.user
    position: relative
    display: flex
    align-items: center
    gap: 8px
    +m
        position: static
    &.active
        .body
            visibility: visible
            opacity: 1
            z-index: 99
            transform: translateY(0)
.profileName
    font-size: 14px
    font-weight: 700
    color: #E27A13
    cursor: pointer
    display: none
.activeUserCheck
    cursor: not-allowed! important     

.coUserLoading
    cursor: not-allowed! important         

.head
    position: relative
    width: 40px
    height: 40px
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.body
    position: absolute
    top: calc(100% + 20px)
    right: 0px
    width: 256px
    padding: 4px 16px
    border-radius: 12px
    box-shadow: 0px 0px 4px 0px hsl(0deg 0% 0%/12%)
    background: $n8
    visibility: hidden
    opacity: 0
    transform: translateY(10px)
    transition: all .3s
    +d
        right: 0
    +t
        right: 0
    +m
        top: 68px
        right: 16px
    +dark
        background: $n2
        box-shadow: 0px 0px 4px 0px hsl(0deg 0% 0%/24%)
    &:before
        content: ""
        position: absolute
        right: 6px
        bottom: 100%
        width: 24px
        height: 12px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' fill='none' viewBox='0 0 24 9'%3E%3Cpath d='M6.343 2.657L0 9h24l-6.343-6.343a8 8 0 0 0-11.314 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 100% / 100% auto
        +d
            right: 28px
        +t
            right: 10px
        +m
            right: 26px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' fill='none' viewBox='0 0 24 9'%3E%3Cpath d='M6.343 2.657L0 9h24l-6.343-6.343a8 8 0 0 0-11.314 0z' fill='%2323262F'/%3E%3C/svg%3E")

.item
    position: relative
    display: flex
    padding: 12px 0
    color: $n2
    transition: color .2s
    +dark
        color: $n8
    &:hover
        color: $p1
    &:not(:last-child)
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 20px
    height: 20px
    margin-right: 8px
    svg
        fill: $n4

.details
    flex-grow: 1
    padding-top: 2px

.line
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: -4px

.title
    +button-2

.content
    margin-top: 2px
    +caption-3
    font-weight: 500
    color: $n4
    text-align: left

.item_logout
    position: relative
    display: flex
    padding: 12px 0
    color: $n2
    transition: color .2s
    cursor: pointer
    +dark
        color: $n8
    &:hover
        color: $p1
        
.bug_icon
   color: $n4

.KYCReviewModal
    max-width: 768px !important
    padding: 24px
    border-radius: 8px
    +dark
        background: $n21 !important
