@import styles/helpers

.inner
    border-radius: 8px
    background: $n8
    max-height: 328px
    overflow: auto
    transition: all 0.3s ease-out
    transform: scale(1.0)
    +scroll
    +dark
        background: $dark
   
.table
    display: table
    width: 100%
    padding: 0px 16px
    position: relative
    +scroll
    &::-webkit-scrollbar
        width: 5px

.link
    max-width: max-content
    display: flex
    align-items: center
    padding: 4px 12px
    border-radius: 14px
    +button-2
    font-size: 12px
    line-height: 16px
    transition: all .2s
    cursor: pointer
   

.modify
    background:  $p1
    color: $n7

.cancel
    background:  hsla(0, 0%, 0%, 0.06)
    color: $n3
    +dark
        background: hsla(0, 0%, 100%, 0.12)
        color: $n6

.disable
    background:  hsla(0, 0%, 0%, 0.06)
    color: $n27
    cursor: not-allowed
    +dark
        background: hsla(0, 0%, 100%, 0.12)
        color: $n27



.actions
    display: flex
    gap: 4px
   
.positive
    background:  $p4

.negative
    background:  $p3

.visible
    display: table-cell
    
.inVisible
    display: none

.emptyState
    height: 100%
    padding: 14px 
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 8px
    .noDataText
        font-weight: 600
        font-size: 14px
        color: $n5
        +dark
            color: $n5

.confirmationModalOuter
   max-width: 480px

.collapse_inner
    border-radius: 8px
    background: $n8
    height: 108px
    overflow: auto
    transition: all 0.3s ease-out
    +scroll
    +dark
        background: $dark

.emptyImage
    height: 96px

.orderTable
    color: $n4
    font-size: 12px
    line-height: 1.65
    text-align: left
    height: 36px
    border-bottom: 1px solid $n6
    width: 100%
    white-space: nowrap
    +dark
        border-bottom: 1px solid $n51

.symbol
    padding-left: 16px
    padding-top: 8px
    display: table-cell
    
.openOrderHeading
    padding-right: 10px
    text-transform: capitalize


.orderQty
    padding-right: 20px


.OrderTableRow
    font-size: 12px
    font-weight: 500
    width: 100%
    white-space: nowrap
    height: 36px
    border-bottom: 1px solid $n6
    +dark
        color: $n6  
        border-bottom: 1px solid $n51    

.OrderTypeContainer
    display: flex
    gap: 4px
    justify-content: left
    align-items: center
.dotColor
    height: 8px
    width: 8px
    border-radius: 8px

.buy
    background: $n13

.sell
    background: $n29        

