@import "../../../../../styles/helper";

.auction-create-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .amount-commission-skeleton {
    width: 30%;
    height: 25px;
    border-radius: 16px;
    display: block;
    background: linear-gradient(90deg, #fff0, #fff3 50%, #fff0 80%), #adb0b8;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: amount-commission-skeleton-ani 1s infinite;

    @include dark {
      background: linear-gradient(90deg, #fff0, #ffffff1a 50%, #fff0 80%), #5e626b;;
    }
  }

  @keyframes amount-commission-skeleton-ani {
    to {
      background-position: 100% 0, /* move highlight to right */
      0 0;
    }
  }
}

.auction-wrapper {
  position: relative;
  padding: 24px;
  background: #ffffff;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  overflow-y: auto;
  @include dark {
    background-color: black;
  }
}

.image-icon-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #747e99;
}

.auction-form-container {
  padding: 24px 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #0000003d;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 15px;

  @include dark {
    background-color: $dark;
    .auction-form-image-text {
      .btn-grey {
        background-color: $dark !important;
        color: $white !important;
        border: 1px solid $white !important;
      }
    }
  }

  .auction-form-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  .auction-form-wrapper {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: #747e99;
    }
  }
}

.auction-form-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auction-form-selected-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;

  .asset-dropdown {
    background: $white;

    .Dropdown_head__USTbf {
      background: $white;
    }

    @include dark {
      background: transparent;
      .Dropdown_head__USTbf {
        background: $dark;
      }
    }
  }

  .auction-form-selected-wrapper {
    display: flex;
    flex: 1;
    gap: 8px;

    .auction-form-selected-icon {
      display: flex;
      width: 46px;
      height: 46px;
      border-radius: 4px;
      background-color: #f0f4ff;
      border: 1px solid #d7dff5;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      span {
        color: #49b875;
      }
    }

    .auction-form-selected-text {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        color: #747e99;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2f323d;
      }

      @include dark {
        span {
          color: $white;
        }
        p {
          color: $white;
        }
      }
    }
  }
}

.auction-form-description-wrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #747e99;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #2f323d;
  }

  @include dark {
    p,
    span {
      color: $white;
    }
  }
}

.auction-form-main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  .auction-form-wrapper {
    display: flex;
    flex-flow: column;
    gap: 16px;

    .auction-form-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #2f323d;
    }

    .date-picker {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .rmdp-time-picker div input {
        width: 22px !important;
      }

      .rmdp-am {
        text-transform: lowercase !important;
        font-size: 14px !important;
        padding: 5px 2px !important;
      }

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #747e99 !important;
      }
    }

    // .rmdp-container {
    //   flex: 1 1;
    // }
    .rmdp-input {
      padding: 25px 12px;
      background-color: transparent;
      border: 2px solid #e6e8ec !important;
      border-radius: 8px;
      width: 100%;
      transition: all 1s;

      &:focus {
        border-color: black !important;
      }
    }

    @include dark {
      .auction-form-header {
        color: $white;
      }

      .input__label {
        color: $white;
      }

      .input__fields {
        caret-color: $white;
        color: $white;
      }

      .rmdp-input {
        caret-color: $white;
        color: $white;
        border: 2px solid #525254 !important;

        &:focus {
          border-color: #ffffff !important;
        }
      }

      .date-picker {
        label {
          color: $white !important;
        }
      }
    }

    .auction-form {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 16px;
      row-gap: 16px;
    }
  }
}

.auction-btn-wrapper {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dfe3ec;
  background-color: #ffffff;

  @include dark {
    background-color: $dark;
  }
}

.create-asset-btn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  button {
    border-radius: 8px;
    font-weight: 500;
  }
}

.asset-Modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .input__text-field {
    background: transparent !important;
    border: 2px solid #e6e8ec;
  }
}

.auction-form-image-file {
  width: 0;
  height: 0;
}

.counter-circle {
  width: 40px !important;
  margin-inline: 5px;

  .CircularProgressbar-path {
    stroke: $n13 !important;
  }
}

.align-center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: -10px;
}

.checkbox {
  grid-column: 1/2;
}

.btn-m {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  font-size: 14px;
  padding: 16px 14px;
}

.read-only {
  background-color: #e6e6e6;
  border-radius: 8px;
  @include dark {
    background-color: $n3;
  }

  input {
    pointer-events: none;
  }
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  @include dark {
    color-scheme: dark;
  }
}

.auction-close-btn {
  font-size: 14px;
  font-weight: 600;
  color: $n30;
  cursor: pointer;
  z-index: 9;
  @include dark {
    color-scheme: $white;
  }
}

.disable-editing {
  pointer-events: none;
}
