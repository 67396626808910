@import ../../../../styles/helpers

.addAccount
    display: flex
    flex-direction: column
    justify-content: center
    min-height: 450px
    align-items: center
    padding: 0 24px
    .bankLogo
        width: 80px
        border-radius: 50px
    .NoHead
        font-weight: 500
        font-size: 18px
        line-height: 32px
        margin: 12px 0 
        +dark
            color: #BCC3D6
    .buttonAaddAccount
        font-size: 14px
        font-weight: 500
        line-height: 16px
        padding: 8px 12px
        border-radius: 4px
        height: unset
        max-width: 140px
        width: 100%
        background: #3772FF
        color: #FCFCFD
        transition: all 0.2s   