@import "../../styles/helper.scss";

.radial-sticks-box {
  position: absolute;
  height: 100%;

  .radial-stick {
    background: $white;
    width: 2.5px;
    height: 10%;
  }

  @include dark {
    .radial-stick {
      background: $dark;
    }
  }
}
