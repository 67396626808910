@import "../../styles/helper.scss";

.listAssetModal{
    .Modal__wrapper{
        background-color: white !important;
        padding-top: 12px;
        @include dark{
        background-color: #141416 !important;
        }
    }




.asset-form-wrapper {
//   min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  form{
        display: flex
;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between
  }
}

.form-container {
  width: 768px;
  @include dark {
    // background: #1f2937;
    // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3);
  }
}

.form-group input.error, .form-group select.error {
    border-color: #ef4444;
    background-color: rgba(239, 68, 68, 0.05);
    @include dark{
         border-color: #ef4444;
    }
}

.form-group .error-message {
    margin-top: 0.25rem;
    color: #ef4444;
    font-size: 0.75rem;
}

.icon-upload input[type=file] {
    display: none;
}

.icon-upload .upload-label {
    cursor: pointer;
    color: #0066ff;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    background: rgba(0, 102, 255, 0.1);
    border-radius: 0.375rem;
    transition: all 0.3sease;
    border: 1px solid var(--Colors-Brand-Primary, #1472FF);
    background: #0061F50F;
}
.form-header {
//   display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
//   padding-bottom: 1rem;
  border-bottom-color: 1px solid #374151;

  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;

    @include dark {
      color: #F5F5F5;
    }
  }
  p{
    color: 0000008f;
    @include dark{
    color: #AFB6C0;
    }
    font-size: 14px;
  }

  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #6b7280;
    font-size: 1.25rem;
    padding: 0.5rem;
    line-height: 1;
    border-radius: 0.375rem;

    &:hover {
      background-color: #e5e7eb;
    }

    @include dark {
      color: #9ca3af;

      &:hover {
        background-color: #374151;
      }
    }
  }
}

.icon-upload {
  display: flex;
//   align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
    width: 100%;
    p.error-message {
    margin-top: 8px;
    color: #ef4444;
    font-size: 0.75rem;
}
  .icon-preview {
    width: 72px;
    height: 72px;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #ffffff;

    @include dark {
      border-color: #FFFFFF3D;
      background: #1D1E20;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .upload-placeholder {
      font-size: 1.5rem;
      color: #6b7280;

      @include dark {
        color: #9ca3af;
      }
    }
  }
}

.label-icon {
    display: block;
    height: 35px;
    font-size: 016px;
    font-weight: 500;
    color: #1f2937;

    @include dark {
      color: #F5F5F5;
    }
    &::after {
    color: #f55353;
    content: "*";
    display: inline-block;
    font-size: 14px;
    margin-left: 3px;
}
  }
.form-group {
    padding-bottom: 8px;
      width: 49%;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937;

    @include dark {
      color: #AFB6C0;
    }
    &::after {
    color: #f55353;
    content: "*";
    display: inline-block;
    font-size: 14px;
    margin-left: 3px;
   }
  }

  .select-container{
    position: relative;
    display: inline-block;
    width: 100%;
    &::after{
        color: black;
         @include dark {
        color: white;
         }
        position: absolute;
        right: 10px;
        top: 18px;
        transform: translate(-50%, -50%);
        pointer-events: none;
        font-size: 20px;
        content: "\2304";
    } 
    select {
        appearance: none; /* Remove default styles */
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        cursor: pointer;
        position: relative;
   }
  }
  input, select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    background: #ffffff;
    color: #1f2937;

    &::placeholder {
      color: #6b7280;
    }

    &:focus {
      outline: none;
      border-color: #0066ff;
      box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.1);
    }

    &.error {
      border-color: #ef4444;
      background-color: rgba(239, 68, 68, 0.05);
    }

    @include dark {
      background: #FFFFFF08;
      border-color: #FFFFFF1F;
      color: #f9fafb;

      &::placeholder {
        color: #9ca3af;
      }

      &:focus {
        box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.2);
      }
    }
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
//   margin-top: 2rem;
  padding-top: 1rem;
//   border-top: 1px solid #d1d5db;
    width: 100%;
  @include dark {
    border-top-color: #374151;
  }

  button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;

    &.cancel {
      background: #e5e7eb;
      color: #1f2937;
      border: none;

      &:hover {
        background: #d1d5db;
      }

      @include dark {
            background: #FFFFFF0F;
             color: #AFB6C0;

        // &:hover {
        //   background: #4b5563;
        // }
      }
    }

    &.submit {
      background: #0066ff;
      color: white;
      border: none;
        width: 220px;
        height: 48px;

      &:hover {
        background: #0052cc;
      }

      &:disabled {
        background: #99c2ff;
        cursor: not-allowed;
      }
    }
  }
}
}