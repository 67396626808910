@import "../../../../../../styles/helpers"

.removalRequestContainer

    +dark
        background: $n31
        color: white
.removalRequestSection
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0 auto
    
.logoContainer
    width: 187px
    cursor: pointer
    
.removalRequestSection__main
    max-width: 576px
    height:  420px
    padding: 24px
    margin-top: 32px
    border-radius: 8px
    background: $n7
    +dark
        background: $n19 !important
    &__title
        font-size: 16px
        font-weight: 600
        line-height: 24px
        padding-bottom: 4px
        +dark
            color: $white


    &__details
        p
            font-size: 12px
            font-weight: 400
            line-height: 20px
            border-bottom: 1px solid $n15
            padding-bottom: 16px
            +dark
                color: $n16
                border-bottom: 1px solid $n12

    
    .invitation
        font-size: 16px
        font-weight: 600
        line-height: 24px
        padding-bottom: 10px
        margin-bottom: 10px
        border-bottom: 1px solid $n15
        +dark
            border-bottom: 1px solid $n12
.messageContainer
        font-size: 14px
        font-weight: 600
        line-height: 24px
        color: $n14
        display: flex
        flex-direction: column
        gap: 4px
       
        height: 68px  

.Details
    padding-bottom: 20px
    border-bottom: 1px solid $n15
    +dark
        border-bottom: 1px solid $n12        
.viewDocument
    font-size: 14px
    font-weight: 600
    line-height: 24px
    padding-top: 10px
    color: $n3
    +dark
        color: $n14
.message
    font-size: 12px
    font-weight: 400
    line-height: 20px
    color: $n4
    padding-bottom: 20px
    margin-bottom: 10px       
    +dark
        color: $n16
        
.buttonSection
    display: flex
    justify-content: flex-end
    align-items: center
    margin-top: 30px  
    gap: 8px 
    padding-top: 16px
    border-top: 1px solid $n15
    +dark
        border-top: 1px solid $n12

.deny
    width: 108px !important
    height: 52px !important
    border-radius: 8px !important
    height: 52px
    font-weight: 500 !important
    color: $n2 !important
    font-size: 14px !important
    background: rgba(0, 0, 0, 0.12) !important
    transition: 0.3s !important
    &:hover 
            background: rgba(0, 0, 0, 0.3) !important
    +dark
        color: $white !important
        background: $n36 !important  
        &:hover
            background: $n35 !important     

.DenyRequest
    padding: 16px 24px
    border-radius: 8px !important
    height: 52px
    width: 142px !important
    font-size: 14px !important
    font-weight: 500 !important

.removal_invitationFooter    
    margin-top: 60px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 16px
.cancelRequest_Footer    
    margin-top: 32px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 16px

.invitaionSocial
    display: flex
    gap: 8px
    color: $n30
    &__twitterLogo
        background:$n33
        padding: 8px
        border-radius: 100%
        display: flex
        justify-content: center
        align-items: center
        height: 32px
        width: 32px
              
    &__twitterImg
        width: 16px
        height: 16px 

.footerDisclaimer
    color: $n30

.documentHeader
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 30px
    &__document
    font-size: 14px
    font-weight: 600
    line-height: 24px
    color:$n3
    +dark
        color: $n14
    &__file
        font-size: 12px
        font-weight: 400
        line-height: 16px
        color: $n15
.fileLogo
    background: $n6
    padding: 8px
    height: 40px
    width: 40px
    border-radius: 32px
    display: flex
    justify-content: center
    align-items: center
    +dark
        background:$n12
    img
        width: 22px
  

.fileSection
    height:100px
    overflow-y: scroll
.sectionHeight
    height: 150px    
        
.fileContainer
     display: flex
     justify-content: space-between
     align-items: center  
     margin-top: 16px
.fileLeft
    display: flex
    justify-content: space-between
    align-items: center 
    gap: 12px        

.fileDetails
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    font-size: 14px
    font-weight: 500
    line-height: 24px
    &__name
        color: $n4
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        max-width: 297px
        +dark
            color: $n14
    &__size
        color:$n4
        +dark
            color :$n15
       
.viewButton
    display: flex
    gap:8px
    margin-right: 5px
    justify-content: center
    align-items: center    
    width: 77px
    height: 32px
    padding: 8px 12px 8px 12px
    border-radius: 4px
    border: 1px solid $n28
    font-size: 14px
    font-weight: 500
    color: $n28


.removalTextArea
    textarea
        height: 196px
        margin-top: 20px
        border-radius: 4px

.requestDeniedSection
    display: flex
    flex: 1 1
    flex-direction: column
    justify-content: center
    align-items: center
    +dark
        background: $n31
.requestDenied__main
    padding: 24px
    max-width: 576px
    margin-top: 32px
    border-radius: 8px
    background: $n7
    +dark
        background: $n19 !important
    &__heading
        padding-bottom: 16px
        font-size: 16px
        margin-bottom: 20px
        font-weight: 600
        line-height: 24px
        border-bottom: 1px solid $n15
        +dark
            border-bottom: 1px solid $n12
    &__headingTwo
        font-size: 14px
        font-weight: 600
        line-height: 24px
        margin-bottom: 4px
        +dark
            color: $n14 

    &__text
        font-size: 12px
        font-weight: 400
        line-height: 20px
        padding-bottom: 20px
    &__details
        p   
            border-bottom: 1px solid $n15
            +dark
                border-bottom: 1px solid $n12


    
.removal_textArea
    position: relative
.wordCount
    color: $n15
    font-size: 12px
    font-weight: 400
    line-height: 16px
    position: absolute
    bottom: 8px
    right: 16px
    margin-top: 8px
    
.request_cancel
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 8px 
    padding-top: 16px

@media screen and (max-width:768px)
    .removalRequestSection__main
        height: 460px
        margin: 16px
    .removalRequestContainer
        overflow-y: scroll
    .removalRequestSection
        justify-content: space-between   
    .requestDeniedSection
        justify-content: space-between     
    .DenyRequest
        height: 52px !important    
    .requestDenied__main 
        margin: 16px
    .removalRequestSection__main
        max-width: 576px  

   