@import styles/helpers

.verfiyOrderModal
  max-width: 768px !important
  height: 480px 
  background-color: $n19 !important

.checkboxIcon
    color: $n73
    font-size: 18px

.MpcSetupHeader
  height:92px
  display: flex
  flex-direction: column
  gap: 4px
  border-bottom: 1px solid gray
  &__heading
    font-size: 20px
    font-weight: 600
    line-height: 28px
    padding: 24px 0px 0px 24px
    +dark
      color: $white
  &__subHeading
    font-size: 14px
    font-weight: 400
    line-height: 20px
    padding: 0px 0px 0px 24px
    color: $n63
    +dark
      color: $n68
.MpcSetupDescription
  display: flex
  flex-direction: column
  gap: 20px
  padding: 24px
  border-bottom: 1px solid gray
  &__text
    font-size: 14px
    font-weight: 400
    line-height: 20px
    color: $n63
    +dark
      color: $n68

.MpcSetupBenifits
  display: flex
  gap: 8px
  flex-direction: column  
  &__title
    font-size: 14px
    font-weight: 500
    line-height: 20px
    color: $n68
  &__points
    display: flex
    gap: 8px
    align-items: flex-start
    &__Icon
    &__desc
      &__heading
        font-size: 16px
        font-weight: 500
        line-height: 24px

.mpcSetupcheckboxIcon
  color: $n73
  font-size: 24px  

.MpcSetupBtnContainer
  height: 80px
  display: flex
  justify-content: flex-end
  align-items: center
  padding-right: 24px
  gap: 8px
  &__cancel
    padding: 12px 20px
    font-size: 16px
    font-weight: 500 !important
    line-height: 24px
    border-radius: 4px !important
    background: rgba(54, 56, 61, 0.06) !important
    color: $n23 !important
    +dark
      color: $n64 !important
      background: $n71 !important
  &__next
    width: 114px !important
    height: 48px
    padding: 12px 20px
    background: $n74
    color: $n64
    font-size: 16px
    font-weight: 500 !important
    line-height: 24px
    border-radius: 4px !important
.QrcodeContainer
  display: flex
  gap: 24px
  justify-content: center
  align-items: flex-start
  flex-direction: column
  &__details
    width: 544px
    &__desc
      font-size: 16px
      font-weight: 500
      line-height: 24px
      color: $n68
    &__heading
      font-size: 24px
      font-weight: 600
      line-height: 40px
      +dark
        color: $white
  &__Qrcode
    display: flex
    justify-content: center
    align-items: center
    margin: auto
    width: 544px
    height: 260px
    // padding: 40px 80px 40px 80px
    border-radius: 8px
    background-color: $n64
    +dark
        background-color: $n17
    &__image
      width: 180px
      height: 180px

.mpc_status_container
  width: 480px
  height:240px
  padding: 0px 16px 0px 16px
  gap: 16px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  text-align: center
  &__approvedIcon
    height: 64px
    width: 64px
    background: $n73 !important
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
  &__DeclineIcon
    height: 64px
    width: 64px
    background: $n76 !important
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
  &__heading
    font-size: 20px
    font-weight: 600
    line-height: 28px
    +dark
      color: $white
  &__para 
    font-size: 14px
    font-weight: 400
    line-height: 20px
    color: $n63
    +dark
      color: $n68     

.icon
  height: 24px
  width: 24px 

.contactus
  color: $n74  