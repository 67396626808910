.SimplciConfirmationDialog {
  display: flex;
  justify-content: center;
  align-items: center;
  
  &--container{
    width:400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d7dff5;
    border-radius: 6px;
    margin: auto;
    position: absolute;
    background: #fff;
    top: 0;
  }
}
