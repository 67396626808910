@import "../../styles/helper.scss";

.filter-container {
  position: relative;
  height: 100%;
  display: flex;

  .button {
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    height: 100% !important;

    .ri-filter-3-line {
      font-size: 18px;
    }
  }

  .filter-container-body-wrapper {
    background-color: #ffffff;
    position: absolute;
    top: 50px;
    width: 300px;
    height: auto;
    z-index: 999;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: all 0.25s ease-in;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }

    @include dark {
      background-color: #36383d;
    }
  }

  .filter-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-radius: 4px 4px 0px 0px;
    gap: 16px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .filter-container-body {
    display: flex;
    flex-direction: column;

    .filter-container-main-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px 8px 16px;
      border-top: 1px solid #d7dff5;
      cursor: pointer;
      gap: 8px;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-size: 12px;
        }
      }

      span {
        color: #747e99;
        font-size: 20px;
      }
    }
  }

  .filter-container-options {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    background: #f4f5f6;
    gap: 8px;
    transition: all 0.25s ease-in;
  }

  .Checkbox_input__QX96x:checked
    + .Checkbox_inner__HHP-u
    .Checkbox_tick__avD9P {
    border: 1px solid #d7dff5;
    background: #49b875;
  }

  .Checkbox_text__MPGo0 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #6c707a;
    align-self: center;
  }

  @include dark {
    .button {
      background-color: $n12 !important;
      color: $white !important;

      &:hover {
        background-color: darken($n12, 8);
      }
    }

    .filter-container-options {
      background-color: $dark;
    }
  }
}
