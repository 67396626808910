@import "../../../../styles/helper";

.mpc-status-container{
    .header{
        padding: 12px;
    }
    .brand_logo {
        width: 210px;
        height: 100%;
    }
    .body{
        height: 100%;
        display: flex;
        margin-top: 85px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 480px;
        gap: 15px;
        margin-inline: auto;
        .iconWrapper{
            height: 72px;
            width: 72px;
            border-radius: 50%;
            background-color: $n29;
        }
        .iconWrapperGreen{
            background-color: $n73;
        }
        .icon{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
        }
        .heading {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
        }
        .para{
            font-size: 16px;
            font-weight: 500;
            color: $n3;
            text-align: center;
            @include dark{
                color: $n5;
            }
        }
        a{
            color: $n74;
        }
        .button {
            border-radius: 4px;
            height: 40px;
            font-weight: 500;
            font-size: 16px;
            margin-right: 10px;
        }
        .skipButton{
            background-color: $n6;
            color: $n4;
            @include dark{
                background-color: $n19;
            }
        }
    }
}