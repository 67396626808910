@import ../styles/helpers

.page
    background: #18191D
    color: white
    flex: 1 1
    display: flex

.main
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    width: 80%
    margin-inline: auto

    .heading
        font-size: 20px
        font-weight: 600
        line-height: 24px
        margin-top: 10px
        padding-bottom: 5px

.text
    font-size: 14px
    font-weight: 400
    line-height: 16px
    padding-bottom: 24px
    margin-top: 10px
    +dark
        color:#D1D4DB   

.title
    font-size: 16px
    font-weight: 600
    line-height: 16px
    margin-top: 10px
    +dark
        color:#D1D4DB 
.note
  margin-top: 10px
  border: 1px solid rgba(226, 122, 19, 1)
  padding: 5px
  background-color: rgba(226, 122, 19, 0.06)
  display: flex
  gap: 5px
  
.para
    display: inline
    color: $n4
    font-weight: 400
    font-size: 12px
    line-height: 16px
    text-align: left

.icon
    margin-top: -6px
    color: rgba(226, 122, 19, 1)
    font-size: 24px

.app_buttons
    display: flex
    flex-direction: column
    justify-content: center
    gap: 10px
  

.footer
    position: fixed
    bottom: 0
    left: 0
    right: 0
    padding: 24px
    text-align: center

    .footer_item
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center
        gap: 10px
        