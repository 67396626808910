@import "../../../../styles/helper.scss";

.place-bid-modal-container {
  width: 432px;

  .Modal__wrapper {
    padding-inline: 10px;
    padding-bottom: 10px;
  }

  .Modal__closeButton {
    right: 0;
    top: -10px;
  }

  .Modal__closeIcon {
    font-size: 24px;
  }

  .Modal_title {
    margin-bottom: 5px;
  }

  .bid-card-image {
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 175px;
    position: relative;
    margin-bottom: 15px;
    border: 1px solid $n6;
    background-color: $n6;
    display: flex;
    justify-content: center;
    align-items: center;

    .ri-image-add-line {
      font-size: 50px;
      color: $n4;
    }

    .label {
      position: absolute;
      right: 10px;
      background-color: rgba(93, 92, 92, 0.64);
    }

    .label {
      font-size: 12px;
      font-weight: 600;
      top: 10px;
      color: $white;
      padding-inline: 8px;
      border-radius: 50px;
      text-transform: capitalize;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: center;
    }

    .arrow-label {
      position: absolute;
      left: 0;
      top: 10px;
      background-color: $s6;
      padding: 0 6px;
      font-size: 12px;
      color: $white;
      text-transform: capitalize;

      .arrow-label-img {
        position: absolute;
        left: 100%;
        height: 100%;
      }
    }
  }

  .title-heading-symbol {
    line-height: 1.3;
    margin-bottom: 10px;
  }

  .title-heading {
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title-symbol {
    font-size: 14px;
    color: $n4;

    span {
      color: $n2;
    }
  }

  .time-red {
    color: $p3;
  }

  .stats-details {
    display: grid;
    gap: 5px 10px;
    grid-template-columns: 6fr 6fr;
    border-bottom: 1px solid $n6;
    margin-bottom: 15px;
    padding-bottom: 15px;
    align-items: baseline;

    &.rm-border {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  .your-bid-price {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .max-bid-details {
    display: grid;
    gap: 5px 10px;
    grid-template-columns: 6fr 6fr;
    margin-top: 15px;

    .button-red-light {
      font-size: 12px;
      font-weight: 500;
    }

    .stat-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .row-colon {
    .stat-label, .your-bid-label {
      position: relative;

      &:after {
        content: ":";
        display: inline-block;
        color: black;
        position: absolute;
        left: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .disable-step {
    .stat-label, .stat-value, .bid-multiplier-container {
      opacity: 0.4;
    }
  }

  .stat-label {
    font-size: 14px;
    color: $n4;
    font-weight: 500;
    text-transform: capitalize;
  }

  .stat-value {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
  }

  .last-bid-status {
    background-color: $lightRed;
    color: $youtube;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 24px;
    margin-left: 5px;

    &.success {
      background-color: $lightGreen;
      color: black;
    }
  }

  .buy-btn {
    width: 100%;
    border-radius: 4px;

    .circle-loader {
      width: auto;
    }
  }

  .data-room-section {
    border-bottom: 1px solid $n6;
    margin-bottom: 10px;
  }

  .data-room-heading {
    color: $s5;
    font-size: 16px;
    padding-block: 10px;
    font-weight: 600;
    border-bottom: 1px solid $n6;

    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 4px;
      background-color: $s5;
      border-radius: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .edit-history-container.data-room-files {
    max-height: 120px;
    overflow-y: auto;
    padding: 10px 0;

    .file-name {
      font-size: 12px;
      .file-docname{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
      }
    }

    .view-btn {
      margin-left: auto;
    }

    .no-data-found {
      font-size: 16px;
      padding: 0;
      background-color: transparent;

      img {
        width: 50px;
      }
    }
  }

  @include dark {
    .stat-label {
      color: $n5;
    }

    .bid-card-image {
      border-color: $n18;
    }

    .title-heading {
      color: $white;
    }

    .title-symbol {
      span {
        color: $n5;
      }
    }

    .time-color {
      color: $white;
    }

    .stats-details, .data-room-section, .data-room-heading {
      border-bottom-color: $n18;
    }

    .row-colon {
      .stat-label, .your-bid-label {

        &:after {
          color: $white;
        }
      }
    }

    .edit-history-container.data-room-files {
      .edit-img {
        background-color: $n12;
      }
    }
  }

  .separate-line-with-text {
    @include dark {
      span {
        background-color: $n19;
      }
    }
  }

  .buy-now-confirmation {
    text-align: center;
    padding-block: 50px;

    .buy-now-image {
      position: relative;
      height: 180px;
      background-color: $n22_1;
      border-radius: 7px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .buy-now-details {
      position: absolute;
      font-size: 40px;
      font-weight: 600;
      top: 50%;
      width: 100%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      line-height: 1.5;

      small {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .money-icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
      color: $blue;
      font-size: 24px;
      line-height: 1;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0px -1px 3px 0px $n5;
    }

    .confirmation-text {
      font-size: 16px;
      font-weight: 600;
    }

    .button {
      font-size: 14px;
      min-height: 36px;
      height: 36px;
      border-radius: 90px;
    }

    .btn-grey-1 {
      font-weight: 500;
    }

    @include dark {
      .buy-now-image {
        background-color: $n23;
      }

      .money-icon {
        background-color: $n19;
        box-shadow: 0px -1px 3px 0px $n23;
      }

      .btn-grey-1 {
        background-color: $n4 !important;
        color: $white !important;
      }
    }
  }

  .user-input-bid-container {
    grid-column: 1 / span 2;
    line-height: 1.3;

    .input__label {
      color: $n22;
    }

    .input__button {
      color: $p4;
      background-color: transparent;
      border: 2px solid $p4;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
      min-width: 125px;
      gap: 0;
    }

    .input__text-fields {
      background-color: $white;
      border: 1px solid $n6;
    }

    .input__text {
      margin-right: 10px;
      background-color: $n7;
      border-radius: 24px;
      display: inline-block;
      padding: 2px 10px;
    }

    .input__label {
      display: flex;
      gap: 5px;
    }

    .info-icon {
      display: inline-block;
      cursor: pointer;

      .ri-information-fill {
        font-size: 20px;
      }
    }

    .react-tooltip {
      max-width: 300px;
      border: 1px solid $n6;
      background-color: $white;
      color: $n22;
      font-size: 12px;
    }

    .react-tooltip-arrow {
      border-right: 1px solid $n6;
      border-bottom: 1px solid $n6;
    }

    .max-bid-text {
      color: $p3;
      font-size: 12px;
      grid-column: 1 / span 2;
    }

    @include dark {
      .input__text-fields {
        background-color: $n19;
        border-color: $n18;
      }

      .input__text {
        background-color: $n2;
      }

      .react-tooltip {
        max-width: 300px;
        border-color: $n18;
        background-color: $n2;
        color: $white;
      }

      .react-tooltip-arrow {
        border-right-color: $n18;
        border-bottom-color: $n18;
      }
    }
  }

  .bid-multiplier-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .bid-plus-minus {
      display: inline-flex;
      border: 1px solid $n6;
      padding: 8px;
      color: $n3;
      border-radius: 4px;
      width: 32px;
      line-height: 1;
      justify-content: center;
      cursor: pointer;
    }

    .bid-value {
      background-color: $n6;
      font-weight: 600;
    }

    @include dark {
      .bid-plus-minus {
        border-color: $n18;
        color: $n6;
      }

      .bid-value {
        color: $white;
        background-color: $n18;
      }
    }
  }
}
