@import "../../../../styles/helper";

.trading-unavailable {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 48px 24px;
  width: 100%;
  height: 100%;
  background: $n8;
  border: 1px solid $n6;
  border-radius: 8px;
  @include dark {
    background: $dark;
    border-color: $n2;
  }

  .icon-container {
   img{
    width: 120px;
   }
  }

  h2 {
    margin: 8px;
    color: $n21;
    font-size: var(--font-size-4);
    font-weight: 600;
    line-height: 24px;
    text-align: center;
     @include dark{
        color: $n64;
     }
  }

  p {
    color: $n30;
   font-size: var(--font-size-4);
    font-weight: 400;
    line-height: 24px;
     @include dark{
        color: $n9;
     }
  }
}
