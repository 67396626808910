@import ../../../../styles/helpers

.list
    width: 100%
    padding-inline: 20%
    padding-top: 24px
    overflow-y: auto
    display: flex
    flex-direction: column

    +dark
.addAccount
   padding: 16px
.addInput
    padding: 8px 0
.item
    padding: 0 16px
    border-radius: 4px
      
    +m
        flex: 0 0 calc(100% - 4px)
        width: calc(100% - 4px)
        padding: 0 12px
    
    &.soon
        +m
            display: none
.heading
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color:$n12 
    margin: 16px 0 4px    
    +dark
     color: $white
.head
    display: flex
    align-items: center
    padding: 20px 0 16px
    .buttonAaddAccount
        font-size: 12px
        line-height: 16px
        min-height: unset
        padding: 8px 12px
        border-radius: 4px
        height: unset
        width: 76px
.accountMore
    cursor: pointer
    position: absolute
    top: 6px
    right: 20px
    display: none
    .optionBox
        background: #282A2E
        border-radius: 8px
        box-shadow: inset 0 -1px 0 #23262F
        border: 1px solid #23262F
        min-width: 168px
        opacity: 1
        padding: 12px 0
        position: absolute
        right: 0
        top: 100%
        z-index: 1
        button
            color: white
            padding: 4px 8px
            display: flex
            align-items: center
            justify-content: center
            width: 100%
.accountDetail
    display: flex
    background: $n7
    margin-bottom: 16px
    padding: 16px 20px 20px 16px
    border-radius: 8px
    justify-content: space-between
    position: relative
    +dark
       background: #282A2E
    +m
       .accountMore
           display: block   
    &:hover    
       .accountMore
           display: block

.accounList
    display: flex 
    flex-wrap: wrap
    +m
       display: block     
.accountNumber
    color: #878C99
    font-size: 14px
    width: 50%
    display: flex
    align-items: center
    justify-content: space-between
    margin: 4px 0
    &:nth-child(odd)
        padding-right: 24px
    &:nth-child(even)    
        padding-left: 24px
        border-left: 1px solid #777E90
        +dark
            border-left: 1px solid #515767
        +m
            padding: 0
            border: none
    +m
        width: unset
        padding: 0
        &:nth-child(odd)
            padding-right: 0 
    span
        text-transform: capitalize
        font-size: 14px
        color: #777E90
        font-weight: 500
        +dark
            color: #BCC3D6
.cardExpire
    color: #878C99
    font-size: 14px
    +dark
        color: #BCC3D6
.accountData  
    width: 100% 
.accountName    
    display: flex
    align-items: flex-start
    width: 100%
    img
       margin-right: 16px
       height: 48px
       max-width: 48px
       +dark
       
         
.title
    display: flex
    align-items: center
    margin-right: auto
    font-weight: 500
    color: $n3
    +dark
        color: $n7

.details
    text-align: right

.currency
    +body-bold-2

.price
    color: $n4

.bg
    flex-shrink: 0
    width: 12px
    height: 12px
    margin-right: 8px
    border-radius: 4px

.body
   
    .soon
        display: inline-block
        padding: 8px 16px
        box-shadow: inset 0 0 0 2px $n6
        border-radius: 16px
        +button-2
        color: $p4
        +dark
            box-shadow: inset 0 0 0 2px $n3

.btns
    display: flex

.buttons
    height: 32px
    background: #3772FF !important
    color: #FCFCFD
    transition: all 0.2s 
    &:not(:last-child)
        margin-right: 8px

.emptyState
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 48px
    .noDataText
        font-weight: 600
        font-size: 12px
        color:#878C99
        +dark
            color: $n5         
    .buttonAaddAccountEmpty
            border-radius: 8px
            margin-top: 24px
            font-size: 14px
            line-height: 24px
            font-weight: 500
            max-width: 212px
            width: 100%   
            padding:0 24px
            background: #3772FF
            color: #FCFCFD
            transition: all 0.2s   
                
    img
        max-width: 190px

.addCard
    border-radius:0
    +dark
        background:#282A2E
    .addCardHeader    
        padding: 16px 24px
        border-bottom: 1px solid $n6
        font-weight: 600
        font-size: 16px
        line-height: 24px
        display: flex
        align-items: center
        justify-content: space-between
        +dark
            border-bottom: 1px solid #43464D
    .expCvv
        display: flex
        gap: 16px
        .addInput
            width: 50%             

.maskDots
    height: 6px
    width: 6px
    border-radius: 32px
    margin-top: 10px
    display: inline-block
    background: #777E90
    +dark
        background: #FFFFFF   

.accountDots
    height: 6px
    width: 6px
    border-radius: 32px
    margin-top: 10px
    display: inline-block
    background: #777E90
    +dark
        background: #878C99  

        

.dots
    display: flex
    gap: 2px

.cardNo
    display: flex
    gap: 8px    
        
.arrowBack 
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    border-radius: 5px
    cursor: pointer
    border: 1px solid rgb(108, 112, 122)
    margin-right: 24px

.MpcSetupStatusModal
  height: 532px !important
  max-width: 768px !important
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
  +dark
    background: $n21 !important    

.kycStatusModal
  max-width: 576px !important
  padding: 24px
  border-radius: 8px
  +dark
    background: $n21 !important    


.bankNameSection
    display: flex
    justify-content: space-between
    align-items: center
.deleteAccountIcon
    font-size: 20px
    cursor: pointer    
    color: #EB4747 !important

.userName
    font-size: 16px
    font-weight: 500
    line-height: 24px
    text-transform: capitalize
.bankName
    font-size: 14px
    font-weight: 500
    line-height: 20px
    margin: 4px 0px 12px 0px
    color: $n30
    +dark
        color:$n9
    
.subAccounts    
    display: flex
    justify-content:center
    align-items: center
    gap: 8px
    &__account
        width: 304px
        width: 50%
        height: 64px
        display: flex
        justify-content: space-between
        align-items: flex-start
        border-radius: 4px
        padding: 8px 12px
        gap: 4px
        background: #0000000F
        // border-left: 1px solid #EF9943
        +dark
            background: $n36
.accType
    font-size: 14px
    font-weight: 500
    line-height: 20px
    text-transform: capitalize
.primaryAccContainer
    display: flex
    justify-content: center
    align-items: center
.primary
    display: flex
    justify-content: center
    align-items: center
    margin-left: 8px
    width: 56px
    height: 22px
    padding: 4px 8px 
    gap: 8px
    border-radius: 4px
    font-size: 12px
    font-weight: 400
    line-height: 16px
    color: $n69
    background: $n70 

.subAccRight
    display: flex
    flex-direction: column
    align-items: flex-end       
.makePrimaryContainer
    width: 119px
    height: 24px
    padding: 4px 8px 4px 8px
    gap: 4px
    border-radius: 4px 
    display: flex
    justify-content:center
    align-items: center
    font-size: 12px
    font-weight: 500
    line-height: 16px
    color: #5C5C5C
    background: #0000000F
    cursor: pointer
    +dark    
        background:$n36
        color: $n9
    i
        font-size: 16px

.KYCReviewModal
    max-width: 768px !important
    padding: 24px
    border-radius: 8px
    +dark
        background: $n21 !important
