@import "../../../../styles/helpers"

.dvpEmail_container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 60px 0
    width: 360px
    margin-inline: auto


    .dvpEmail_para
        text-align: center
        color: $n4
        font-weight: 500
        margin-bottom: 10px
        padding-bottom: 5px
        border-bottom: 1px solid $n6
        +dark
            border-bottom: 1px solid $n12

    .dvpEmail_para2
        text-align: center
        color: $n4
        font-weight: 400
        margin-bottom: 10px
        padding-bottom: 5px

    .border
        padding-top: 5px
        border-top: 1px solid $n6
        +dark
            border-top: 1px solid $n12
    
    .resend
        color: $n69
        font-weight: 500
        cursor: pointer

    .dvpEmail_email
        color: $n3
        font-weight: 500
        +dark
            color: $n9

    .dvpEmail_edit
        color: var(--color-primary-light)
        cursor: pointer
        font-weight: 500

    .dvpEmail_input
        height: 36px
        border-radius: 4px
        display: flex
        align-items: center
        gap: 5px
        .dvpEmail_inputField
            width: 150px
            height: 100% 
            background: $n7
            padding-left: 8px
            font-size: 14px
            font-weight: 500
            line-height: 18px
            transition: border-color .2s
            border:1px solid $n6
            border-radius: 2px
            +dark
                background: #FFFFFF08
                color: $white
                border: 1px solid $n35 

    .dvpEmail_input2
        align-items: flex-start

    .inputError
        border-color: var(--color-error) !important
        background-color: hsla(0, 100%, 50%, 0.06)!important

    .errorMsg
        text-align: start
        color: red

    .dvpEmail_btn
        background-color: var(--color-primary-light)
        color: white
        font-size: 14px
        font-weight: 600
        max-width: 750px
        align-self: center
        border-radius: 6px
        padding: 16px 24px
        width: 240px

.dvpEmail_container2
    width: 480px