@import "../../../../styles/helper.scss";

.social-pop-up {
    background: #ffffff;
    max-height: 726px;
    min-width: 480px;
    overflow-y: auto;
    border-radius: 4px;

    @include dark {
        background: #282a2e;
    }


    .Modal__wrapper {
        padding: 0px;
        max-height: 100%;
        overflow-y: auto;
    }

    &__modal {
        width: 768px;
        max-height: 726px;
        height: auto;
    }

    &__container {
        position: relative;
        padding: 16px;
        background: #ffffff;
        position: relative;
        max-height: 100%;
        overflow-y: auto;

        @include dark {
            background: #282a2e;
        }

        .close-btn {
            font-size: 24px;
            position: absolute;
            right: 15px;
            top: 5px;
            cursor: pointer;
        }

    }


    &__heading {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
    }
}