@import "../../../../../../styles/helper.scss";

.amount-screen {
    &__loading--wraper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 24px;
        min-height: 250px;
    }

    &__loading-message {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        color: black;
        line-height: 42px;

        @include dark {
            color: #999999;
        }
    }

}