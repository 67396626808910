@import "../../../styles/helper";

.auction-details-containers {
  background-color: $n7;
  overflow-y: auto;
  flex: 1 1;

  .header-sticky {
    position: sticky;
    top: 0;
  }

  .details-and-latest-bids {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    .details-container {
      flex: 1 1 70%;
      background-color: $white;
    }

    .auction-latest-bids {
      flex: 1 1 30%;
      display: flex;

      .latest-bids-container {
        max-height: 600px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .congratulations-image-container {
    position: relative;

    .congratulations-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .ri-checkbox-circle-fill {
      color: $n13;
      font-size: 51px;
      line-height: normal;
      margin-bottom: 15px;
    }

    .congratulations-heading {
      font-size: 20px;
      font-weight: 600;
      color: $white;
    }

    .congratulations-desc {
      font-size: 14px;
      color: $n14;
    }
  }

  .auction-details-heading {
    color: $s5;
    font-size: 18px;
    padding: 15px;
    font-weight: 600;
    background-color: $white;

    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 4px;
      background-color: $s5;
      border-radius: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }

    @include dark {
      background-color: $dark;
    }
  }

  .auction-details-image {
    width: 100%;
    height: 380px;
    object-fit: fill;
    object-position: center;
    border-radius: 0;
    display: inline-block;
    border: 1px solid $n6;
    background-color: $n6;
  }

  .auction-details {
    background-color: $white;
    padding: 15px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    .auction-name {
      font-size: 16px;
      font-weight: 600;
    }

    .title-symbol {
      font-size: 14px;
      color: $n4;

      span {
        color: $n2;
      }
    }

    .auction-desc {
      font-size: 14px;
      font-weight: 400;
      color: $n4;
      text-wrap: wrap;
    }

    @include dark {
      background-color: $dark;

      .auction-name {
        color: $white;
      }
      .auction-desc {
        color: $n5;
      }
      .title-symbol {
        span {
          color: $n5;
        }
      }
    }
  }

  .auction-stats-container {
    background-color: $white;
    border-radius: 4px;
    padding-block: 15px;
    margin-bottom: 15px;

    @include dark {
      background-color: $dark;
    }
  }

  .auction-progress-bar {
    .CircularProgressbar-path {
      stroke: $p4;
      stroke-linecap: butt;
    }

    &.time-red {
      .CircularProgressbar-path {
        stroke: $p3;
      }
    }

    &.time-over {
      .CircularProgressbar-trail {
        stroke: $p3;
      }
    }

    @include dark {
      .CircularProgressbar-trail {
        stroke: $n3;
      }

      &.time-over {
        .CircularProgressbar-trail {
          stroke: $p3;
        }
      }
    }
  }

  .inner-time-circle {
    width: 140px;

    .CircularProgressbar-text {
      font-size: 18px;
      stroke: $p1;
    }

    .CircularProgressbar-path {
      stroke: $p1;
    }

    .CircularProgressbar-trail {
      stroke: $p1_12;
    }
  }

  .radial-stick {
    height: 6%;
  }

  .stats-row {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 20px;
  }

  .stats {
    display: grid;
    grid-template-columns: 200px auto;
    gap: 24px;
    align-items: center;
    margin-bottom: 20px;
    padding-inline: 15px;

    .stats-percent {
      height: 200px;
      padding: 13px;
      width: 200px;
      margin: 0 auto 30px;
      border-radius: 50%;

      .stats-item {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .stats-details {
      display: grid;
      gap: 15px;
      grid-template-columns: 3fr 3fr 3fr 3fr;
    }

    .row-colon {
      .stat-label {
        position: relative;

        &:after {
          content: ":";
          display: inline-block;
          color: black;
          position: absolute;
          left: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
        }
      }
    }

    .stat-label {
      font-size: 12px;
      color: $n4;
      font-weight: 500;
      text-transform: capitalize;
    }

    .stat-value {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
    }

    @include dark {
      .stat-label {
        color: $n5;
      }
    }
  }

  .bid-place-container {
    border-left: 1px solid $n6;

    .stats-details {
      grid-column: 1 / span 2;
      grid-template-columns: auto auto;
      border-bottom: 1px solid $n6;
      margin-bottom: 15px;
      padding-bottom: 15px;
      align-items: center;
    }

    .your-bid-price {
      grid-template-columns: auto;
      text-align: center;
      border-bottom: 0;
      padding-bottom: 0;

      .stat-value {
        font-size: 24px;
      }
    }

    .last-bid-status {
      background-color: $lightRed;
      color: $youtube;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 24px;
      margin-left: 5px;

      &.success {
        background-color: $lightGreen;
        color: black;
      }
    }
  }

  .buy-btn {
    width: 90%;
  }

  .auction-detail-features {
    background-color: $white;
    display: grid;
    grid-template-columns: 7fr 5fr;
    padding: 20px 10px;
    align-items: center;

    .auction-details {
      padding: 0 15px 0 0;
    }

    .bid-img {
      width: 80px;
      height: 80px;
    }

    .bid-detail {
      .name {
        font-weight: 600;
      }

      .time,
      .name {
        font-size: 18px;
      }
    }

    .stats {
      margin-bottom: 0;
      border-left: 1px solid $n6;
    }

    .stats-details {
      grid-column: 1 / span 2;
      grid-template-columns: auto auto;
    }
  }

  .bid-sheet {
    width: 10px;
    min-width: 100%;
  }

  @include dark {
    background-color: black;

    .auction-details-image {
      border-color: $n19;
      background-color: $n19;
    }

    .details-container,
    .auction-detail-features {
      background-color: $dark;
    }

    .auction-detail-features {
      .stats {
        border-left-color: $n18;
      }
    }

    .bid-place-container {
      border-left-color: $n18;

      .stats-details {
        border-bottom-color: $n18;
      }
    }
  }
}

.bid-multiplier-container {
  display: flex;
  align-items: center;
  gap: 8px;

  .bid-plus-minus {
    display: inline-flex;
    border: 1px solid $n6;
    padding: 8px;
    color: $n3;
    border-radius: 4px;
    width: 32px;
    line-height: 1;
    justify-content: center;
    cursor: pointer;
  }

  .bid-value {
    background-color: $n6;
    font-weight: 600;
  }

  @include dark {
    .bid-plus-minus {
      border-color: $n18;
      color: $n6;
    }

    .bid-value {
      color: $white;
      background-color: $n18;
    }
  }
}

.data-room-container {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 10px;
  background-color: $white;
  padding: 15px;

  .data-room-item {
    display: grid;
    gap: 15px;
    align-items: center;
    grid-template-columns: 50px auto;

    .data-room-img {
      width: 50px;
      height: 50px;
      border: 1px solid $n5;
      padding: 8px;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .data-room-details {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: start;

    .name {
      cursor: pointer;
    }

    span {
      font-size: 12px;
      color: $n4;
    }
  }

  @include dark {
    background-color: $dark;

    .data-room-details {
      span {
        color: $n5;
      }
    }
  }
}

.sba-stats-container {
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  @include dark {
    background: #17181b;
  }

  .react-table-responsive-wrapper {
    padding: 0 8px 0 12px;

    tr,
    tr:last-child {
      background: transparent !important;
      border-bottom: 1px solid #d9e0f2;
    }
  }

  .text-elipsis {
    max-width: unset;
  }

  thead tr th {
    color: #777e90;
  }

  button {
    background: #f4f5f6;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 50px;
    @include dark {
      background: transparent;
      border: 1px solid #f4f5f6;
    }
  }
}

#modal {
  .sba-loan-modal {
    max-width: 1080px;
    padding: 24px;

    h2 {
      border-bottom: 1px solid #d7dff5;
      padding-bottom: 12px;
      @include dark {
        color: white;
      }
    }

    h3 {
      font-size: 14px;
      @include dark {
        color: white;
      }
    }

    p {
      font-size: 12px;
      color: #747e99;
    }

    .detail-item {
      padding: 24px 0 0;
      flex: 25% 0 0;
    }

    .detail-items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
