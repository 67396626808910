@import ../../../../styles/helpers
.list
    width: 100%
    +dark
    
.addCard
    border-radius:0
    +dark
        background:$n19
.item
    padding: 0 16px
    border-radius: 4px
      
    +m
        flex: 0 0 calc(100% - 4px)
        width: calc(100% - 4px)
        padding: 0 12px
    
    &.soon
        +m
            display: none
.head
    display: flex
    align-items: center
    padding: 20px 0 16px
    .buttonAaddAccount
        font-size: 12px
        line-height: 16px
        min-height: unset
        padding: 8px 12px
        border-radius: 4px
        height: unset
        width: 87px
.title
    display: flex
    align-items: center
    margin-right: auto
    font-weight: 500
    color: $n3
    +dark
        color: $n7 
.userCount
    font-size: 12px
    font-weight: 500
    line-height: 18px
    margin-left: 8px
    +dark
        color:$n11

               

.details
    text-align: right
.buttons
    height: 32px
    background: $p1 !important
    color: $n8
    transition: all 0.2s 
    &:not(:last-child)
        margin-right: 8px
.body
   
    .soon
        display: inline-block
        padding: 8px 16px
        box-shadow: inset 0 0 0 2px $n6
        border-radius: 16px
        +button-2
        color: $p4
        +dark
            box-shadow: inset 0 0 0 2px $n3


.addInput
    padding: 12px 0   
    +dark
        background: $n19 !important 

.namefield
    display: flex
    gap: 16px
    justify-content: space-between
.addInviteUser
    padding: 16px 24px
    border-bottom: 1px solid $n6
    font-weight: 600
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 8px
    +dark
        background: $n19 !important  
    +dark
        border-bottom: 1px solid $n34 
.inviteUser
    padding: 24px 24px 0px 24px  
    +dark
        background: $n19 !important  


.extraSpace
    // margin-top: 20px    

.userType
    font-size: 12px
    font-weight: 500
    line-height: 16px
    color: $n15
    margin-bottom: 12px
    


.permission
    display: flex
    justify-content: space-between
    padding: 8px 0px    
.tradingPermission
    font-size: 14px
    font-weight: 500
    line-height: 20px
    color: $n15
    margin-bottom: 12px

.modelSection
    position: relative
.input_wrapperr
   border: 2px solid $n12
   border-radius: 12px
   height: 52px 
   display: flex 
   align-items: center
   border: 2px solid $n6
   caret-color: var(--color-bg-100-dark) !important
   +dark
        border: 2px solid $n3
        caret-color: var(--color-bg-110-light) !important
   &.country-label
       max-width:200px
   &.css-1s2u09g-control
     border-width: none !important
     border-style: none !important

.Invite_input_field
   padding: 0px 8px
   letter-spacing: 2px
   font-size: 16px
   width: 100%
   -webkit-box-shadow: 0 0 0 1000px white inset !important 
   -webkit-text-fill-color: black !important
   +dark
        -webkit-box-shadow: 0 0 0 1000px $n19 inset !important 
        -webkit-text-fill-color: white !important


.status
    display: flex
    gap: 8px
    justify-content: center
    align-items: center
.pending
    color: $s5

.joined
    color:$n13

.dotColor
    height: 8px
    width: 8px
    border-radius: 50%
    background: red

.dotPending
    background: $s5
.dotJoined
    background: $n13



.emailInput
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 12px
    border: 2px solid $n6
    background: $n8
    +poppins
    +caption-bold-1
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        background: none
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4


.inputLabel
    text-transform: none !important
    font-weight: 500 !important
    color: $n15 !important

.lastName
    width: 230px
.firstName 
    width: 230px       


.phoneLabel
    font-size: 12px
    font-weight: 500
    line-height: 16px
    color: $n15
    margin-bottom: 12px
    

.inviteUser
    &__footer
        display: flex
        align-items: center
        justify-content: flex-end
        border-top: 1px solid $n6
        height: 84px
        gap: 8px
        border-radius: 8px
        +dark
            background: $n19 !important
            border-top: 1px solid $n34

    &__cancel
        padding: 16px 24px
        border-radius: 8px !important
        height: 52px
        font-weight: 500 !important
        background: rgba(0, 0, 0, 0.12) !important
        color: $n2 !important
        &:hover 
            background: rgba(0, 0, 0, 0.3) !important
        
        +dark
            color: $white !important
            background: $n36 !important
            &:hover
                background: $n35 !important

    &__invite 
        padding: 16px 24px
        border-radius: 8px !important
        height: 52px
        width: 112px !important
        font-weight: 500 !important
        margin-right: 24px 
        
.userSection
    display: flex
    flex-direction: column
    gap: 16px
    height: calc(100vh - 20vh)
    overflow-y: auto

.invitedUsersContainer
    border-radius: 8px
    padding: 16px    
    background: $n7
   
    +dark
        background: $n19
    .invitedUsers
        display: flex
        justify-content: space-between
        &__userName
            font-size: 16px
            font-weight: 500
            line-height: 24px
            display: flex
        &__accountid 
            font-size: 12px   
            
.userDetails
    font-size: 14px
    font-weight: 500
    line-height: 20px
    margin-top:16px
    color: $n16
    display: flex
    justify-content: space-between
    flex-direction: column
    gap: 8px
    &__left
        width: 319.5px
        display: flex
        flex-direction: column
        gap: 8px
    &__emailfield
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    
.details
    display: flex
    justify-content: space-between
    color: #6C7489
    gap: 16px
    +dark
        color: $n16

    

.speration
    border-right: 1px solid $n4
    +dark
    border-right: 1px solid $n37
.divider
    display: flex
    justify-content: space-between

    
.emptyState
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 96px
    .noDataText
        font-weight: 500
        font-size: 12px
        color:$n11
        line-height: 16px
        +dark
            color: $n5 
    .heading
        margin-top: 16px                
    .buttonAaddAccountEmpty
            border-radius: 8px
            margin-top: 24px
            font-size: 14px
            line-height: 24px
            font-weight: 500
            max-width: 122px
            width: 100%   
            padding:12px, 24px, 12px, 24px
            color: $n8
            transition: all 0.2s
    img
        max-width: 190px



    /* Target autofilled input fields */
input:-webkit-autofill
    caret-color: var(--color-bg-100-dark) !important
    +dark
          caret-color: var(--color-bg-110-light) !important


/* Target autofilled textareas */
TextInput:-webkit-autofill
    background-color: $n3


.userDropdown
    width: 195px
    margin-right: 24px

.deleteModal
    width: 480px !important        
    
.removeUser
    &__footer
        display: flex
        align-items: center
        justify-content: flex-end
        gap: 8px 
        width: 480px
        height:84px
        border-bottom-left-radius: 8px
        border-bottom-right-radius: 8px
        border-top:1px solid $n6
        padding-right: 24px
        +dark
            background: $n19
            border-top:1px solid $n34
       
     

    &__cancel
        padding: 16px, 24px, 16px, 24px 
        border-radius: 8px !important
        background: rgba(0, 0, 0, 0.12) !important
        font-size: 14px !important
        font-weight: 500 !important
        line-height: 20px 
        color: $n2 !important
        &:hover 
            background: rgba(0, 0, 0, 0.3) !important
        +dark
            color: $white !important
            background: $n36 !important
            &:hover
                background: $n35 !important
       
    &__remove
        background:$n24 !important
        width: 112px !important
        padding: 16px, 24px, 16px, 24px 
        border-radius: 8px !important
        font-size: 14px !important
        font-weight: 500 !important
        line-height: 20px
        width: 172px !important
        &:hover
            background: $n29 !important
        
    &__editUser
        width: 112px !important
        padding:16px, 24px, 16px, 24px 
        border-radius: 8px !important
        font-size: 14px !important
        font-weight: 500 !important
        line-height: 20px
        width:135px !important

.userDetailContainer
    display: flex
    justify-content: space-between
    align-items: center

.userTypeDetails
    display: flex
    width: 214px
    align-items: start   
    gap: 8px
    margin-left: 5px

.userAvatar
    width: 40px
    height: 40px
    border-radius: 40px
    margin-right: 12px
    
input[type="radio"]:checked
    accent-color:$n28

.editingRemovalRequest
    background: $n28 !important
    width: 144px !important
    &:hover
        background: $blue !important


    