@import "../../../../../../../styles/helper";

.asset-modal {
  max-width: 500px;
  width: 480px;
  .h4 {
    svg {
      display: none;
    }
  }

  & > div {
    padding: 0;
  }
  .asset-Modal-form {
    padding: 24px;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }

  .asset-dropdown {
    background: #f4f5f6;
    .Dropdown_head__USTbf {
      background: #f4f5f6;
    }
    @include dark {
      background: transparent;
      .Dropdown_head__USTbf {
        background: $dark;
      }
    }
  }
  .create-asset-btn {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px 24px 16px 24px;
    border-top: 1px solid #d7dff5;

    button {
      border-radius: 8px;
    }
  }

  .asset-modal-header {
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid #d7dff5;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #2f323d;
      @include dark {
        color: $white;
      }
    }
  }

  .Modal_close__nt4fk {
    top: 14px;
  }

  @include dark {
    .input__text-fields,
    textarea {
      border-color: $n18;
      background-color: transparent;
    }
    .input__fields {
      color: $white;
      caret-color: $white;
    }
    .TextArea_label__ewxUT {
      color: $white;
    }

    .input__label {
      color: $white !important;
      text-transform: capitalize;
      font-weight: 500;
    }
  }
}
