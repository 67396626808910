@import styles/helpers

.IssuerNameModal
    height: 300px !important
    max-width: 576px !important
    border-radius: 8px
    +dark
        background: $n21 !important    

.issuerNameModal__top   
    height: 92px
    padding: 24px 56px 16px 24px
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 4px
    border-bottom: 1px solid $n14
    +dark
        border-bottom: 1px solid $n35
    &__heading   
        font-size: 20px
        font-weight: 600
        line-height: 28px
    &__subHeading   
        font-size: 14px
        font-weight: 400
        line-height: 20px
        color: $n63
        +dark
            color: $n68

.issuerNameModal__middle
    height: 128px
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 16px 24px
    &__label     
        font-size: 14px
        font-weight: 500
        line-height: 24px
        color: $n63
        +dark
            color: $n9
    &__input    
        width: 528px
        height: 48px
        border-radius: 4px
        position: relative
        &__field
            width: 100%
            height: 100% 
            background: #FFFFFF08
            padding-left: 8px
            font-size: 16px
            font-weight: 500
            line-height: 24px
            transition: border-color .2s
            border:1px solid $n6
            border-radius: 2px
            +dark
                color: $white
                border: 1px solid $n35 
            &__green
                color:$n73
            &__red
                color: $n29
    
        i
            position: absolute
            top: 50%
            right: 10px
            transform: translateY(-50%)
            font-size: 24px
        
    &__error
        display: flex
        align-items: center
        justify-content: flex-start
        color: $n77   
        font-size: 12px
        font-weight: 500
        line-height: 16px
        gap: 4px
        margin-top: 4px
        &__icon
            font-size: 16px

.IssuerNameModaBtnContainer
    height: 80px
    display: flex
    justify-content: flex-end
    align-items: center
    padding-right: 24px
    gap: 8px
    border-top: 1px solid $n14
    +dark
        border-top: 1px solid $n35
    &__cancel
        padding: 12px 20px
        font-size: 16px
        font-weight: 500 !important
        line-height: 24px
        border-radius: 4px !important
        background: rgba(54, 56, 61, 0.06) !important
        color: $n23 !important
        +dark
            color: $n64 !important
            background: $n71 !important
    &__save  
        width: 114px !important
        height: 48px
        padding: 12px 20px
        background: $n74
        color: $n64
        font-size: 16px
        font-weight: 500 !important
        line-height: 24px
        border-radius: 4px !important
      