@import ../styles/helpers


.main
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    height: 100vh
    margin: auto
    width: 342px
    gap: 12px

.title
    padding-inline: 10px
    

.text
    font-size: 12px
    font-weight: 400
    line-height: 20px
    +dark
        color:#D1D4DB

.page
    background: #fcfdfe
    color: black
    +dark
        color: white
        background: #18191D    

.store
    margin-bottom: 8px   
    width: 166.15px
    height: 48px

.app_buttons
    display: flex
    flex-direction: column
    justify-content: center
    gap: 10px

.footer
    position: fixed
    bottom: 0
    left: 0
    right: 0
    padding: 24px
    text-align: center

.logoSection
    height: 64px
    
        
             



           

     
