@import "../../../../styles/helper.scss";

.wallet-select-card--wapper{
  min-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.wallet-select-card {
  display: flex;
  flex-direction: row; // Changed from column to row
  flex-wrap: wrap; // Added to wrap cards
  gap: 16px;
  padding: 24px;
  width: 100%;

  .card {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 8px); // Changed from 100% to half width
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: black;
    border: 1px solid #3C4043;
    min-height: 48px; // Added to ensure consistent height

    @include dark {
      background: #FFFFFF;
      border: 1px solid #757575;
    }

    // ... existing code ...
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      
      i {
        color: #ffff;
        font-size: 26px;
        @include dark {
          color: #4c4f53;
        }
      }
    }

    &:hover {
      background: #2A2A2A;
      
      @include dark {
        background: white;
      }
    }

    p {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: -1px;
      color: #FFFFFF;
      margin-top: 4px;
      
      @include dark {
        color: #4c4f53;
      }
    }

    &.active-card {
      background: #2A2A2A;
      border-width: 2px;
      border-color: #4574f5;
      
      @include dark {
        background: #F5F5F5;
        border-color: #4574f5;
      }
    }
  }

  .card-manual {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 8px); // Changed from 100% to half width
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-height: 48px; // Added to ensure consistent height
    
    background: black;
    border: 1px solid #3C4043;

    @include dark {
      background: #FFFFFF;
      border: 1px solid #757575;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      
      i {
        color: #ffff;
        font-size: 26px;
        @include dark {
          color: #5f6368;
        }
      }
    }

    p {
      margin: 0;
      font-size: 22px;
      letter-spacing: -1px;
      font-weight: 500;
      color: #FFFFFF;
      
      @include dark {
        color: #4c4f53;
      }
    }

    &:hover {
      background: #2A2A2A;
      
      @include dark {
        background: white;
      }
    }

    &.active-card {
      background: #2A2A2A;
      border-width: 2px;
      border-color: #4574f5;
      
      @include dark {
        background: #F5F5F5;
        border-color: #4574f5;
      }
    }
  }

  &__google-pay-button-wrapper {
    width: calc(50% - 8px); // Changed from 100% to half width
    height: 48px;
    border-width: 2px;
    border-radius: 4px;
  }
}