@import styles/helpers

.confirmationModalOuter
   max-width: 480px
.KYCReviewModal
  max-width: 768px !important
  padding: 24px
  border-radius: 0px !important
  +dark
    background: $n21 !important

.loginAppPopupModal

  &__wrapper
    position: absolute
    z-index: 99
    width: 100%

  &__crossIcon
    position: absolute
    top: 10px
    right: 12px
    font-size: 20px
