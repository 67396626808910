@import styles/helpers
.AssetDetail
    background: $white
    border-radius: 8px
    +dark
        background: $dark
        border-color: $n2

.nav
    display: flex
    overflow-x: scroll
    border-bottom: 1px solid $n6
    padding: 0px 20px
    &::-webkit-scrollbar
        display: none
    +dark
        border-bottom: 1px solid $n2
.link
    display: flex
    padding: 16px
    background: none
    text-transform: capitalize
    min-width: max-content
    +poppins
    font-weight: 500
    font-size: 12px
    color: #777E91
    transition: all .2s
    border-bottom: 1px solid transparent
    &:hover
        color: $n10
        +dark
            color: $n10
    &.active
        color: $n10
        border-bottom: 1px solid $n10
        +dark
            color: $n10
            border-bottom: 1px solid $n10
.outer
    display: flex
    flex-direction: row

.detail
    position: sticky
    top: -3px
    z-index: 9
    background-color: #fff
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    +dark
        background-color: $dark
.tab
    position: sticky
    top: 0
    z-index: 1
    background-color: $n17

.pre_button
    position: absolute
    height: 100%
    background: $white
    font-size: 20px
    border-top-left-radius: 8px
    border-bottom: 1px solid $n6
    left: 0px
    display: flex
    justify-content: center
    align-items: center
    color: #777E91
    &:hover
        color: $n10
    +dark
        background: $dark
        border-bottom: 1px solid $n2
    
.next_button
    position: absolute
    height: 100%
    background: $white
    font-size: 20px
    border-top-left-radius: 8px
    border-bottom: 1px solid $n6
    right: 0px
    display: flex
    justify-content: center
    align-items: center
    color: #777E91
    &:hover
        color: $n10
    +dark
        background: $dark
        border-bottom: 1px solid $n2
.emptyState
    height: 400px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 8px
    .noDataText
        font-weight: 600
        font-size: 14px
        color: $n5
        +dark
            color: $n5




