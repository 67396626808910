@import "../../styles/helper";

.data-room-file-container {
  display: grid;
  vertical-align: middle;
  height: 268px;
  padding: 72px, 32px, 72px, 32px;
  border-radius: 8px;
  gap: 8px;
  background: #f4f5f5;
  border: 1px solid #ccd2e0;
  cursor: pointer;
  @include dark {
    background: $dark;
    border: 1px solid $white;
  }
}

.auction-form-data-room {
  display: flex;
  gap: 24px;
  flex-flow: column nowrap;

  .data-room-files-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include dark {
      p {
        color: $white;
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #2f323d;
    }

    .file-count {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #747e99;
    }
  }
}

.data-room-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .upload-icon {
    font-size: 64px;
    color: #4574f5;
  }
  .data-room-content-browse {
    color: $blue;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #515767;
  }

  small {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #7c8398;
    margin-top: -4px;
  }

  .image_browse {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    opacity: 0;
    cursor: pointer;
  }
}

.file-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-room-files {
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  gap: 14px;

  .file-details-gap {
    display: flex;
    gap: 12px;
    padding: 5px 10px;
    align-items: center;
    height: auto;

    &:hover {
      transition: all 0.4s ease-in;
      cursor: move;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    }
  }

  .file-details-no-gap {
    display: flex;
    gap: 12px;
    padding: 5px 0px;
    align-items: center;
    height: auto;

    &:hover {
      transition: all 0.4s ease-in;
      cursor: default;
      box-shadow: none;
    }
  }


  .file-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
    background: #3d3d3d0f;

    img {
      width: 20px;
      height: 24px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #363a45;
  }

  small {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #747e99;
  }

  @include dark {
    p,
    small {
      color: $white;
    }

    .file-icon-container {
      background: #3d3d3d0f;
    }
  }
}

.files-description-container {
  display: flex;
  gap: 4px;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  margin-right: 10px;
}

.files-description {
  display: flex;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  .file-names {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 1 0;

    p {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .file-loading-status {
    font-size: 12px;
    font-weight: 500;
    color: #7c8398;
    text-transform: capitalize;
  }
}

.loading-placeholder {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background-color: lightgrey;
  .loading-percentage {
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    // width: 40%;
    z-index: 10;
    transition: all 0.25s ease-in;
    background: #458bf5;
  }
}

.delete-file {
  font-size: 24px;
  cursor: pointer;
  color: #7c8398 !important;
}

.ri-loader-3-line {
  font-size: 24px;
  cursor: inherit;
  color: #7c8398 !important;
}

.ri-close-circle-line {
  font-size: 20px;
  color: #7c8398;
}

.edit-file-btn {
  padding: 4px 12px;
  border-radius: 32px;
  background: #3c65d60f;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #3c65d6;
}

.edit-file-btn {
  padding: 4px 12px;
  border-radius: 32px;
  background: #3c65d60f;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #3c65d6;
}

.pointer-evenet-none {
  pointer-events: none;
}
