@import ../../../../styles/helpers

.withdrawHeader    
    padding: 16px 24px
    border-bottom: 1px solid $n6
    font-weight: 600
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    justify-content: space-between
    +dark
        border-bottom: 1px solid #43464D
.withdrawBody
    padding: 24px
.withdrawFooter
    padding: 12px 24px
    border-top: 1px solid $n6                     
    +dark
        border-top: 1px solid #43464D
    .continueWithdraw
        width: 100%
        font-weight: 500
        font-size: 14px
        line-height: 20px
        border-radius: 8px
        border: none
        background: #3772FF
        color: #FCFCFD
        transition: all 0.2s
.label
    margin: 20px 0 8px 
    +hairline-2
    color: $n5
    text-transform: capitalize   
.customLabel
    text-transform: capitalize      
.selectedAccount
    display: flex
    background: $n7
    padding: 12px
    border-radius: 8px
    justify-content: space-between
    position: relative
    align-items: center
    +dark
       background: #36383D
    button
        border-radius: 40px
        padding: 4px 8px
        font-weight: 500
        font-size: 12px
        line-height: 16px
        background: $white
        +dark
             background: rgba(255, 255, 255, 0.06)
             color: #BCC3D6   
    img
       margin-right: 16px
       height: 48px
       max-width: 48px
       +dark
.accounDetail
    display: flex
    align-items: center     
.accountName    
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: #23262F
    +dark
         color: $n14
.accountNumber
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: #878C99
    margin-top: 4px
    display: flex
    align-items: center
    text-transform: capitalize
.accounType
    margin-right: 4px    
.maskDots
    width: 4px
    height: 4px
    display: inline-block
    background: #878C99
    border-radius: 4px
    margin: 0 1px
    &:first-child
        margin-left: 2px

.avlBalance
    border-radius: 8px
    margin-bottom: 16px
    padding: 16px 12px
    display: flex
    justify-content: space-between
    color: #A2A7B8
    font-weight: 500
    font-size: 14px
    line-height: 24px
    background: $n7
    +dark
        background: #36383D
.balanceAmt
    font-weight: 600
    font-size: 14px
    line-height: 24px
    color:#23262F
    +dark
        color: #FFFFFF
.amountWithdraw
    padding-top: 16px
    border-top: 1px dashed #51545C

.transactionSuccess
    display: flex
    flex-direction: column
    justify-content: center
    height: 320px
    width: 480px
    align-items: center
    padding: 24px
    border-radius: 8px
    +dark 
        background: #282a2e 

    .successHead
        font-weight: 600
        font-size: 32px
        line-height: 48px
    .successSubHead
        font-size: 24px
        font-weight: 600
        line-height: 36px
        color: $n3
        margin: 4px 0 16px
        +dark
            color: $white 
        
    .successPara
        font-size: 14px
        font-weight: 400
        line-height: 24px
        margin: 4px 0 16px
        text-align: center
        +dark
            color: $n16
        span
            font-weight: 600
            color: $n2
            +dark
                color: #FFFFFF     
    .transactionDetailsContainer
        display: flex 
        padding: 20px 16px
        flex-direction: column
        border-radius: 8px
        background:#F4F5F6
        width: 100%
        gap:16px
        +dark
            background: #36383D

               
    .transactionDetail
        border-radius: 8px
        display: flex
    .transactionCol
        width: 50%
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: $n2
        +dark 
            color: #A2A7B8
    .transactionStatus
        font-weight: 500
        font-size: 14px
        line-height: 20px
        margin-top: 4px
        &__success
            color:$n13
        &__processing 
            color: $s5  
        &__failed
            color: $n24       

    .transactionId
        font-weight: 500
        font-size: 14px
        line-height: 20px
        display: flex
        justify-content: flex-start
        align-items: center
        gap: 4px
        color: $n2
        margin-top: 4px
        +dark
            color: #FFFFFF
    &__footer
        display: flex
        gap: 8px
        align-items: center
        margin-top: 24px
        margin-left: 140px
        &__cancel
            width: 112px
            height: 52px
            padding: 16px 24px 16px 24px
            border-radius: 8px
            background: rgba(0, 0, 0, 0.12)
            color: $dark
            +dark
                background: rgba(255, 255, 255, 0.12)
                background: $n36
                color:$n67
        &__view     
            width: 170px
            height: 52px
            border-radius: 8px
            background: $blue
            color: $white
               


              