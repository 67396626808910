@import "../../styles/helper";

.auction__container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 24px 24px 0px 24px;
  overflow-y: auto;
  @include dark {
    background-color: black;
  }

  .auction__container-body {
    td:nth-child(4) {
      text-transform: capitalize;
    }

    td:nth-child(2)::first-letter {
      text-transform: capitalize;
    }

    td:nth-child(1)::first-letter {
      text-transform: capitalize;
    }

    tr {
      cursor: pointer !important;
      background-color: $white !important;

      &:hover {
        transition: all 0.25s;
        background-color: $n6 !important;
      }
    }

    td {
      background-color: transparent !important;
    }

    th {
      background: #d7f5e9;
      color: $n21 !important;
    }

    @include dark {
      th {
        background: $dark;
        color: $white !important;
        border-bottom: 1px solid $white;
      }
      tr {
        background-color: $dark !important;

        &:hover {
          transition: all 0.25s;
          background-color: $n3 !important;
        }
      }
    }
  }

  .auction__table-product {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    img {
      width: 40px;
      height: 40px;
      border-radius: 24px;
      margin-right: 8px;
      object-fit: fill;
      background-position: center;
    }

    .auction__table-product-desc {
      display: flex;
      flex-flow: column;
      gap: 4px;

      .product-name,
      .product-desc {
        width: 40ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }


      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: $n23;

        @include dark {
          color: $white;
        }
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: $n22;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;

        @include dark {
          color: $white;
        }
      }
    }
  }

  .auction__table-date-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;

    .auction__table-time {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: $n22;
      @include dark {
        color: $white;
      }
    }

    .auction__table-date {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $n23;
      @include dark {
        color: $white;
      }
    }
  }

  .auction-date-flex-center {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-left: -10px;
  }

  .asset-action-icons {
    display: flex;
    gap: 10px;

    span {
      width: 15px;
      cursor: pointer;
    }

    .ri-delete-bin-line {
      color: $n24;
    }
  }


.auction-main-price{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-80-light);
  @include dark {
    color: $white;
  }
}

.auction-sub-price{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-text-70-light);
  @include dark {
    color: $white;
  }
}

  .auction__table-status {
    i {
      padding: 4px;
      border-radius: 50%;
      display: inline-flex;
      margin-right: 5px;
    }
  }

  .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .circle-green {
    background-color: $n13;
  }

  .circle-red {
    background-color: $n24;
  }

  .circle-dark {
    background-color: $n22;
  }
}
