@import styles/helpers

.verfiyOrderModal
  max-width: 768px !important
  height: 480px 
  +dark
    background-color: $n19 !important

.modalContent
  display: flex
  height: 480px 
  width: 444px
  gap: 16px
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 0 auto
  text-align: center
  &__heading
      font-size: 20px
      font-weight: 600
      line-height: 28px
      +dark
        color: $n64
  &__subHeading
      font-size: 14px
      font-weight: 400
      line-height: 20px
      color: $n63
      +dark
        color: $n68
    
  &__btnContainer
    display: flex
    gap: 8px
    justify-content: center
    align-items: center      
  &__btnClose
    width: 102px
    height:48px
    padding: 12px 20px
    border-radius:4px
    font-size: 16px
    font-weight: 500
    line-height: 24px
    background: rgba(54, 56, 61, 0.06)
    +dark
      color: $white
      background: $n12
  &__btnVerify
    width:205px
    height:48px
    padding: 12px 16px 12px 16px
    border-radius: 4px
    background: $n74
    color: $white
    font-size: 16px
    font-weight: 500
    line-height: 24px

    
  &__btnExtendTime
    width: 154px
    height:48px
    padding:12px 20px
    border-radius: 4px
    font-size: 16px
    font-weight: 500
    line-height: 24px
    background: $n74
    color: $white
  &__approvedIcon
    height: 64px
    width: 64px
    background: $n73 !important
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
  &__DeclineIcon
    height: 64px
    width: 64px
    background: $n76 !important
    border-radius: 100%
    display: flex
    justify-content: center
    align-items: center
  
.icon
  height: 24px
  width: 24px
.clockImage
  width:86px
  height: 96px



.flipClock
  --fcc-flip-duration: 1s
  --fcc-digit-block-radius: 8px
  --fcc-digit-block-width: 108px 
  --fcc-digit-block-height: 64px
  --fcc-label-color: $n9