@import styles/helpers

.confirmationModal
  +poppins

.confirmationImage
  width: 96px
  height: 96px

.confirmationHeader
  height: 160px
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  background: #EDEFF5
  border-bottom: 1px solid $n14
  border-radius: 20px
  +dark
    background: $n12
    border-bottom: 1px solid #3A3F4D
.confirmationTitle
  font-weight: 600
  font-size: 16px
  margin-top: 8px

.modalDes
  font-weight: 400
  font-size: 14px
  padding: 24px
  line-height: 20px
  padding-bottom: 6px
  color: #6C707A
  +dark
    color: #C9CDD6
.modalFooter
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 16px 24px
  border-top: 1px solid $n14
  +dark
    border-top: 1px solid #3A3F4D

.buttonCancel
  font-size: 14px
  padding: 16px 24px
  min-width: 92px
  height: 52px
  background: rgba(54, 56, 61, 0.06)
  line-height: 1
  border-radius: 8px
  color: #51545C
  margin-right: 8px
  +dark
    background: rgba(255, 255, 255, 0.06)
    color: #D8DBE6

.buttonCancelYes
  font-size: 14px
  position: relative
  padding: 16px 24px
  min-width: 92px
  height: 52px
  line-height: 1
  background: #F54545
  border-radius: 8px
  color: $white

.loaderIcon
  position: absolute 
  top: 50% 
  left: 50% 
  transform: translate(-50%, -50%) 
  visibility: hidden
  
.loadingActive .btnText
  visibility: hidden

.loadingActive .loaderIcon
  visibility: visible

.btnSuccess
  background: #49B876
.btnbuy
  background: $blue

.paymentTypeContainer
  margin-top: 16px
  &__radioBtns

  span
    font-size: 12px
    font-weight: 500
    line-height: 16px
    +dark
      color: $n15

.paymentType
  display: flex
  flex-direction: column
  &__radioBtns
    display: flex
    gap: 8px
    padding: 4px 0px
    p
      font-size: 14px
      font-weight: 500
      line-height: 20px
      +dark
        color: $n16


.kycStatusModal
  max-width: 576px !important
  padding: 24px
  border-radius: 8px
  +dark
    background: $n21 !important

.MpcSetupStatusModal
  height: 532px !important
  max-width: 768px !important
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
  +dark
    background: $n21 !important    

.totalAmountWrapper
  display: flex
  flex-direction: column
  align-items: center
  background-color: rgba(188, 123, 58, 0.12)
  margin:0px 24px
  padding:8px 12px 8px 12px
  border:2.5px dashed rgba(237, 137, 39, 1)
  margin-top: 0px

.totalAmount
  gap: 4px
  flex-direction: column
  display: flex
  div
    display: flex
    gap: 2rem
    span:nth-child(1)
     width: 115px
      
      
.issuance
  font-size: 14px
  font-weight: 500
  line-height: 20px
  color: #6C707A
  margin: 8px 24px 8px 24px 
  +dark
    color: #C9CDD6
