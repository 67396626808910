.gap-16 {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p.heading {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #a2a7b8;
    margin-bottom: 5px;
  }
}
