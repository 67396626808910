.session-detail-modal{
  z-index: 9999 !important;
  max-width: 1300px !important;
  min-width: 1300px !important;
  .Modal__wrapper{
    max-height: 700px;
    padding: 0 24px !important;
    overflow-y: auto;
    min-width: 1300px !important;
  }
  .kycDetails:nth-child(2){
    max-height: 600px !important;
    overflow-y: auto;
  }
  .session-column .activity-sticky{
    top: 0 !important;
  }

  .simplici-session-loader {
    min-height: 700px;
    display: flex;
    align-items: center;
  }

  iframe {
    width: 100%;
    height: 700px;
  }

  .kycDetail-row__basicInfoContainer, .Sessions-Details {
    color: black;
  }
}
