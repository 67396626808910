@import "../../../../../../styles/helpers"


.removalDecisionSection
    overflow-y: scroll
    flex: 1 1
    +dark
        background: $n31
        color: white
.invitationContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0 auto
    
.logoContainer
    width: 187px
    cursor: pointer

.invitationDecisionDetails
    width: 576px
    height:  628px
    padding: 24px
    margin-top: 32px
    border-radius: 8px
    background: $n7
    +dark
        background: $n19 !important
    .invitation
        font-size: 16px
        font-weight: 600
        line-height: 24px
        padding-bottom: 10px
        margin-bottom: 10px
        border-bottom: 1px solid $n15
        +dark
            border-bottom: 1px solid $n12
.messageContainer
        font-size: 14px
        font-weight: 600
        line-height: 24px
        color: $n14
        display: flex
        flex-direction: column
        gap: 4px
        height: 68px  

.Details
    padding-bottom: 20px
    border-bottom: 1px solid $n15
    +dark
        border-bottom: 1px solid $n12        
.viewDocument
    font-size: 14px
    font-weight: 600
    line-height: 24px
    padding-top: 10px
    color: $n3
    +dark
        color: $n14
.message
    font-size: 12px
    font-weight: 400
    line-height: 20px
    color: $n4
    padding-bottom: 20px
    margin-bottom: 10px       
    +dark
        color:$n16
        
.buttonSection
    display: flex
    justify-content: flex-end
    align-items: center
    margin-top: 30px  
    gap: 8px 
   


.deny
    width: 108px !important
    height: 52px !important
    border-radius: 8px !important
    height: 52px
    font-weight: 500 !important
    color: $n2 !important
    font-size: 14px !important
    background: rgba(0, 0, 0, 0.12) !important
    transition: 0.3s !important
    &:hover 
            background: rgba(0, 0, 0, 0.3) !important
    +dark
        color: #fff !important
        background: $n36 !important
        &:hover
            background: $n35 !important


.approve
    padding: 16px 24px
    border-radius: 8px !important
    height: 52px !important
    width: 112px !important
    font-size: 14px !important
    font-weight: 500 !important

.invitationFooter    
    margin-top: 32px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 16px

.invitaionSocial
    display: flex
    gap: 8px
    color: $n30
    &__twitterLogo
        background:$n33
        padding: 8px
        border-radius: 100%
        display: flex
        justify-content: center
        align-items: center
        height: 32px
        width: 32px
    &__twitterImg
        width: 16px
        height: 16px           
.footerDisclaimer
    color: $n30

.documentHeader
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 30px
    &__document
    font-size: 14px
    font-weight: 600
    line-height: 24px
    color: $n3
    +dark
        color: $n14
    &__file
        font-size: 12px
        font-weight: 400
        line-height: 16px
        color: $n15
.fileLogo
    background: $n6
    padding: 8px
    height: 40px
    width: 40px
    border-radius: 32px
    display: flex
    justify-content: center
    align-items: center
    +dark
        background:$n12
    img
        width: 22px
  

.fileSection
    height:224px
    overflow-y: scroll
        
.fileContainer
     display: flex
     justify-content: space-between
     align-items: center  
     margin-top: 16px
.fileLeft
    display: flex
    justify-content: space-between
    align-items: center 
    gap: 12px        

.fileDetails
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    font-size: 14px
    font-weight: 500
    line-height: 24px
    &__name
        color: $n4
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        max-width: 290px
        +dark
            color:$n14
    &__size
        color: $n4
        +dark
            color :$n15
       
.viewButton
    display: flex
    gap:8px
    margin-right: 5px
    justify-content: center
    align-items: center    
    width: 77px
    height: 32px
    padding: 8px 12px 8px 12px
    border-radius: 4px
    border: 1px solid $n28
    font-size: 14px
    font-weight: 500
    color: $n28
    
.removalProcess
    &__details
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 30px
        font-size: 14px
        font-weight: 500
        line-height: 24px
        border-bottom: 1px solid lightgray
        color: $n11
        
        +dark
            background: $n19
            border-bottom: 1px solid $n12
            
    &__dateHeading
        display: flex
        justify-content: space-between
        align-items: center
        font-size: 14px
        font-weight: 500
        line-height: 20px
        margin-top: 12px
        color: $n11
        +dark
            color: $n11
    &__date
        color: $n4
        +dark
            color: $n16    


@media screen and (max-width: 768px) 
   
    .removalDecisionSection
        display: flex
        justify-content: center
        height: 100vh  
    .invitationContainer
        justify-content: space-between    

    .invitationDecisionDetails 
        height: auto
        margin: 16px
        border-radius: 8px

    .fileSection
        height: 224px

    .invitationDecisionDetails
        height:  628px
        width: 360px

    .removalDecisionSection
        overflow-y: scroll
        flex: 1 1
                      
    .fileDetails__name 
        max-width: 60px
    
  