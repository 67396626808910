@import "../../../../../styles/helper";

.bid-sheet {
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  max-height: 415px;
  display: flex;
  flex-direction: column;
  .infinite-scroll-component{
    height: unset!important;
    overflow: unset!important;
  }
  

  @include dark {
    background: $dark;
  }

  .bid-sheet_heading_container {
    display: flex;
    padding: 10px 15px 15px;


    .button {
      border-radius: 4px;
      height: 40px;
      min-height: 40px;
      font-weight: 500;
      font-size: 14px;
      padding-inline: 10px;
    }

    .bid-sheet_heading_text{
      padding-right: 20px;
      border-right: 1px solid $n4;
    }
  }

  .bid-sheet_heading_container-pool{
    justify-content: space-between;
    display: flex;
    flex: 1;
  }

  &__heading {
    color: $s5 !important;
    font-size: 18px;
    font-weight: 600;
    background-color: $white;

    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 4px;
      background-color: $s5;
      border-radius: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }

    @include dark {
      background-color: $dark;
    }
  }

  &__para{
    color: $n4;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .bid-price-btn-container{
    display: flex;
    flex: 1;
    gap: 6px;
    align-self: center;
    justify-content: right;
    text-align: right;

    .btn-auto{
      width: auto;
    }
  }

  .bid-price-container{
    padding-left: 20px;
    &__heading2{
      color: $s5;
      font-size: 18px;
      font-weight: 600;
    }
    .dropdownHead{
        height: 24px;
        width: 120px;
        padding: 0 36px 0 8px;
        background: #F1F2F4;
        border-radius: 4px;
        box-shadow: none;
        font-size: 12px;
        line-height: 24px;
        @include dark{
          background: $n2;
        }
    }

    .dropdownArrow{
        right: 0
        svg{
            fill: $n4;
        }
    }
    .dropdownBody{
        border-radius: 4px
    }

    .dropdownOption{
        padding: 4px 8px;
        font-size: 12px;
    }
  }

  .bid-sheet-footer{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    .footer-heading{
      color: $n4;
    }
    .footer-para{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .button {
        border-radius: 4px;
        height: 40px;
        min-height: 40px;
        font-weight: 500;
        font-size: 14px;
        padding-inline: 10px;
      }
  }

  .react-table-responsive-container {
    display: flex;
  }

  .react-table-responsive-wrapper {
    padding: 0 8px 0 12px;
    overflow: unset;

    tbody tr,
    tbody tr:last-child {
      background: transparent !important;
      border-bottom: 1px solid #d9e0f2;
    }

    thead tr {
      background: white !important;
      @include dark {
        background: $dark !important;
      }
    }
  }

  .text-elipsis {
    max-width: unset;
  }

  thead tr th {
    color: $n3;
    @include dark{
      color: $n5;
    }
  }

  button {
    font-size: 12px;
    border-radius: 50px;
  }

  &__view-details {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #f4f5f6;
    padding: 4px 12px;
    @include dark {
      background: transparent;
      border: 1px solid #f4f5f6;
    }
  }

  &__button {
    display: flex;
    gap: 10px;
  }

  &__payment-history {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #49b876;
    background: transparent;
    color: #49b876;
    padding: 4px 12px;
  }

  &__ellipsis {
    .ellipsismenu__menu-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #6c707a;
      @include dark {
        color: $white;
      }
    }
  }

  @include dark {
    .react-table-responsive-wrapper {
      tr {
        border-bottom: 1px solid #23262f;
      }

      tr:last-child {
        border-bottom: none;
      }
    }
  }

  .react-table-responsive__head tr th {
    overflow-y: scroll;
    text-overflow: unset;
    max-width: unset;
  }

  &__modal-details {
    border: 1px solid #e6e8ec;
    border-radius: 8px;
    @include dark {
      border-color: #23262f;
      background: $dark;
    }
  }
}
.bid-sheet-pool{
  max-height: 535px;
  .greenValue{
    color: $n13;
  }
  .yellowValue{
    margin-left: 10px;
    color: rgba(226, 122, 19, 1);
    border: 1px solid rgba(226, 122, 19, 1);
    border-radius: 32px;
    padding: 4px 12px;
  }
  .soldValue{
    font-size: 12px;
    color: $p1;
    background-color: $n9;
    border-radius: 32px;
    padding: 2px 12px;
    @include dark{
      background-color: $n3;
    }
  }
  button{
    width: 120px;
  }
}

.bid-note{
  margin-top: 10px;
  border: 1px solid rgba(226, 122, 19, 1);
  padding: 16px;
  background-color: rgba(226, 122, 19, 0.06);
  display: flex;
  gap: 17px;
  .outer-para{
    display: inline;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .inner-para{
    display: inline;
    color: $n4;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .icon{
    margin-top: -6px;
    color: rgba(226, 122, 19, 1);
    font-size: 20px;
  }
}


.table-cell1{
  table{
    border-collapse: collapse;
  }

  th{
    background-color: $n6;
    opacity: 0.7;
    @include dark{
      background-color: rgba(255, 255, 255, 0.06);
    }
    
  }

  td, th{
      padding: 12px;
      border: 1px solid $n6;
      @include dark{
        border: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
  
  input{
    width: 100%;
    border: transparent;
    background-color: transparent;
    outline: none;
    resize: none;
    overflow: hidden;
    margin: 0!important;
    @include dark {
      color: $white;
    }
  }
  
}

#modal {
  .bid-sheet__modal {
    max-width: 1080px;
    padding: 16px;

    .bid-sheet-container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d7dff5;
      padding-bottom: 12px;
    }

    .button {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #49b876;
      background: transparent;
      color: #49b876;
      padding: 12px 20px;
      border-radius: 8px;

      &:hover {
        background-color: unset;
      }

      @include dark {
        color: white;
      }
    }

    h2 {
      @include dark {
        color: white;
      }
    }

    h3 {
      font-size: 14px;
      @include dark {
        color: white;
      }
    }

    p {
      font-size: 12px;
      color: #747e99;
    }

    .bid-sheet__detail-item {
      padding: 24px 0 0;
      flex: 25% 0 0;
    }

    .bid-sheet__detail-items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
.KYCReviewModal{
  max-width: 768px !important;
  padding: 24px;
  border-radius: 8px;
  @include dark{
    background: $n21 !important   
  }
}

.bid-sheet-pool {
  .table-visible-scroll {
    direction: initial;
  }
}
