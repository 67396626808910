@import "../../../../../../styles/helpers"


.invitationSection
    +dark
        background: #18191D
        color: white
.invitationContainer
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0 auto
    
.logoContainer
    width: 187px
    
.invitationDetails
    background: #282A2E
    width: 576px
    height:  264px
    padding: 24px
    margin-top: 32px
    border-radius: 8px
    .invitation
        font-size: 16px
        font-weight: 600
        line-height: 24px
        padding-bottom: 10px
        margin-bottom: 10px
        border-bottom: 1px solid #36383D
.messageContainer
        font-size: 14px
        font-weight: 600
        line-height: 24px
        color: #E6EAF5
        display: flex
        flex-direction: column
        gap: 4px
       
        height: 68px    
.greeting
    font-size: 14px
    font-weight: 600
    line-height: 24px
    color: #E6EAF5
    padding-top: 10px
.message
    font-size: 12px
    font-weight: 400
    line-height: 20px
    color: #BCC3D6
    border-bottom: 1px solid #36383D
    padding-bottom: 20px
    margin-bottom: 10px       
        
.buttonSection
    display: flex
    justify-content: flex-end
    align-items: center
    margin-top: 60px   
    gap: 8px 

.deny
    width: 108px !important
    height: 52px !important
    border-radius: 8px !important
    height: 52px
    font-weight: 500 !important
    background: #ebeef5 !important
    color: $n2 !important
    font-size: 14px !important

    +dark
        color: #fff !important
        background: #FFFFFF0F !important       

.approve
    padding: 16px 24px
    border-radius: 8px !important
    height: 52px
    width: 112px !important
    font-size: 14px !important
    font-weight: 500 !important

.invitationFooter    
    margin-top: 32px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 16px

.invitaionSocial
    display: flex
    gap: 8px
    color: #5C5C5C    
.footerDisclaimer
    color: #5C5C5C

    