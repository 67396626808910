@import styles/helpers

.confirmationModal
  +poppins


.confirmationImage
  width: 96px
  height: 96px

.confirmationHeader  
  height: 160px
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  background: #EDEFF5
  border-bottom: 1px solid $n14
  border-top-left-radius: 20px
  border-top-right-radius: 20px
  +dark
    background: $n12
    border-bottom: 1px solid #3A3F4D
.confirmationTitle
  font-weight: 600
  font-size: 16px
  margin-top: 8px
  
.modalDes  
  font-weight: 400
  font-size: 14px
  padding: 24px
  line-height: 20px
  border-bottom: 1px solid $n14
  color: #6C707A
  +dark
    border-bottom: 1px solid #3A3F4D
    color: #C9CDD6
.modalFooter
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 16px 24px

.buttonCancel
  font-size: 14px
  display: flex
  padding: 16px 28px
  width: 112px
  height: 52px
  background: rgba(54, 56, 61, 0.06)
  border-radius: 8px 
  color: #51545C
  margin-right: 8px
  align-items: center
  text-align: center
  +dark
   background: rgba(255, 255, 255, 0.06)
   color: #D8DBE6


.buttonCancelYes 
  font-size: 14px
  display: flex  
  padding: 16px 28px
  width: 112px
  height: 52px
  background: #F54545
  border-radius: 8px 
  color: $white
  align-items: center
  text-align: center
 
