@import styles/helpers

.form
    background: black
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    width: 100%
    padding: 0 9px
.brand_logo
    height: 32px
    position: absolute
    top: 30px
    left: 30px

.nav
    display: flex
    justify-content: center
    margin-bottom: 32px
    .link
        display: flex
        padding: 6px 12px
        border-radius: 14px
        background: none
        +button-2
        color: $n4
        transition: all .2s
        &:hover
            color: $n3
            +dark
                color: $n8
        &.active
            background: $n3
            color: $n8
            +dark
                background: $n3
                color: $n8
        &:not(:last-child)
            margin-right: 24px

.container
    margin-bottom: 8px
.edit
    padding: 2px
    text-align: left
    margin-bottom: 16px
    flex-direction: column
    color: $n5
    width: 100%
    font-weight: 500
    span
        color: $white

.back
    cursor: pointer
    font-size: 16px
    font-weight: 500
    line-height: 24px
    display: flex
    gap: 4px
    align-items: center
    color: #AFB6C0
    margin-bottom: 8px
.backIcon
    font-size: 24px

.resend
    margin-bottom: 16px
    display: flex
    justify-content: center
    color: $white
    padding: 12px
    align-items: center
.resendText
    font-size: 16px
    font-weight: 500
    line-height: 24px
    color: #AFB6C0
    margin-right: 8px
.editBtn
    cursor: pointer
    margin-left: 4px
    text-decoration: underline
    width: 36px
    font-size: 16px
    font-weight: 500
    line-height: 24px
    color: #478FFB

.line
    display: flex
    justify-content: center
    gap: 10px
    .field
        &:first-child
            flex-shrink: 0
            width: 128px
            margin-right: 8px
        &:nth-child(2)
            flex-grow: 1

.foot
    display: flex
    justify-content: space-between
    margin-top: 16px
    +caption-bold-2
    .link
        color: $p1
        transition: color .2s
        &:hover
            color: darken($p1, 10)

.scan
    +caption-bold-2
    color: $n4
    transition: color .2s
    &:hover
        color: $n2

.buttons
    width: 100%
    border-radius: 4px !important
    +dm
        width: 480px

.otpInput
    color: $white
    font-weight: 700
    text-align: center
    line-height: 60
    height: 84px
    width: 72px
    border: 1px solid #FFFFFF0F
    background: #FFFFFF10
    border-radius: 4px
    font-size: 48px
    &:focus
        border: 1px solid $n74
    &:focus::placeholder
        opacity: 0

    //&:not(:placeholder-shown)::placeholder
    //    opacity: 0

.verify_button
    display: flex
    justify-content: center
    margin-top: 16px
    width: 100%
    transition: all 240ms ease-in-out
    &:hover
      color: #fff
      filter: brightness(0.8)

.otp
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    max-width: 432px

.otp_detail
    font-weight: 700
    color: $white
    text-align: left
    width: 100%
    font-size: 30px
    line-height: 40px
    margin-bottom: 4px
    &__text
        font-size: 16px
        font-weight: 500
        line-height: 24px
        color: #AFB6C0
        margin: 8px 0px 24px 0px

.signInPhoneNumber
    font-size: 16px
    font-weight: 600
    line-height: 24px
    color: #D1D5DB

.back_button
    border-radius: 0 !important
    background: grey
    width: 100%
    &:hover
        background: rgba(245, 175, 69, 0.06)

.sendCodeTimer
    width: 32px
    height: 32px
    font-size: 16px
    font-weight: 500
    line-height: 24px
    color: $n53
    border-radius: 4px
    border: 1px solid $n3
    background-color: $n1
    display: inline-flex
    align-items: center
    justify-content: center
.sendCodeTimerDivide
    color: $n53
    margin-inline: 4px
    font-size: 16px
    font-weight: 500
.optErrorIcon
    color: #F26969
    font-size: 24px
.wrongOtpError
    font-size: 16px
    font-weight: 500
    line-height: 24px
    color: #AFB6C0
    display: flex
    gap: 4px
    align-items: flex-start
    animation: fadeIn 0.3s ease-out
    margin-bottom: 8px

.inputWrongOtp
    border: 1px solid #F26969 !important
    background: #F269691F
    animation: shake 0.3s ease-out
@keyframes shake
    0%, 100%
        transform: translateX(0)

    20%, 60%
        transform: translateX(-4px)

    40%, 80%
        transform: translateX(4px)

@keyframes fadeIn
    0%
        opacity: 0
        transform: translateY(-10px)

    100%
        opacity: 1
        transform: translateY(0)
        
// only for MLC css 
.mlc_form
    @extend .form

@media ((max-device-width: 480px))
    .mlc_form
        padding: 24px 16px 0 16px
