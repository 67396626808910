@import styles/helpers

.songs
    padding: 16px
    height: 100%
    border: 1px solid $n6
    background: $n8
    border-radius: 8px
    transition: all 0.3s ease-out
    +dark
        background: $dark
        border-color: $n2

.table_wrapper
    +scroll
    min-height: 180px
    max-height: 330px
    overflow-y: auto

.table
    display: table
    width: 100%
    position: relative
    &::-webkit-scrollbar
        width: 5px

    .row
        display: table-row
        width: 100%
        height: 100%
        cursor: pointer
        &:first-child
            position: sticky
            top: 0px
            background: #ffffff
            +dark
                background: $dark
        &:not(:first-child)
            &:hover
                background: $n6
                +dark
                    background: rgba(255, 255, 255, 0.03)


.col
    padding: 10px 8px
    min-height: 46px
    display: table-cell
    vertical-align: middle
    border-bottom: 1px solid $n6
    +dark
        border-bottom: 1px solid $n2
    &:last-child
        min-width: 140px
        .indicate
            width: 8px
            height: 8px
            border-radius: 50%

.block
    margin-left: 8px

.flex
    display: flex
    justify-content: flex-start
    align-items: center
    img
        width: 40px
        height: 40px
        border-radius: 50%

.header
    display: flex
    align-items: center
    padding-bottom: 16px
    .rectangle
        width: 24px
        height: 4px
        /* Color/Accent/Dark */
        background: $n10
        border-radius: 16px
        margin-right: 8px
    p
        font-weight: 500
        font-size: 16px

.sorting
    font-weight: 500
    font-size: 12px
    line-height: 20px
    color: #777E91

.name
    font-weight: 500
    font-size: 14px
    line-height: 20px

.artist
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: #717684
    text-transform: capiitalize

.variation
    font-weight: 500
    font-size: 12px
    line-height: 16px
    &.positive
        color: $p4
    &.negative
        color: $p3

.emptyState
    width: 100%
    min-height: 180px
    display: flex
    flex-direction: column
    gap: 8px
    justify-content: center
    align-items: center
    font-weight: 600
    font-size: 14px
    color: $n5

.assetLetter
    height: 40px
    width: 40px
    border-radius: 50%
    background: #EDF1FC
    padding: 4px 12px
    color: $dark
    display: flex
    justify-content: center
    align-items: center
    font-size: 20px
    +dark
        background: #282A2E
        color: $white
