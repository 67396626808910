
@import  "../../styles/helper.scss";

.error-page-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100dvh;
    margin: 0 20px;
    gap: 24px;
    &__image{
        max-height: 216px;

    }
    &__details{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        &__desc{
            font-weight: 600;
            line-height: 32px;
            font-size: 24px;
        }
        &__subDesc{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $n37;
            @include dark{
                color: $white;
            }
        }
        &__btn{
            border-radius: 4px;
            padding: 8px 16px;
            background: #0000001F;
            cursor: pointer;
            @include dark{
                color: $white;
                background: $n4;

            }
        }
    }

}