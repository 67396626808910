@import "../../styles/helper.scss";

.social-auth-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $dark;
  font-family: Arial, sans-serif;

  p {
    margin-top: 16px;
    font-size: 24px;
    color: $white;
  }
}
