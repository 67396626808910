@import "../../styles/helper.scss";

.pagination-exchange {
  border-top: 1px solid $n6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #747e99;
  padding: 12px 12px 20px;
  font-weight: 500;
  background-color: $white;

  .row-per-page {
    display: flex;
    align-items: center;
  }

  .pageNumber {
    background: $blue6per;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
    border-radius: 4px;
  }

  .prev-next-page {
    display: flex;
    align-items: center;

    .prev-page, .next-page {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $p1;
      }

      i {
        font-size: 16px;
      }
    }
  }

  .pagination-drop-down {
    background-color: $blue6per;
    box-shadow: none;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    width: 52px;
    padding: 0 12px;
    border-radius: 4px;
  }

  .pagination-drop-arrow {
    right: 0px;

    svg {
      fill: #747e99;
    }
  }

  @include dark {
    background-color: $dark;
    border-top-color: $n3;
  }
}
