@import "../../../../styles/helper";

.signup-container {
  width: 100%;
  height: 100vh;
}

.signup-header {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-weight: 600;
    font-size: var(--font-size-7);
    line-height: 40px;
    color: var(--color-text-100-light);
  }

  &__text {
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: 21px;
    color: var(--color-text-60-light);
  }
}

.signup-inputs {
  // display: flex;
  // flex-direction: column;
  // gap: calc(var(--base-margin) * 0.5);

  .input__fields {
    color: black;
  }

  .input__text-fields--error {

    @include dark {
      .input__fields {
        color: $white;
      }
    }
  }

  .css-1s2u09g-control {
    cursor: pointer;
    border-style: unset;
  }
  .css-1pahdxg-control {
    cursor: pointer;
    border-style: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
    background: transparent !important;
    &:hover {
      border-style: unset !important;
    }
  }
  .country-label {
    width: 180px;
  }
  .css-319lph-ValueContainer {
    padding: 2px 0px !important;
  }
  .css-tlfecz-indicatorContainer {
    padding-left: 4px;
  }
}

.signup-row-1,
.signup-row-2 {
  width: 50%;
  margin: auto;
}

.signup-row-2 .emailInput{
  color: black !important;
  .input__text-fields{
    @include dark{
      background: $n19;
      border: 1px solid $n3;
    }
  }
  .input__fields{
    @include dark{
      color: $white;
    }
  }
  .input__text-fields--error{
    @include dark{
      border-color: var(--color-error);
      background-color: hsla(0, 100%, 50%, 0.06);
    }
  }
  
}
.signup-row-1 {
  display: flex;
  gap: 16px;
}

.signup-checkbox_container {
  height: 16px;
  display: flex;
  align-items: center;
  &__checkbox {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
  &__checkbox-label {
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: 21px;
    color: var(--color-text-60-light);
    cursor: pointer;
    padding-left: 16px;
    &::selection {
      color: unset;
      background-color: unset;
    }
  }
}

.signup-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  .signup-btn-label {
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: 20px;
    color: var(--color-bg-100-light);
  }
}

.have-an-account {
  width: 100%;
  font-weight: 500;
  font-size: var(--font-size-3);
  line-height: 20px;
  color: var(--color-text-60-light);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .back-to-login {
    cursor: pointer;
    color: var(--color-primary-light);
    font-weight: 600;
  }
}

@media only screen and (max-width: 500px) {
  .signup-row-1 {
    flex-direction: column;
    gap: calc(var(--base-margin) * 0.5);
  }
}

// AuthLayout CSS
.AuthLayout {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AuthLayout-Card {
  border-radius: 8px;
  padding: 0px 24px 24px;
  width: 100% !important;
  min-height: 100px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media only screen and (max-width: 500px) {
  .LoginHeader {
    &__btnContainer {
      display: none;
    }
  }
  .AuthLayout {
    padding: 12px;
  }
  .AuthLayout-Card {
    box-shadow: unset;
    width: 100% !important;
  }
}
.SECOND-NAME,
.FIRST-NAME{
  width: 100%;
}
.error-msg-1,
.error-msg-2,
.error-msg-3{
  text-align: start;
  color: red;
}