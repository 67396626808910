@import "../../../../styles/helper.scss";

.notificationSection {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 12px 16px;
  border-bottom: 1px solid $n54;
  cursor: pointer;
  @include dark {
    border-bottom: 1px solid $n12;
  }
}

.orderNotification {
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;
    gap: 12px;
    position: relative;

    &.with-no-icon {
      padding-left: 50px;
    }

    &__icon {
      width: 40px;
      height: 40px;
      gap: 8px;
      border-radius: 4px;
      background: $n57;
      @include dark {
        background: $n12;
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }

      .orderCreation {
        color: $n13;
      }

      .cancelledOrder {
        color: $n24;
      }
    }

    &__readMark {
      height: 8px;
      width: 8px;
      border-radius: 24px;
      background: $n62;
      position: absolute;
      left: 38px;
      top: -2px;
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $n58;
      @include dark {
        color: $white;
      }


    }

    &__Subtitle {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: $n59;
      margin-top: 4px;
      @include dark {
        color: $n14;
      }
    }

    &__userDetail {
      display: flex;
      gap: 4px;
      margin-top: 12px;
      align-items: center;

      &__userImg {
        height: 24px;
        width: 24px;
        border-radius: 40px;
        object-fit: cover;

      }

      &__userName {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $n45;
        @include dark {
          color: $n15;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    &__amount {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $n58;
      @include dark {
        color: $n14;
      }
    }

    &__orderDetails {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 4px;

      &__qty {
        display: flex;
        gap: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        div {
          color: $n15;
          @include dark {
            color: $n46;
          }
        }

        span {
          color: $n45;
          @include dark {
            color: $n15;
          }
        }
      }

      &__bullet {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: $n46;
      }

      &__price {
        display: flex;
        gap: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        div {
          color: $n15;
          @include dark {
            color: $n46;
          }
        }

        span {
          color: $n45;
          @include dark {
            color: $n15;
          }
        }
      }

      &__orderType {
        margin-top: 12px;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        gap: 6px;
        text-transform: capitalize;

        div {
          padding: 4px 12px;
          background-color: $n57;
          color: $n60;
          border-radius: 4px;
          white-space: nowrap;
          @include dark {
            background: $n61;
            color: $n11;
          }
        }

        span {
          padding: 4px 12px;
          border-radius: 4px;
          background-color: $n57;
          @include dark {
            background: $n61;
          }
        }

        &__BUY {
          color: $n13;
        }

        &__SELL {
          color: $n24;
        }
      }

    }

    &__time {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: $n11;
    }
  }

}

.notificationSection {
  .orderNotification-description {
    font-size: 14px;
    font-weight: 500;
    padding-inline: 50px 10px;
    margin-bottom: 15px;
    color: $n4;

    @include dark {
      color: $n9;
    }
  }

  .request-status {
    color: $n4;
    font-size: 14px;
    text-transform: capitalize;

    &:before {
      content: '';
      display: inline-block;
      padding: 4px;
      background-color: $n13;
      margin-inline: 5px;
      border-radius: 50%;
    }

    &.red:before {
      background-color: $n77;
    }

    @include dark {
      color: $n16;
    }
  }
}
