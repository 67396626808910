.lockInCount {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 0;
  gap: 4px;

  .countCircle {
    width: 25px !important;

    .CircularProgressbar-path {
      stroke: #58BD7D;
      stroke-linecap: butt;
    }

    .timeColor {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

  }

}