@import "../../../../styles/helper";

.withdraw-confirmation-panel {
  margin: 10px 0;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  border-radius: 8px;
  background-color: #f4f5f6;

  @include dark {
    background-color: #36383D;
  }

  .panel-details {
    display: block;
    flex: 1;

    .details-heading {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #23262f;

      @include dark {
        color: #999999;
      }
    }

    .details-body {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #23262f;

      @include dark {
        color: #F5F5F5;
      }
    }
  }
}
.confirmation-deposit{
  display: grid;
  place-items: center;
  text-align: center;
}