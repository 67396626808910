@import "../../../styles/helper";

.create-auction {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .input__text-fields {
    background: transparent;
    border: 2px solid $n6;
    &:focus-within {
      border-color: $dark;
      transition: 0.2s ease-in all;
    }
  }

  .input__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #747e99 !important;
  
  }

  textarea {
    background: transparent;
    border: 2px solid $n6;
    height: 104px;
    &:focus {
      border: 2px solid $dark;
      transition: 0.2s ease-in all;
    }
  }

  .TextArea_label__ewxUT {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #747e99 !important;
  }

  @include dark {
    .input__text-fields,
    textarea {
      border-color: $n18;
      background-color: transparent;
      &:focus-within {
        border-color: $white;
      }
    }
    
    .input__fields {
      color: $white;
      caret-color: $white;
    }
    .TextArea_label__ewxUT {
      color: $white !important;
    }

    .input__label {
      color: $white !important;
      font-weight: 500;
      &::first-letter {
        text-transform: capitalize !important;
      }
    }
  }
}

.auction-wrapper {
  position: relative;
  padding: 24px 24px 50px 24px;
  background: $white;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  overflow-y: auto;
}

.auction-commision-wrapper{
  display: flex;
  padding: 12px 16px;
  gap: 16px;
  border-radius: 4px;  
  border: 1px solid rgba(230, 232, 236, 1);   

  .amount-commision{
    flex: 1 1;
    padding: 8px;
    &:nth-child(1){
      border-right: 1px solid rgba(230, 232, 236, 1);
      @include dark {
        border-right: 1px solid $white;
      }
    }
    .commision-heading{
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(93, 101, 122, 1);
    }

    .commision-value{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
    }
  }
}

