@import "../../styles/helper.scss";

.maintenance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  border-radius: 12px;
  height: 100%;
  justify-content: center;

  .icon-container {
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 8px;
    color: $n21;
    font-size: var(--font-size-5);
    font-weight: 600;
    line-height: 28px;
    @include dark {
      color: $n64;
    }
  }

  p {
    color: $n30;
    font-size: var(--font-size-4);
    font-weight: 400;
    line-height: 24px;
    @include dark {
      color: $n9;
    }
  }

  a {
    color: #3b82f6;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}
