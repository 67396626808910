@import "../../../../../../styles/helper.scss";


.removeCoownerSection {
  .removeCoowner-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .authorised_dataroom-file-container {
    display: grid;
    vertical-align: middle;
    margin-top: 8px;
    height: 268px;
    border-radius: 8px;
    gap: 8px;
    background: $n39;
    border: 1px dashed $n15;
    cursor: pointer;
    width: 432px;
    @include dark {
      background: $n19;
      border: 1px dashed $n34;
    }
  }

  .authorised_dataroom {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    border-bottom: 1px solid $n6;
    @include dark {
      background: $n19;
      border-bottom: 1px solid $n34;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      @include dark {
        color: $n14;
      }
    }

    &__desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      @include dark {
        color: $n16;
      }
    }
  }

  .authorised-form-data-room {
    display: flex;
    flex-flow: column nowrap;
    padding: 16px 24px 16px 24px;
    @include dark {
      background: $n19;
    }

    .authorised-dataroom-files-header {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;

      @include dark {
        color: $n14;
      }
    }
  }

  .authorised-dataroom-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    border-bottom: 1px solid $n12;

    p {
      color: $n15;
    }
  }

  .authorised_dataroom-content {
    position: relative;
    width: 432px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      font-size: 64px;
      color: $n28;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      @include dark {
        color: $n14;
      }
    }

    small {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: $n41;
      margin-top: -4px;
      @include dark {
        color: $n15;
      }
    }

    .image_browse {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
      opacity: 0;
      cursor: pointer;
    }
  }

  .file-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .authorised-data-room-files {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    gap: 14px;
    overflow-y: auto;

    .authorised-file-details {
      display: flex;
      gap: 12px;
      align-items: center;
      height: auto;
    }

    i {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $n42;
    }

    small {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: $n22;
    }
  }

  .files-description-container {
    display: flex;
    gap: 4px;
    margin-right: 10px;
  }

  .file-names {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $n14;
  }

  .fileName {
    color: $n22 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px !important;
    @include dark {
      color: $n14 !important;
    }
  }

  .fileSize {
    color: $n15;
    width: 70px;
  }

  .authorised-files-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &__upper {
      display: flex;

      &__uploading {
        width: 350px;
        display: flex;
        justify-content: space-between;
      }
    }

    .file-loading-status {
      font-size: 12px;
      font-weight: 500;
      color: $n41;
      text-transform: capitalize;
      padding-left: 4px;
    }
  }

  .loading-placeholder {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 8px;
    background-color: lightgrey;
    width: 350px;

    .loading-percentage {
      position: absolute;
      left: 0;
      top: 0;
      height: 4px;
      z-index: 10;
      transition: all 0.25s ease-in;
      background: $n40;
    }
  }

  .authorised-delete-file {
    color: $n15 !important;
    cursor: pointer;

    &__bin {
      color: $n24 !important;
    }
  }

  .fileLogo {
    background: $n6;
    padding: 8px;
    height: 34px;
    width: 34px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include dark {
      background: $n12;
    }

    img {
      width: 22px;
    }
  }

  .authorised-dataroom-document {
    height: 132px;

    &__nav {
      border-bottom: 1px solid $n6;
      @include dark {
        border-bottom: 1px solid $n12;
      }
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }

    &__header {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      @include dark {
        color: $n14;
      }
    }

    &__fileCount {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $n15;
      @include dark {
        color: $n15 !important;
      }
    }
  }

  .authorised-dataroom-files {
    height: 100px;
    overflow-y: auto;
  }

  .browse {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    color: $n28;
  }

  .viewButton {
    display: flex;
    gap: 8px;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 32px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    border: 1px solid $n28;
    font-size: 14px;
    font-weight: 500;
    color: $n28;
  }

  .removeCoownerfile-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .removeCoownerfile-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .no-data-found {
    background-color: transparent;
    padding: 5px;
    gap: 5px;
    font-size: 16px;

    img {
      width: 60px;
    }
  }

}
