@import ../../../../styles/helpers

.bidSheetModal_buttonSection
    height: 80px
    border-top: 1px solid $n55
    display: flex
    gap: 8px
    justify-content: flex-end
    padding: 16px 24px   
    +dark
        border-top: 1px solid $n34
    &__cancel
        background: $n32 !important
        color: $n37 !important
        font-weight: 500 !important
        border-radius: 4px !important
        width: 120px
        +dark
            background: $n3 !important
            color: $white !important
    &__submit
        background: $p1 !important
        font-weight: 500 !important
        border-radius: 4px !important
        width: 120px
