@import "../../styles/helper.scss";

.date-range-picker-overlay {
  z-index: 3;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.date-range-picker {
  display: flex;
  gap: calc(var(--base-margin) * 0.25);
  width: fit-content;
  padding: calc(var(--base-padding) * 0.5);
  border-radius: calc(var(--base-border-radius) * 0.75);
  background-color: var(--color-bg-100-light);
  box-shadow: 0px 4px 60px rgb(0 0 0 / 10%);
  position: absolute;
  top: 116px;
  z-index: 4;
  min-width: 450px;
}

.overlay-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--base-margin) * 0.25);
  padding-bottom: calc(var(--base-padding));
  padding-top: calc(var(--base-padding) * 0.75);
}

.rdrCalendarWrapper {
  background-color: var(--color-transparent);
}

.rdrInputRanges {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 130px;
  border: none;
  background: unset;
}

.rdrWeekDay {
  color: var(--color-golden);
}

.rdrMonths {
  @include dark {
    background: black;
  }
}

.rdrDay .rdrDayNumber span {
  color: var(--color-text-100-dark);
  color: #4574f5;
  @include dark {
    color: white;
  }
}

.rdrDayDisabled {
  background-color: var(--color-transparent);
}

.rdrNextPrevButton {
  background: var(--color-transparent);
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
}

.DefinedRange--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: 400 !important;
}
@media (max-width: 500px) {
  .rdrMonthAndYearWrapper,
  .rdrDays,
  .rdrWeekDays,
  .rdrMonths {
    width: 200px;
  }
  .overlay-container {
    width: 140px;
  }
  .rdrDefinedRangesWrapper {
    width: 124px !important;
  }
  .date-range-picker {
    width: 340px !important;
    min-width: 340px !important;
  }
  .rdrNextButton {
    margin-right: 0px !important;
  }
  .button__filled {
    min-height: 34px;
    height: 34px !important;
  }
  .overlay-container {
    padding-bottom: 4px;
  }
}
.darkMode {
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 15px;
    background-color: #36383d;
    color: #bcc3d6;
  }
  .rdrDayDisabled {
    background-color: black;
  }

  .rdrPprevButton {
    border-color: transparent transparent transparent rgb(234 241 248);
    transform: translate(-3px, 0px);
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 15px;
    background-color: #36383d;
    color: #bcc3d6;
  }
  .rdrCalendarWrapper {
    font-size: 12px;
    background-color: black;
  }
  .rdrMonthAndYearPickers {
    font-weight: 600;
    color: white;
  }
  .rdrWeekDay {
    color: #bcc3d6;
  }
  .rdrDayNumber {
    color: white;
  }
  .rdrStaticRange {
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: #000000;
    color: white;
    &:hover {
      background-color: black;
      color: blue;
    }
  }
  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: #000000;
  }
}
.apply-button {
  padding: 16px 24px !important;
  border-radius: 5px !important;
}
