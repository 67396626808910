@import styles/helpers

.DvpNameModal
    +dark
        background: $n21
    svg
        margin: 20px 0
    

.iframeModal
    background: $white !important
    max-width: 85%
    height: 95%
    padding-top: 50px
    svg
        fill: $n4 !important

.emailModal
    max-width: 768px!important
    height: 480px

.DvpNameModal__top
    padding: 24px 56px 16px 24px
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 4px
    border-bottom: 1px solid $n14
    +dark
        border-bottom: 1px solid $n35
    &__heading   
        font-size: 20px
        font-weight: 600
        line-height: 28px
    &__subHeading   
        font-size: 14px
        font-weight: 400
        line-height: 20px
        color: $n63
        +dark
            color: $n68

.DvpNameModal__middle
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 16px 24px
    .currLimit
        height: 72px
        width: 495px
        background: #FFFFFF08
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        .limitHeading
            font-size: 14px
            font-weight: 400
            line-height: 20px
            text-align: left
            color: $n9
        .limitValue
            font-size: 20px
            font-weight: 600
            line-height: 32px
            text-align: center


    &__label     
        font-size: 14px
        font-weight: 500
        line-height: 24px
        color: $n63
        +dark
            color: $n9
    &__input    
        width: 495px
        height: 48px
        border-radius: 4px
        position: relative
        &__field
            width: 100%
            height: 100% 
            background: #FFFFFF08
            padding-left: 8px
            font-size: 16px
            font-weight: 500
            line-height: 24px
            transition: border-color .2s
            border:1px solid $n6
            border-radius: 2px
            +dark
                color: $white
                border: 1px solid $n35 
            &__green
                color:$n73
            &__red
                color: $n29
    
        i
            position: absolute
            top: 50%
            right: 10px
            transform: translateY(-50%)
            font-size: 24px
        
    &__error
        display: flex
        align-items: center
        justify-content: flex-start
        color: $n77   
        font-size: 12px
        font-weight: 500
        line-height: 16px
        gap: 4px
        margin-top: 4px
        &__icon
            font-size: 16px
    
    // .dataRoom
    //     .data-room-file-container
    //         background-color: red !important

.DvpNameModaBtnContainer
    height: 80px
    display: flex
    justify-content: flex-end
    align-items: center
    padding-right: 24px
    gap: 8px
    border-top: 1px solid $n14
    +dark
        border-top: 1px solid $n35
    &__cancel
        padding: 12px 20px
        font-size: 16px
        font-weight: 500 !important
        line-height: 24px
        border-radius: 4px !important
        background: rgba(54, 56, 61, 0.06) !important
        color: $n23 !important
        +dark
            color: $n64 !important
            background: $n71 !important
    &__save  
        min-width: 114px !important
        height: 48px
        padding: 12px 20px
        background: $n74
        color: $n64
        font-size: 16px
        font-weight: 500 !important
        line-height: 24px
        border-radius: 4px !important
      
.dvpSuccessModal
    padding: 0 20px
    height: 300px
    display: flex
    flex-direction: column
    justify-content: center
    
    .heading
        width: 444px
        margin-inline: auto
        font-size: 20px
        font-weight: 600
        line-height: 28px
        text-align: center
        margin-bottom: 5px
    .para
        width: 444px
        margin-inline: auto
        font-size: 14px
        font-weight: 400
        line-height: 20px
        text-align: center
        color: $n9

