.card-details {
  &__wrapper {
    height: 100%;
    min-height: 300px;
    padding: 0px 24px 0px 24px;
    position: relative;
  }
  &__loader--wrapper{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__footer{
    margin-top: 12px;
  }
  &__footer--loading{
    position: absolute;
    bottom: 0;
    left: 0;  
    right: 0;
  }
}
