@import "../../styles/helper.scss";

.NotificationDrawerTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 64px;
  border-bottom: 1px solid $n54;
    @include dark{
      border-bottom: 1px solid $n12;
    }

}

.NotificationDrawer {
  height: 100vh;
  width: 600px;
  background: $white;
  position: fixed;
  top: 0px;
  bottom: 0px;
  z-index: 999999;
  animation: myfirst 500ms linear ;
  display: flex;
  flex-direction: column; 
  border-left: 1px solid  $n54;
  @include dark{
    background-color: $n19;
    border-left: 1px solid $n12;
  }
  @media (max-width: 425px) {
      max-width: 425px;
  }
  &--open {
    right: 0px;
  }
  &__heading{
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    
  }

  &__markasreadContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    &__loader{
      height: 20px;
      width: 20px;
    }
    &__text{
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: $n62;
    }
    i{
      color: $n62;
      font-size: 20px;
    }
  }

  &__body {
    max-width: 600px;
    background: $white;
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    background: $white;
    @include dark {
        background: $n19;
    }
  }

  &__logo {
    background: $white;
    @include dark {
        background: $n19;
    }
  }
}
@keyframes myfirst {
  0%   { right:-100%; }
  100%  { right: 0; }
}