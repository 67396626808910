@import "styles/helpers";

@mixin t {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: "767px") {
    @content;
  }
}

.chart_container {
  height: 100%;
  @include t {
    width: 100%;
    height: 100%;
  }
}

.chart_inner {
  height: 100%;
  @include t {
    height: 100%;
  }
}

.canvas-areaChart {
  height: 100% !important;
  width: 100% !important;
  @include t {
    width: 100% !important;
  }
  @include m {
    height: 100% !important;
  }
}
