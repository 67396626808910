@import "../../styles/helper";

.modal-main {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .modal-content {
    width: 480px;
    border-radius: calc(var(--base-border-radius) * 0.75);
    text-align: center;
    background-color: $white;
    @include dark {
      background: $n2;
    }
    .modal-title-not {
      color: $n2;
      margin: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: var(--line-height-4);
      @include dark {
        color: $white;
      }
    }
    .modal-footer {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 12px 8px;
    }
  }
}
.modal-des {
  font-style: normal;
  font-weight: 100;
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  display: flex;
  text-align: left;
  align-items: flex-start;
  padding: calc(var(--base-padding) * 1.5) calc(var(--base-padding) * 2);
  border-bottom: 0.5px solid $white;
  color: black;

  @include dark {
    color: $white;
  }
}

.notsupported-header {
  height: 160px;
  padding: var(--base-padding) calc(var(--base-padding) * 0.5);
  padding: 8px;
  border-top-left-radius: calc(var(--base-padding) * 0.75);
  border-top-right-radius: calc(var(--base-padding) * 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-image {
  width: 96px;
  height: 96px;
}


