@import "../../../../styles/helper.scss";

.bidsheetModal{
    width: 768px;
}

.bidSheetModal-Header{
    height: 84px;
    border-bottom: 1px solid $n55;
    padding: 16px 56px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    @include dark{
        border-bottom: 1px solid $n34;
    }
    &__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }
    &__subTitle{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $n38;
    }
}
.auction-loan-modal-file-section{
   flex-direction: column;
   padding: 16px 24px;
}

.bidSheetModal-middleSection{
    height: 412px;
    overflow-y: auto;
}

.auction-loan-modal-file-section__container{
    @include dark{
        border: 1px solid $n34;
    }
}

.bidsheetModal__MismatchWarning{
    background:$n56;
    margin: 12px 24px;
    border: 1px solid $n29;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 15px;
    gap: 17px;
    &__alertIcon{
        color: $n29;
        font-size: 20px;
        
    }
    &__TextContainer{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__heading{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $n37;
        @include dark{
            color: $white;
        }
    }
    &__text{
        color: $n37;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        @include dark {
            color: $white
        }
    }
}
.bidsheetModalpart{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.bidsheetModal__singleMismatchWarning{
    background:$n56;
    border: 1px solid $n29;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 17px;
    height: 102px;
    &__alertIcon{
        color: $n29;
        font-size: 20px;
        
    }
    &__TextContainer{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__heading{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $n37;
        @include dark{
            color: $white;
        }
    }
    &__text{
        color: $n37;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        @include dark {
            color: $white
        }
    }
}

.bidsheetModal{
    min-width: 768px ;
    min-height: 576px ;
    border-radius: 8px;
    padding: 0px;
    .Modal__wrapper{
        padding: 0px;
    }
}

.bidsheetSuccessScreen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    &__textContainer{
        text-align: center;
        &__heading{
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }
        &__subheading{
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: $n27;
        }
    }
}
