@import "../../styles/helper.scss";

.minted-dashboard-body {
  max-height: 100vh;
  overflow: hidden;
  display: grid;
  grid-auto-flow: column;
  .create-sba7 {
    max-height: 93vh;
  }

  .mint-circle-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    .loader {
      border-radius: 50%;
      animation: spin 2s linear infinite;
      width: 54px;
      height: 54px;
    }
    .loader-blue {
      border: 3px solid #3c65d6;
      border-top: 3px solid #8c97b8;
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

exchange-dashboard {
  .header {
    display: none;
  }

  .ant-tabs-tab {
    padding-block: 16px;
  }

  .sidebar-container {
    display: none;
  }
  .mint-app {
    grid-template-columns: auto;

    .minted-tiles {
      padding-bottom: 100px;
    }
    .header__name {
      display: none;
    }
    .theme_icon {
      display: none;
    }
  }
}
.mintContainer {
  .mint-wrapper {
    height: calc(100vh - 184px);
  }
  .music-main-container {
    height: calc(100vh - 184px);
  }

  .minted-assets-container .react-table-responsive-wrapper {
    height: calc(100vh - 290px);
  }

  .sba7-assets-container .react-table-responsive-wrapper {
    height: calc(100vh - 235px);
  }
}

.mint-toaster {
  text-align: left;

  .Toastify__toast-theme--light {
    @include dark {
      background: $dark !important;
      color: $white !important;
    }
  }
  .Toastify__close-button--light {
    @include dark {
      color: $white !important;
      opacity: 0.3;
    }
  }
}