@import "../../styles/helper";

.wallet {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wallet-account-details-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
}

.wallet-transaction-details-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  background-color: $white;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $n6;
  @include dark{
    background-color: $n19;
    border-color: $n12; 
  }

  .transaction-details-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
    }

    small {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $s5;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  .transaction-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
