@import "../../../../styles/helper";

.transactions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1;
  overflow-y: auto;
  padding-inline: 20%;
  padding-top: 24px;
  height: 100vh; /* Full viewport height */
  overflow-y: hidden;
  .transaction-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      display: flex;
      gap: 15.64px;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid rgba(108, 112, 122, 1);
        i {
          font-size: 24px;
          color: rgba(0, 0, 0, 1);
          @include dark {
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  main {
    display: flex;
    flex-flow: column;
    gap: 16px;
    flex: 1 1;
    overflow-y: auto;

    .transaction-tabs {
      display: flex;
      flex-flow: row nowrap;
      border-bottom: 2px solid $n49;
      @include dark {
        border-bottom-color: $n51;
      }
      span {
        padding: 16px;
        border: 0px, 0px, 1px, 0px;
        gap: 10px;
        color: #777e90;
        cursor: pointer;
        font-weight: 600;
        border-bottom: 2px solid transparent;
        &:hover{
          color: $blue;
          border-bottom-color:  $blue;
          transition: .4s all ease-in-out;
          @include dark {
            color: $n10;
            border-bottom-color: $n10;
          }
        } 
      }
      .active-tab {
        border-bottom: 2px solid $blue;
        color:  $blue;
        @include dark {
          color: $n10;
          border-bottom: 2px solid  $n10;
          
        }
      }
    }
    .transaction-container {
      flex: 1 1;
      overflow-y: scroll; 
      -ms-overflow-style: none;  
      scrollbar-width: none;
    }
    .transaction-container::-webkit-scrollbar {
      display: none; 
    }
  }
  .transac-empty-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    }
    .transac-empty-text{
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: $n5;
      @include dark{
        color: $n5
      }
            
    }
    .no-data-image {
      display: block;
      margin: 100px auto 0;
      width: 150px;
    }
}
