body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#exchange--wrapper {
  scroll-behavior: smooth;
}


.hover:hover{
  cursor: pointer;
  transition: .4s all ease-in-out;
  color: #7398fc;
  text-decoration: underline;
}


.kybUpgrade-iframe {
  width: 100%;
  height: 90vh;
}  

.kybUpgrade .accreditation-iframe__loading{
  height: 90vh;
}
