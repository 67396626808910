@import "../../../../../styles/helper";

.bisheet-modal{
  max-width: 600px !important;
  &.Modal_outer__B4TkJ > div{
    padding: 0 !important;
    width: 600px;
  }

  .Modal_close__nt4fk{
    top: 20px;
  }

  .bisheet-modal_header{
    padding: 16px 56px 16px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   
    @include dark{
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
   
    h3{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: unset;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #B8B8B8;
    }
  }

  .bisheet-modal_body{
    padding: 16px;
  }

  .data-room-file-container{
    border: 1px dotted #1472FF !important;
    padding: 24px;
    border-radius: 4px;
    background-color: rgba(0, 97, 245, 0.08) !important;
  }

  .bg-none{
    background-color: $n6;
    @include dark{
      background-color: $n2;
   }
  }

  .btn-danger{
    border-radius: 4px;
    background-color: rgba(233, 53, 53, 0.12);
    color: #E93535;
    z-index: 99;

    &:hover{
      background-color: rgba(233, 53, 53, 0.12);
      color: #E93535;
      opacity: 80%;
    }
  }

  .file-icon{
    width: 53px;
    height: 63px;
    margin: 16px 0;
  }

  .data-room-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px;

    span {
      font-size: 64px;
      color: #4574f5;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #3A3B41;
      @include dark{
        color: #F5F5F5;
      }
    }
  
    .highlight-text{
      font-size: 16px !important;
      font-weight: 600;
      line-height: 24px;
      color: #1472FF;
    }

    small {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #7c8398;
      margin-top: -4px;
    }
  
    .image_browse {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
      opacity: 0;
      cursor: pointer;
    }
  }

  .btn-transparent{
    background-color: $n5;
    &:hover{
      background-color: $n5;
      opacity: 80%;
    }

    @include dark{
      background-color: #3A3B41;

      &:hover{
        background-color: #3A3B41;
        opacity: 80%;
      }
    }
   
  }


  .bidsheet-modal_footer{
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: right;
      padding:18px 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.12); 
    
      @include dark{
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      }

      button{
        text-align: center;
        width: 114px;
        padding: 16px 20px !important;
        border-radius: 4px !important;
      }
  }
}
