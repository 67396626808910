@import "styles/helper.scss";

.issuance-msg{
    margin: 0px 8px;
    border: 1px solid #F38A20;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    
    font-weight: 500;
    line-height: 20px;
    display: flex;
    gap: 8px;
    background: #EFB6430F;
    @include dark{
      color: #D6D6D6;
    }
    i{
    color: #F38A20;
    font-size: 20px;
    }
}
@media (max-width: 480px) {
  .issuance-msg{
    margin: 0 16px;
  }
}