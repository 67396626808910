.issuanceSignDoc{

 iframe,.issuance-iframe__loading {
  width: 100%;
  height: 80vh;
 
}

 .issuance__header {
    padding: 12px 24px;
    display: flex;
        justify-content: space-between;
        border-bottom: 1px solid  #0000001F;
    .issuance__header--inner {
    display: flex;
    gap: 12px;
}
    .issuance_profile {
     img{
      max-width: 48px;
     }
    }
    h3{
        font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #2F323D;
    }
    p{
        font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #5C5C5C;
    
    }
    a{
       color: #0061F5;

    }
  }
  button{
    margin-right: 32px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #3C65D6;
    border: 1px solid #3C65D6;
    background: #3C65D60F;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    width: 135px;
  }
}

.issuance_modal {
  padding: 0;
  width: 100%;
  max-width: 80% !important;
  margin: auto;
  border-radius: 0;
  .Modal__wrapper {
    padding: 0;
    max-width: 100% !important;
  }
  .Modal__closeButton{
    right: 4px !important;
  }
  .Modal_title{
    margin: 0;
  }
}
