// @use 'utils';
@import "../../../../../../styles/helper.scss";

.options-wrapper {
  position: relative;
}
.options-box {
  position: absolute;
  right: 0;
  margin-top: 4px;
  background: $white;
  opacity: 22;
  padding: 8px 0;
  border-radius: 8px;
  height: 52px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  width: 168px;
  border-radius: 4px;
  border: 1px solid $n43;
  @include dark {
    background: $n17;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.5);
  }
}
.option-manage {
  position: absolute;
  right: 0;
  margin-top: 4px;
  background: $white;
  opacity: 22;
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 228px;
  height: 52px;
  border-radius: 4px;
  border: 1px solid $n43;
  width: 262px;
  @include dark {
    background: $n17;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.5);
  }
}
.space {
  color: $n24;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
.menu-item {
  height: 36px;
  color: $n24;
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menu-item:hover {
  background: $n22_1;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  @include dark {
    background: $n19;
  }
}
.iconWithBackground {
  padding: 8px;
  background: lightgray !important;
  border-radius: 4px;
  @include dark {
    background: gray !important;
  }
}
.extra {
  cursor: pointer !important;
  background-color: transparent;
  padding: 8px;
}
.icon-container {
  position: relative;
}
.manage-removal-request {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 8px;
}
.manage-removal-request:hover {
  background: $n22_1;
  border-radius: 2px;
  transition: all 0.3s;
  padding: 4px;
  cursor: pointer;
  @include dark {
    background: $n19;
  }
}
