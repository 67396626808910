@import "../../../styles/helpers.sass";
@import "../../../styles/helper.scss";

.sba-order-details-container {
  overflow-y: auto;
  flex: 1 1;

  .issuer-info {
    display: flex;
    align-items: center;
    padding-block: 12px;
    margin-bottom: 16px;
    border-bottom: 1px solid $n6;

    @include dark {
      border-bottom-color: $n3;
    }
  }

  .issuer-logo {
    margin-right: 16px;

    .logo-placeholder {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: $n6;
      font-size: 20px;
    }

    @include dark {
      .logo-placeholder {
        background-color: $n3;
      }
    }
  }

  .issuer-details {
    flex: 1;
    line-height: 24px;

    .issuer-symbol {
      font-size: 16px;
      font-weight: 600;
    }

    .issuer-name {
      font-size: 14px;
      font-weight: 500;
      color: $n4;
    }
  }

  .step-circle {
    width: 60px;
    height: 60px;

    .CircularProgressbar-trail {
      stroke: $p1_12;
    }

    .CircularProgressbar-path {
      stroke: $p1;
    }
  }

  .step-inner-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .step-text {
      font-size: 10px;
      font-weight: 400;
      color: $n3;
      @include dark {
        color: $n5;
      }
    }

    .step-number {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .order-content {
    display: flex;
    gap: 20px;
  }

  .order-info-panel {
    width: 288px;
    background-color: $n64;
    padding: 16px;
    height: fit-content;

    .info-section {
      padding: 8px 0;
      border-bottom: 1px solid $n16;

      &:last-child {
        border-bottom: none;
      }

      .info-label {
        font-size: 14px;
        font-weight: 500;
        color: $n4;
      }

      .info-value {
        font-size: 14px;
        font-weight: 600;

        &.order-type-SELL {
          color: $p3;
        }

        &.order-type-BUY {
          color: $p4;
        }

        &.total-amount {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    @include dark {
      background-color: $n17;

      .info-section {
        border-bottom-color: $n3;
      }
    }
  }

  .order-steps-panel {
    flex: 1;
  }

  .steps-timeline {
    position: relative;
    padding-left: 12px;

    &::before {
      content: '';
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: $n5;

      @include dark {
        background-color: $n4;
      }
    }

    .step-item {
      border-left: 2px solid transparent;
      position: relative;
      padding-left: 22px;

      &:last-child {
        margin-bottom: 0;
        border-left-color: $n8
      }

      @include dark {
        &:last-child {
          border-left-color: $n1
        }
      }

      &.active {
        border-left-color: $p4;

        &:last-child {
          border-left-color: $n8
        }

        .step-marker {
          border-color: $p4 !important;
          background-color: $white;
        }

        @include dark {
          &:last-child {
            border-left-color: $n1
          }
        }
      }

      .step-marker {
        position: absolute;
        left: -13px;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid $n5;
        background-color: $white;
        z-index: 1;

        &.completed {
          background-color: $p4 !important;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            display: inline-block;
            transform: translate(-50%, calc(-50% - 2px)) rotate(45deg);
            height: 12px;
            width: 8px;
            border-bottom: 2px solid $white;
            border-right: 2px solid $white;
          }
        }

        @include dark {
          border-color: $n4;
          background-color: $dark
        }
      }

      .step-content {
        padding-bottom: 16px;

        .step-title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 16px;
          line-height: 1.4;
        }
      }
    }
  }

  .document-card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .document-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: $n6;
      border-radius: 50%;
      margin-right: 16px;

      .document-icon-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: none;
      }
    }

    .document-name {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
    }

    .review-button {
      background-color: $p1_12;
      border: 1px solid $p1;
      color: $p1;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;

      &[disabled] {
        cursor: not-allowed;
      }
    }

    @include dark {
      .document-icon {
        background-color: $n3;
      }
    }
  }

  .signers-section {
    background-color: $n64;
    border-radius: 8px;
    padding: 16px;

    .signers-title {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: $n4;

      &:after {
        content: "";
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid $n5;
      }
    }

    .signer-item {
      display: flex;
      margin-bottom: 16px;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      .signer-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        border-radius: 4px;
        font-weight: 500;
        margin-right: 12px;
        border: 1px solid $n5;
      }

      .signer-info {
        flex: 1;

        .signer-role {
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .signer-email {
          font-size: 12px;
          color: $n4;
        }
      }

      .signer-status {
        display: flex;
        align-items: center;
        gap: 4px;

        .status-indicator {
          width: 16px;
          height: 16px;
          border: 2px solid $n5;
          border-radius: 50%;
        }

        .checkbox-signed {
          font-size: 16px;
          color: $p1;
        }

        span {
          font-size: 14px;
          color: $n4;
        }
      }
    }

    @include dark {
      background-color: $n17;

      .signers-title {
        &:after {
          border-bottom-color: $n3;
        }
      }

      .signer-number {
        border-color: $n3;
      }

      .signer-status {
        .status-indicator {
          border-color: $n4;
        }

        span {
          font-size: 14px;
          color: $n4;
        }
      }
    }
  }

  .certification-box {
    background-color: $n53_6;
    border: 1px solid $n53;
    border-radius: 8px;
    padding: 10px 12px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    .certification-icon {
      margin-right: 12px;
      font-size: 20px;
      color: $n53;
      line-height: normal;
    }

    .certification-text {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .sba-order-detail-dataroom-container {
    background-color: $n7;
    padding: 16px;
    border-radius: 4px;

    .files-description {
      gap: 4px;

      .file-names {
        flex: 1 1;
        width: 150px;
        white-space: nowrap;

        p {
          max-width: unset;
        }
      }
    }

    &.hide-uploader {
      .data-room-file-container, .data-room-files-header {
        display: none;
      }
    }

    .file-details-no-gap {
      border: 1px solid $n5;
      border-radius: 4px;
      padding: 8px;
      position: relative;

      .circle-loader {
        width: auto;
      }
    }

    .files-description-container:not(.not-divider-line) {
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 37px;
        border-left: 1px solid $n5;
      }
    }

    .data-room-file-container {
      border: 1px dashed $n5;
      background-color: $p1_12;
      height: 140px;
    }

    .data-room-content .upload-icon {
      font-size: 48px;
      line-height: normal;
    }

    .data-room-content p {
      font-size: 14px;
      font-weight: 500;
    }

    .data-room-button-container {
      text-align: right;
      margin-top: 16px;
    }

    .data-room-button {
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      min-height: 40px;
    }

    &.dataRoomUploading {
      .auction-form-data-room {
        cursor: not-allowed;
      }

      .data-room-file-container, .data-room-files-container {
        pointer-events: none;
      }
    }

    @include dark {
      background-color: $dark;

      .data-room-file-container {
        border-color: $n3;
      }

      .file-details-no-gap {
        border-color: $n3;
      }

      .files-description-container:after {
        border-color: $n3;
      }
    }
  }

  .addendum-checkbox-container {
    margin-top: 16px;
    padding: 16px;
    background-color: $n7;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;

    .data-room-button-container {
      margin-top: 16px;
      display: flex;
      gap: 10px;
    }

    .data-room-button {
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      min-height: 40px;

      &.btn-no {
        background-color: transparent;
        color: black;
        border: 1px solid $n5;
      }
    }

    @include dark {
      background-color: $dark;
      .data-room-button {
        &.btn-no {
          border-color: $n4;
          color: $white;
        }
      }
    }
  }

  .sba-order-detail-skeleton {
    width: 100% !important;
    height: 140px;

    .skeleton-loader-light, .skeleton-loader-dark {
      border-radius: 4px;
    }
  }

}

.order-detail-kyc-modal-container {
  padding: 0 !important;
  width: 100%;
  max-width: 80% !important;
  height: 90vh;

  .Modal__wrapper {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0;
    line-height: 0;
    height: 100%;
  }

  @include dark {
    &.exchange-modal-wrapper.ReactModal__Content {
      background-color: $white !important;
    }
    &.exchange-modal-wrapper .Modal__wrapper {
      background-color: $white !important;
    }
  }

  .Modal__closeButton {
    top: 18px !important;
    right: 4px;
  }

  .order-detail-modal-iframe {
    width: 100%;
    height: 100%;
    display: block;

    &.is-loading {
      height: 0;
    }
  }
}
