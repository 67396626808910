@import "../../../../styles/helper.scss";

.add-switch-account-modal {
  max-width: 576px !important;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 !important;

  &.email-verify {
    max-width: 768px !important;
    width: 100%;

    .Modal__wrapper {
      //background-color: $n7 !important;
      @include dark {
        background-color: $n1 !important;
      }
    }
  }

  .Modal_title {
    margin-bottom: 10px;
  }

  .Modal__closeIcon {
    font-size: 24px;
  }

  .Modal__wrapper {
    padding: 0;
    border-radius: 8px;
    //background-color: $n7 !important;
    overflow: hidden;

    @include dark {
      background-color: $n17 !important;
    }
  }

  .add-switch-account-modal-header {
    padding-inline: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid $n6;
    margin-bottom: 20px;

    .header-heading {
      font-size: 20px;
      font-weight: 600;
    }

    .header-des {
      font-size: 14px;
      font-weight: 400;
      color: $n4;
    }

    @include dark {
      border-bottom-color: $n3;
    }
  }

  .email-warning {
    padding: 20px;
    margin-inline: 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    border: 1px solid #E27A13;
    background-color: #E27A130F;
    align-items: flex-start;
    gap: 10px;

    i {
      font-size: 20px;
      color: #E27A13;
      line-height: 20px;
    }
  }

  .account-input {
    padding-inline: 20px;
    margin-top: 20px;

    .input__label {
      color: $n4;
    }

    .input__text-fields {
      background-color: $n7;
      border-color: $n6;
    }

    .input__text-fields--error {
      border-color: var(--color-error) !important;
      background-color: hsla(0, 100%, 50%, 0.06) !important;
    }

    @include dark {
      .input__label {
        color: $n9;
      }

      .input__text-fields {
        background-color: $n21;
        border-color: $n3;
      }
    }
  }

  .add-switch-account-modal-footer {
    border-top: 1px solid $n6;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    gap: 10px;

    button {
      min-height: 48px;
      height: 48px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 0;
      padding-inline: 14px;
      width: 114px;
    }

    .gray-btn {
      background-color: $n6;
      color: $n3;
    }

    .button__filled--primary:disabled {
      background: #3772FF !important;
    }

    @include dark {
      border-top-color: $n3;

      .gray-btn {
        color: $n5;
        background-color: $n72;
      }
    }
  }

  .switch-account-email-verify {
    padding-block: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .switch-account-email-verify-heading {
      font-size: 20px;
      font-weight: 600;
    }

    .switch-account-email-verify-des {
      font-size: 14px;
      font-weight: 400;
      color: $n4;
      max-width: 478px;
      display: flex;

      &.bottom-border {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $n6;
        max-width: 90%;
      }

      .email {
        font-weight: 500;
        color: $n4;
      }

      .edit {
        font-weight: 600;
        color: $n74;
        cursor: pointer;
      }

      .resend {
        font-weight: 500;
        color: $n69;
        cursor: pointer;
      }

      @include dark {
        color: $n9;

        &.bottom-border {
          border-bottom-color: $n3;
        }

        .email {
          color: $n6;
        }
      }
    }

    &.success {
      padding-bottom: 60px;
      .add-switch-account-modal-footer {
        padding: 0;
        margin-top: 0;
        border-top: 0;
      }
    }
  }

}
