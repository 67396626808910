$title-font: 500 18px/1.2 "Ubuntu", sans-serif;
$cool-grey: #555;
$box-shadow-bottom-heavy: 0 15px 20px rgba(0, 0, 0, 0.1);
@import "../../../../styles/helpers";

.social-login-options-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  &__options {
    display: flex;
    align-items: center;
    gap: calc(var(--base-margin) * 0.5);
    &__item {
      width: calc((100% / 4) - 4px);
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 4px;
      cursor: pointer;
      background-color: $n17;
      &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          // background-color: $n17 !important;
          -webkit-transition:unset !important;
          transition: unset !important
        }
      }

      &:hover {
        background-color: $n2;
        -webkit-transition: 0.4s all ease-in-out;
        transition: 0.4s all ease-in-out;
      }
    }
  }
  &__more_options {
    border: 1px solid #0000001f;
    width: 100%;
    height: 40px;
    display: grid;
    place-items: center;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    color: var(--color-text-130-light);
    cursor: pointer;
    i {
      font-size: 20px;
    }
    &--disabled {
      background-color: initial;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: unset !important;
      }
    }
    &:hover {
      background-color: hsla(0, 0%, 0%, 0.05);
      -webkit-transition: 0.4s all ease-in-out;
      transition: 0.4s all ease-in-out;
    }
  }
}
