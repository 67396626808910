.verificationStep {
  background-color: #1973fa;
}
.verification-steps {
  padding: 12px;
  width: 100%;
  // max-width: 1200px;
  margin: 0 auto;
  .steps-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 1rem;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    flex: 1;
    background: none;
    &.sessionInt {
      opacity: 0.8;
      &:first-child {
        opacity: 1;
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: calc(50% + 8px);
        width: calc(100% - 16px);
        height: 2px;
        background-color: rgba(255, 255, 255, 0.2);
        transform: translateY(-50%);
        transition: all 0.5s ease;
        border: 1px dashed white;
      }

      &.rejected::after,
      &.completed::after,
      &.processing::after,
      &:first-child::after {
        background-color: #4ade80;
        border: 1px solid white;
      }
    }

    &.completed,
    &.processing {
      .step-icon {
        background-color: white;
        border-color: white;
      }
    }

    &.completed .step-icon::before,
    &.processing .step-icon::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 11px;
      border-right: 2px solid #1973fa;
      border-bottom: 2px solid #1973fa;
      transform: rotate(45deg);
      top: 2px;
    }

    .step-icon::before {
      content: "";
      width: 4px;
      background: white;
      height: 4px;
      border-radius: 2px;
    }

    .step-icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      cursor: pointer;

      // &:hover {
      //   transform: scale(1.1);
      // }

      svg {
        color: #fff;
        width: 16px;
        height: 16px;
      }
    }

    .step-label {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 500;
      text-align: center;
      margin: 4px 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 8px;
      i {
        cursor: pointer;
      }
      .verification-action-btn {
        background: #ff9854;
        color: white;
        padding: 3px 8px;
        border-radius: 4px;
        font-size: 12px;
      }
    }

    .step-status {
      font-size: 12px;
      color: #ffffff;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background: #0000001f;
      padding: 2px 8px;
      border-radius: 16px;

      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
      }

      &.completed::before {
        background-color: #4ade80;
      }

      &.processing::before {
        background-color: #fbbf24;
      }
      &.rejected::before {
        background-color: #f26969;
      }
    }
  }
}

