@import "../../../../../styles/helper";

.auction-basic-form {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.Dropdown_label__eVaEB {
  color: #747e99 !important;
  text-transform: lowercase;
  font-size: 14px;
    font-weight: 500;
  &::first-letter {
    text-transform: capitalize;
  }
  @include dark {
    color: $white !important;
  }
}

.disable-dropdown {
  cursor: not-allowed;
  .Dropdown_head__USTbf {
    background: $n6 !important;
    pointer-events: none;
    @include dark {
      background: $n18 !important;
    }
  }
}

.auction-basic-form-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @media only screen and (max-width: 992px) {
    flex-flow: column;
  }
}

.auction-basic-form-container-left {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.auction-basic-form-container-right {
  display: flex;
  flex: 1 1;
  gap: 30px;
}

.auction-basic-form-container-fields {
  flex: 1 1;
  display: flex;
  gap: 16px;
}

.input:has(span.symbolPlaceholder) {
  .input__group {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0px;
    height: 56px;
  }

  .input__text-fields {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.symbolPlaceholder {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  background: #e6e6e6;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @include dark {
    background-color: $dark;
    color: $white;
    border: 1px solid $white;
  }
}

input[name="symbolValue"] {
  text-transform: uppercase !important;
}
