.no-data-wrapper {
  height: 100%;
  width: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
  &__image {
    width: 150px;
    object-fit: contain;
  }
  &__description {
    color: #777e90;
    font-size: 16px;
  }
}
