@import styles/helpers

.renderKycStatus
    display: flex
    flex-direction: column
    gap: 16px
    justify-content: center
    align-items: center
    &__usersImg
        height: 120px
    &__Texts
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 4px
        &__heading
            font-size: 20px
            font-weight: 600
            line-height: 28px
            +dark
                color: $n64
        &__subHeading
            font-size: 14px
            font-weight: 400
            line-height: 20px
            color: $n63
            text-align: center
            +dark
                color: $n68
    &__Status
        display: flex
        flex-direction: column
        gap: 8px
        align-items: center
        &__upper
            display: flex
            justify-content: center
            align-items: center
            gap: 8px
        &__circle
            width: 17px
            height: 17px
            border-radius: 100%
            border: 2px solid $n68
        &__yellow
            background: $n48
            border: none
        &__red
            background: $n29
            border: none

        &__checkboxIcon
            color: $n73
            font-size: 18px

        &__border
            width: 260px
            height:40px
            padding: 8px 16px
            border-radius: 8px
            font-size: 14px
            font-weight: 400
            line-height: 20px
            display: flex
            justify-content: flex-start
            gap: 10px
            border: 1px solid $n5
            +dark
                border: 1px solid #FFFFFF1F
                color: $n64
        &__Kyc
            
        &__aml
        &__accreditation
    &__btn
        width: 231px
        height:48px
        padding: 12px 20px 12px 20px
        border-radius:4px
        font-size: 16px
        font-weight: 500
        line-height: 24px
        background: $p1
        color: $white

