@import "../../../../styles/helper";

.biometric-policy {
  background-color: #fff;
  color: $n1;
  &__header {
    background: #fff;
    display: flex;
    padding: 12px 48px;
    align-items: center;
  }
  &__logo {
    width: 210px;
    height: 100%;
  }
  .otnotice {
    padding-top: 24px;
    ol {
      list-style-type: decimal !important;
    }
    .otnotice-sections {
      margin-left: unset !important;
    }
  }
}
