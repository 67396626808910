// @import ../../../styles/helpers
    
.progress_container 
    width: 100%
    background-color: #e0e0e0
    border-radius: 10px
    height: 10px
    overflow: hidden
    margin: 20px 0
    .progress_bar 
        height: 100%
        background-color: #4caf50
        width: 0
        border-radius: 10px
        transition: width 0.4s ease
  