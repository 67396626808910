@import styles/helpers

.mainContainer
    height: max-content
.orders
    height: 100%
    border: 1px solid $n6
    background: $n8
    border-radius: 8px
    transition: all 0.3s ease-out
    +dark
        background: $dark
        border-color: $n2
    +t
        margin-top: 8px

.head    
    position: sticky
    top: 0
    z-index: 2
    background: $white
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    +dark
        background: $dark
    
.nav
    display: flex
    overflow-x: scroll
    border-bottom: 1px solid $n6
    &::-webkit-scrollbar
        display: none
    +dark
        border-bottom: 1px solid $n2



.disabled
    pointer-events: none
    opacity: 0.6

.nav_header
    justify-content: space-between
    align-items: center

.link
    display: flex
    padding: 16px 16px
    background: none
    text-transform: capitalize
    min-width: max-content
    +poppins
    font-weight: 500
    font-size: 12px
    color: #777E91
    transition: all .2s
    border-bottom: 1px solid transparent
    &:hover
        color: $n10
        +dark
            color: $n10
    &.active
        color: $n10
        border-bottom: 1px solid $n10
        +dark
            color: $n10
            border-bottom: 1px solid $n10

.collapse
    width: 28px
    height: 28px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    font-size: 20px
    cursor: pointer
    margin-right: 16px

.collapse_true
    transition: transform 0.3s ease-out
    transform: rotate(180deg)

.collapse_false
    transition: transform 0.3s ease-out
    
.collapse_orders
    height: 100%
    min-height: 168px
    max-height: 168px
    border: 1px solid $n6
    background: $n8
    border-radius: 8px
    +dark
        background: $dark
        border-color: $n2
    +t
        margin-top: 8px

.overViewwrapper
    padding : 16px

.heading
    font-size: 16px
    line-height: 30px
    letter-spacing: -.01em 
    font-weight: 600 

.subData
    font-size : 16px
    line-height: 24px
    color : $n4
    font-weight: 500
    display: inline-block
    i
        font-size: 8px
    +dark
        color : $n4

.youtube
    color: $youtube
    cursor: pointer
    font-size: 24px
.spotify
    color: $spotify
    cursor: pointer
    font-size: 24px
.apple
    color: $white
    cursor: pointer
    font-size: 24px
.tiktok
    font-size: 20px
.soundcloud
    font-size: 24px
    color: $soundcloud
    cursor: pointer

.subHeading
    font-size : 16px
    line-height: 24px
    color : $n4
    font-weight: 500
    display: flex
    align-items: center
    gap: 4px
    i
        font-size: 8px
    +dark
        color : $n4
     

.newSubHeading
    margin-top : 4px
    font-size : 14px
    line-height: 20px
    color : $n4
    font-weight: 500
    +dark
        color : $n4
.description
    font-size : 12px
    line-height: 16px
    color : $n2
    letter-spacing: .01em
    font-weight: 500
    +dark
        color : $n16
.newsdescription
    font-size : 16px
    line-height: 24px
    color : $n2
    letter-spacing: -.01em
    font-weight: 500
    +dark
        color : $n16        

.showLess
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-size : 12px
    line-height: 16px
    color : $n2
    letter-spacing: .01em
    font-weight: 500
    +dark
        color : $n16


.cellWrapper
    display: flex
    flex-wrap: wrap

.cellbody
    width : 25%
    margin-top: 10px

 
.cellHead
    margin-top: 16px
    font-weight: 600
    border-bottom: 1px solid $n6
    font-size: 16px
    line-height: 24px
    letter-spacing: -.01em
    +dark
        border-bottom: 1px solid $n2 


.cellValue
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis 
    font-size : 20px
    line-height: 28px
    color : $n2
    font-weight: 600
    +dark
        color : $n6

.readmore
    color : $n10

.newsWrapper
    display: flex
    padding-top: 20px
    padding-bottom: 20px
    border-bottom: 1px solid $n6
    +dark
        border-bottom: 1px solid $n2 

.pic
    width: 200px
    max-height: 130px
    object-fit: cover
    cursor: pointer

.noNews
    width: 100%
    max-width: 200px
    max-height: 130px
    display : flex
    justify-content: center
    align-items: center

.defaultNewsImg
    width : 60px

.flexbox
    display : flex  
    align-items: center  
    color: $n16

.newsDetailsWrapper
    padding-left : 16px
    padding-right : 16px    

.cardTitle
    font-size : 12px
    line-height: 16px
    color : $n18
    letter-spacing: -.01em
    font-weight: 500
    +dark
        color : $n16

.symbol
    font-size : 12px
    line-height: 16px
    color : $n18
    font-weight: 500
    margin-left : 8px
    +dark
        color : $n16

.emptyState
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 8px
    .noDataText
        font-weight: 600
        font-size: 14px
        color: $n5
        +dark
            color: $n5

.dot
    width: 4px
    height: 4px
    background: $n4  
    margin-left: 5px 
    border-radius: 4px
    
.loaderContainer
        min-height: calc( 100vh - 556px )
        display: flex
        align-items: center             

@include s 
    .cellbody
        width : 50%
    .orders
        margin-bottom : 100px
        margin : 8px
    .pic
        width: 100%
        max-width: 100%
        max-height: 200px
    .noNews
        width : 100%
        max-width: 100%
        min-height: 130px
    .defaultNewsImg
        width : 70px
    .newsWrapper
        display: block    
    .newsDetailsWrapper
        padding-top : 8px
        padding-left : 0px
        padding-right : 0px  
.sortDesc
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-size : 12px
    line-height: 16px
    color : $n2
    letter-spacing: .01em
    font-weight: 500
    +dark
        color : $n16

@include t 
    .orders
        margin : 8px
        margin-bottom: 120px
    