@import ../../styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.wrap
    position: relative
    display: flex

.input
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 12px
    border: 2px solid $n6
    background: $n8
    +poppins
    +caption-bold-1
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        background: none
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.toggle,
.preview
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 48px

.toggle
    svg
        fill: $n5
        transition: fill .2s
    &:hover
        svg
            fill: $n4
    &.active
        svg
            fill: $n1
    +dark
        svg
            fill: $n4
        &:hover
            svg
                fill: $n5
        &.active
            svg
                fill: $n8

.preview
    display: flex
    justify-content: center
    align-items: center
    svg
        fill: $n4

.empty
    .input
        height: 26px
        border: none

.view,
.icon
    .input
        padding-right: 48px

.note
    margin-top: 12px
    +caption-3
    font-weight: 500
    color: $n4
    
.inputWebkit:-webkit-autofill 
    -webkit-box-shadow: 0 0 0 1000px white  inset !important
    +dark
        -webkit-box-shadow: 0 0 0 1000px transparent  inset !important
        -webkit-text-fill-color: white
        border: 2px solid #353945
        transition: background-color 5000s ease-in-out 0s


