@import styles/helpers

.confirmationModal
  +poppins

.confirmationImage
  width: 96px
  height: 96px

.simplciConfirmationHeader
  height: 160px
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  background: #fff
  border-bottom: 1px solid $n14
  border-radius: 8px
  border-top-left-radius: 20px
  border-top-right-radius: 20px
    

.confirmationHeader
  height: 160px
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  background: #EDEFF5
  border-bottom: 1px solid $n14
  border-top-left-radius: 20px
  border-top-right-radius: 20px
  +dark
    background: $n12
    border-bottom: 1px solid #3A3F4D
.confirmationTitle
  font-weight: 600
  font-size: 16px
  margin-top: 8px

.simplciModalDes
  font-weight: 400
  font-size: 14px
  padding: 24px
  line-height: 20px
  border-bottom: 1px solid $n14
  color: #000

.simplciConfirmationTitle
    font-weight: 600
    color: #000
    font-size: 16px
    margin-top: 10px



.modalDes
  font-weight: 400
  font-size: 14px
  padding: 24px
  line-height: 20px
  border-bottom: 1px solid $n14
  color: #6C707A
  +dark
    border-bottom: 1px solid #3A3F4D
    color: #C9CDD6
.modalFooter
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 16px 24px
.simplcibuttonCancel
  padding: 12px 24px
  font-size: 14px
  min-width: 92px
  height: 52px
  background: rgba(54, 56, 61, 0.06)
  line-height: 24px
  border-radius: 8px
  color: #000
  margin-right: 8px

.buttonCancel
  padding: 12px 24px
  font-size: 14px
  min-width: 92px
  height: 52px
  background: rgba(54, 56, 61, 0.06)
  line-height: 24px
  border-radius: 8px
  color: #51545C
  margin-right: 8px
  +dark
    background: rgba(255, 255, 255, 0.06)
    color: #D8DBE6

.buttonCancelYes
  position: relative
  padding: 12px 24px
  font-size: 14px
  min-width: 92px
  height: 52px
  line-height: 24px
  background: #F54545
  border-radius: 8px
  color: $white

.loaderIcon
  position: absolute 
  top: 50% 
  left: 50% 
  transform: translate(-50%, -50%) 
  visibility: hidden
  
.loadingActive .btnText
  visibility: hidden

.loadingActive .loaderIcon
  visibility: visible

.btnSuccess
  background: #49B876
.btnbuy
  background: $blue

