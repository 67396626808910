.fi-wrapper__fr_inner {
  width: 100%;
  height: 68vh;
  &__right {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px 24px;
    height: 280px;
    &__lists {
      display: flex;
      flex-direction: column;
      gap: var(--base-margin);
      margin-right: 4px;
      align-items: center;
      &__wrapper {
        // max-width: 550px;
        width: 100%;
        border-radius: calc(var(--base-border-radius) * 0.5);
        border: 2px solid transparent;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        background: #f4f5f6;
        border-radius: 8px;
        color: #878c99;
      }
      &__item {
        display: flex;
        align-items: center;
        gap: calc(var(--base-margin) * 0.5);
        justify-content: space-between;
        padding: var(--base-padding);

        &__details {
          display: flex;
          align-items: center;
          gap: var(--base-margin);
        }
        .bank-logo {
          min-width: 40px;
          max-width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .active-icon {
          color: #84a0eb;
          position: absolute;
          right: -8px;
          top: -16px;
          z-index: 111;
          width: 30px;
          height: 30px;
          font-size: var(--font-size-6);
          background: white;
          border-radius: 50%;
        }
        &__name {
          font-weight: 600;
          font-size: var(--font-size-3);
          color: var(--color-bg-90-dark);
        }
        &__number {
          font-weight: 600;
          font-size: var(--font-size-3);
          color: var(--color-text-80-light);
        }
      }
      .active {
        border: 2px solid #84a0eb;
      }
    }
  }
}
.fi-wrapper__fr_inner__right {
  .radio-list__wrapper {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-margin) * 0.25);
    padding: var(--base-padding);
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(var(--base-padding) * 0.5);
      cursor: pointer;
      border-radius: calc(var(--base-border-radius) * 0.5);
      &:hover {
        background: hsla(0, 0%, 0%, 0.03);
      }
      &__right {
        display: flex;
        align-items: center;
        gap: calc(var(--base-margin) * 0.5);
        &__sub_type {
          font-weight: 500;
          font-size: var(--font-size-3);
          color: #afb4c0;
          text-transform: capitalize;
        }
        &__mask {
          font-weight: 500;
          font-size: var(--font-size-2);
          color: var(--color-text-60-light);
        }
      }
    }
  }
  input[type="radio"]#primary {
    accent-color: #3158c4;
  }
  .fi-wrapper__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #23262f;
  }
  .fi-wrapper__sub_title {
    text-transform: capitalize;
  }
  .dropdown-icon {
    font-size: 20px;
  }
}

.dark-mode {
  .fi-wrapper__fr_inner__right__lists__wrapper {
    background: #36383d;
    color: #afb4c0;
  }

  .fi-wrapper__fr_inner__right .fi-wrapper__title {
    color: #f4f4f6;
  }
  .fi-wrapper__fr_inner__right__lists__item .active-icon {
    background: #282a2e;
  }
}
