@import "../../../../styles/helper.scss";

.biometric-setup-wrapper {
  align-items: center;
  background: #17181b;
  height: 100vh;
  padding: 0 9px;
  width: 100%;
  display: flex;
  justify-content: center;
  @include dark {
    background: #17181b;
  }
  &__inner {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 700px;
    height: 450px;
  }
  &__right {
    background-color: #f5f5f5;
    width: 30%;
    height: 100%;
    padding: 24px;
    display: grid;
    place-items: center;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    @include dark {
      background-color: #1d1e20;
    }
  }
  &__left {
    background-color: #ffffffff;
    width: 70%;
    height: 100%;
    padding: 24px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    @include dark {
      background-color: #0e0f10;
    }
  }

  .touch-id-login {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #e0e0e0;
  }
  
  .touch-id-title {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
    color: #3d3d3d;
    @include dark {
      color: #d6d6d6;
    }
  }
  
  .touch-id-description {
    margin: 10px 0;
    font-size: 16px;
    color: #5c5c5c;
    @include dark {
      color: #b8b8b8;
    }
  }
  
  .touch-id-list {
    margin-top: 24px;
    padding-left: 20px;
    list-style-type: disc;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 500;
    @include dark {
      color: #d6d6d6;
    }
  }
  
  .touch-id-list-item {
    margin: 5px 0;
  }
  
  .touch-id-button-group {
      margin-top: 48px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      width: 100%;
  }
  
  .touch-id-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 48px;
    font-weight: 500;
  }
  
  .touch-id-add-btn {
    background-color: #1472ff !important; 
    color: white;
    &:hover{
      background-color: #4791ff !important;
      transition: 0.4s all ease-in-out;
    }
  }
  
  .touch-id-not-now-btn {
    background-color: #f5f5f5;
    color: #757575;
    @include dark{
      background-color: #151617;
      color:#B8B8B8
    }
  }
  
  
  
  .touch-id-add-btn {
    background-color: #1472ff;
    @include dark {
      background-color: #1472ff;
    }
  }
  
  .touch-id-not-now-btn {
    background-color: var(--color-white-border);
    color: #5c5c5c;
    @include dark {
      border: 1px solid transparent;
    }
  }
  
  .touch-id-fingerprint {
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: grid;
    place-items: center;
    background-color: #ffffff;
    i {
      font-size: 70px;
      color: #3c65d6;
    }
    @include dark {
      background-color: #0e0f10;
      border: 1px solid transparent;
    }
  }
  
  
  .skip-content {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    color: #ffffff;
    padding: 12px 24px 12px 24px;
    span {
      cursor: pointer;
      margin-left: 4px;
      text-decoration: underline;
      width: 36px;
      color: #f5c462;
    }
  }
  
}

// only for MLC css 
.mlc_biometric-setup-wrapper {
  @extend .biometric-setup-wrapper;
  &__left {
    @extend .biometric-setup-wrapper__left;
  }

  &__right {
    @extend .biometric-setup-wrapper__right;
  }
  &__inner {
    @extend .biometric-setup-wrapper__inner
  }
  @media (max-width: 480px) {
    background-color: black !important;
    padding: 0 16px;
    &__left {
      width: 100%;
      height: auto;
      padding: 0;
      background-color: transparent !important;
    }

    &__right {
      display: none;
    }
    &__inner{
      width: 100%;
      height: auto;
    }
    .mlc_touch-id-fingerprint {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      i {
        font-size: 70px;
        color: #3c65d6;
      }
    }
  }
  @media (min-width: 481px) {
    .mlc_touch-id-fingerprint{
      display: none;
    }
  }
}
