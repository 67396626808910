@import "../../../../../styles/helper";

.auction-banner-container {
  position: relative;
  height: 380px;
  border-radius: 8px;
  background: #f4f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;

  &__resizeButton-container{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 8px;
    padding: 12px;
    z-index: 1;
  }

  &__resizeButton{
    &:hover{
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 6px 12px rgba(255, 255, 255, 0.3);
    }
    display: grid;
    align-content: center;
    justify-content: center;
    width: 40px;
    background: $n64;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(255, 255, 255, 0.2);
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    .resizeIcon{
      color: $n30;
    }
  }

  &__deleteButton{
    &:hover{
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 6px 12px rgba(255, 255, 255, 0.3);
    }
    display: grid;
    align-content: center;
    justify-content: center;
    width: 40px;
    background: $n66;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(255, 255, 255, 0.2);
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    .deleteIcon{
      color: $n29;
    }
  }

  #ri-shape-2-line{
    left: -72.1055px !important;
    .react-tooltip-arrow{
      left: 100px !important;
    }
  }
  #ri-delete-bin-6-line{
    left: -22.8475px !important;
    .react-tooltip-arrow{
      left: 98px !important;
    }
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
    border-radius: 8px;
    background-position: center;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
  }

  .auction-form-image {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    align-items: center;

    &__image-icon{
      font-size: 50px;
      color: $blue;
    }

    .auction-banner-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      text-align: center;

      .banner-content {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $n1;
        @include dark {
          color: #7c8398;
        }
      }

      .banner-content-instruction {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #515767;
        span{
          color: $blue;
        }
      }

      .banner-cuntent-restriction {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #7c8398;
      }
    }
  }

  @include dark {
    background-color: $dark;
    border-color: #e6e8ec;
  }
}
