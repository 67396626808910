@import styles/helpers

.qr_wrapper
    width: 100%
    height: 100vh
    place-items: center
    display: grid
    background: $dark

.inner_wrapper
    max-width: 90%
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px

.inner
   background: #282A2E
   border-radius: 8px
   padding:48px 32px
   width: 100%
   +m
        padding: 24px 16px
  

.left_wrapper
    display: flex
    flex-direction: column
    gap: 24px
.row
    gap: 48px
    display: flex
    +m
        flex-direction: column
        gap: 23px

.title 
   font-weight: 500
   font-size: 24px
   line-height: 32px
   color: $n16

.lists
    display: flex
    flex-direction: column
    gap: 16px
    padding: 0px 24px
    +m
        padding: 0px 8px
    
.list
   font-weight: 400
   font-size: 16px
   line-height: 24px
   color: $n16

.highlighter
    font-weight: 600
    +dark
        color: $white
.index
    color: $white
    font-weight: 600
    padding-right: 8px

.trouble_text
  font-weight: 500
  font-size: 14px
  line-height: 20px
  color: #0C87FD


.footer_text
   font-weight: 400
   font-size: 14px
   line-height: 20px
   color: $n11
   cursor: pointer

.qr_container
    align-self: center
    height: 200px
    width: 200px
    +m
        margin-left: -20px
.qrimage
    +m
        object-fit: contain
        width:  100%
        height: 100%

    
    