@import "../../../../styles/helper";

.terms-of-service {
  background-color: #fff;
  &__header {
    background: #fff;
    display: flex;
    padding: 12px 48px;
    align-items: center;
  }
  &__logo {
    width: 210px;
    height: 100%;
  }
  .otnotice {
    padding-top: 24px;
    ol {
      list-style-type: decimal !important;
    }
  }
}
.loadingTerms {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}