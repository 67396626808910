@import styles/helpers

.reports_container
    background: #ffffff
    padding: 24px 32px
    overflow-x: hidden
    overflow-y: auto
    display: flex
    flex: 1 1 
    gap: 16px
    flex-direction: column
    justify-content: space-between
    +scroll
    +dark
        background: $n78
    &_header
        position: sticky
        top: 0
        z-index: 9
    &_footer
        position: sticky
        bottom: 0
        z-index: 100
 
.report
    padding: 24px 0 0 16px
    display: flex
    flex-direction: column
    gap: 16px
    &_wrapper_button
        display: flex
        gap: 16px
        align-items: center
        +m
            display: flex
            flex-direction: column
            gap: 8px

.container
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    +m
        display: flex
        flex-direction: column
        gap: 8px
    .section_heading
        font-size: 20px
        font-weight: 600
        line-height: 32px
    &_button
        display: flex
        justify-content: center
        align-items: center
        gap: 8px
        min-width: 178px
        background: #3C65D6
        border-radius: 4px
        color: $white
        padding: 8px
        font-weight: 600
        font-size: 14px
        &:hover
            scale: 0.99
    &_secondaryButton
        background: #3C65D6
        cursor: pointer
     
.charts
    display: flex
    flex-flow: row nowrap
    gap: 16px
    +t
        display: flex
        flex-direction: column
    +dtt    
        display: flex
        flex-direction: column

.table_report
    border-radius: 4px
    display: flex
    flex-direction: column
    flex: 1 1
    +t
        width: 100%
    +m
        width: 100%
