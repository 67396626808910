@import styles/helpers

.portfolio
    padding: 8px 16px
    background: $white
    border-radius: 4px
    width: 100%
    min-width: 304px
    min-height: 370px
    +dark
        background: #282A2E
    &:hover
        box-shadow: 0 1px 4px rgb(0 0 0 / 16%)
        transition: all .2s ease-in-out
    &__text
        font-weight: 700
        font-size: 16px
        line-height: 24px
        color: #17191F
        +dark
            color: $white
    &__sub_text
        font-weight: 600
        font-size: 16px
        line-height: 24px
        color: #747E99
        display: flex
        flex-direction: row
        gap: 4px
        +dark
            color: $n16
        &_value
            color: #464C5C
            +dark
                color: $white
    &__valueData
        display: flex
        justify-content: space-between
        +m
            flex-direction: column
            gap: 4px

.profitData
    display: flex
    align-items: center
    gap: 8px
    +m
        justify-content: flex-end
    &_value
        font-weight: 600
        font-size: 16px
        color: #464C5C
        +dark
            color: $white
    
    &_percent
        display: flex
        flex-direction: row
        align-items: center
        padding: 0px 8px
        gap: 2px
        border-radius: 6px
        font-weight: 500
        font-size: 14px
        color: #464C5C
        +dark
            color: $white

.profit_up
    color:  #00B929
    font-size: 18px

.loss_down
    color: #F55353
    font-size: 18px

.totalGainAndLossPercent
    color: #464C5C        
