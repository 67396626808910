@import "./helper";

.text-green {
  color: $p4 !important;
}

.react-tooltip.theme-tooltip {
  max-width: 350px !important;
  border: 1px solid $n6;
  background-color: $white;
  color: $n23;
  font-size: 14px;
  text-wrap: wrap;
  z-index: 15;

  .react-tooltip-arrow {
    border-right: 1px solid $n6;
    border-bottom: 1px solid $n6;
  }

  @include dark {
    border-color: $n18;
    background-color: $n2;
    color: $white;

    .react-tooltip-arrow {
      border-right-color: $n18;
      border-bottom-color: $n18;
    }
  }
}

.separate-line-with-text {
  border-bottom: 1px solid $n6;
  margin-block: 25px;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-inline: 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: $white;
  }

  @include dark {
    border-bottom-color: $n18;

    span {
      background-color: $dark;
    }
  }
}

.no-data-found {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $n3;
  background-color: $white;
  font-size: 24px;
  font-weight: 600;
  gap: 10px;

  .icon {
    font-size: 50px;
  }

  @include dark {
    background-color: $dark;
    color: $white;
  }
}

.line-tabs {
  display: flex;
  border-bottom: 1px solid $n36;
  gap: 10px;
  background-color: $white;

  &_item {
    font-size: 14px;
    font-weight: 500;
    color: $n4;
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    text-transform: capitalize;

    &.active {
      color: $n10;
      border-bottom-color: $n10;
    }
  }

  @include dark {
    background-color: $dark;

    &_item {
      color: $n16;

      &.active {
        color: $n10;
      }
    }
  }
}

.yellow-anchor {
  color: $n10;
  cursor: pointer;
  text-decoration: underline;
}

.no-skeleton {
  .skeleton_logo,
  &.skeleton_logo {
    background: $n6;
    animation: none;

    @include dark {
      background: $n4;
    }
  }
}

.skeleton-fade-in-out {
  background: $n5;
  animation: fadeInOut 2s linear infinite;

  .skeleton_logo,
  &.skeleton_logo {
    background: $n5;
    animation: fadeInOut 2s ease infinite;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.report-bug-container {
  position: absolute;
  bottom: 60px;
  right: 60px;
  z-index: 100;
  cursor: pointer;

  img {
    pointer-events: none;
  }
}

#transaction-data-reports .infinite-scroll-component__outerdiv{
  height: 100% !important;
}
