.splash-screen-container {
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  img {
    width: 121px;
    height: 125px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}
