@import "../../../../styles/common.scss";

.InsufficientFunds{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 16px;
    border-radius: 8px;
    @include dark{
        background: $n21;
    }
    &__img{
        height: 120px;
    }
    &__texts{
        display: flex;
        flex-direction: column;
        gap: 4px;
        &__heading{
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            @include dark{
                color: $n64;
            }
        }
        &__subHeading{
            color: $n68;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }
    &__btn{
        padding: 12px 20px 12px 20px;
        border-radius:4px;
        background: $p1;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        width: 117px;
        height: 48px;
        
    }
}