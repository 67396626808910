@import "../../styles/helper.scss";

.account-deletion {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: black;

  @include dark {
    background-color: #121212;
    color: white;
        border: 1px solid grey;
  }

  .account-delete-title {
    text-align: center;
    color: black;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 500;
    @include dark {
      color: white;
    }
  }

  p {
    color: #333;
    line-height: 1.5;
       margin-bottom: 12px;
    @include dark {
      color: #ccc;
    }
  }

  ul {
    padding-left: 20px;
    list-style: disc;

    li {
      margin-bottom: 8px;
      color: #555;

      @include dark {
        color: #bbb;
      }
    }
  }

  .data-safety {
    font-weight: 500;
    color: black;
    margin-top: 10px;

    @include dark {
      color: white;
    }
  }

  .deletion-form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    label {
      font-weight: 500;
      margin-bottom: 5px;

      @include dark {
        color: white;
      }
    }

    textarea {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
      resize: none;
      background: white;
      color: black;

      @include dark {
        background: #222;
        color: white;
        border: 1px solid #555;
      }
    }

    .request-deletion {
      margin-top: 10px;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background: #3C65D6;
      color: white;
      font-size: 16px;
      cursor: pointer;
      transition: background 0.3s ease-in-out;

      // &:hover {
      //   background: #333;
      // }

      @include dark {
        // background: white;
        // color: black;

        // &:hover {
        //   background: #ccc;
        // }
      }
    }
  }
}

.ThankYou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;


.ThankYou__card {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
}

.ThankYou__check-icon {
  color: #4CAF50;
  font-size: 64px;
  margin-bottom: 20px;
}

.ThankYou__title {
  color: #333;
  margin-bottom: 30px;
  font-size: 32px;
}

.ThankYou__message {
  margin-top: 20px;
}

.ThankYou__text {
  margin: 15px 0;
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}

}