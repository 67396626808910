@import "../../styles/helper.scss";

.crop-image-on-upload-modal {
  max-width: fit-content !important;
  padding: 0 !important;
  border-radius: 15px;
  overflow: hidden;


  &__container{
    padding: 24px 24px 8px;
    border-bottom: 1px solid $n5;
    @include dark {
      border-bottom: 1px solid $n4;
    }
  }

  &__heading {
    font-size: 20px;
    font-weight: 600;
    @include dark {
      color: $white;
    }
  }
  &__description{
   font-size: 14px;
   font-weight: 400;
   color: $n63;
   @include dark {
    color: $n4;
  }
  }
  &__listStyle{
    padding: 8px 24px 16px
  }
  &__list{
    list-style: unset !important;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
    color: $n30;
    margin-bottom: 16px;
    @include dark {
      color: $n4;
    }
  }
  .Modal__wrapper {
    width: 100%;
    max-width: 100% !important;
    padding: 0;
    border-radius: 0;
  }

  .crop-image-container {
    overflow: hidden;
    position: relative;
    background-color: $n6 !important;

    @include dark {
      background-color: $n4 !important;
    }
  }

  .reactEasyCrop_CropArea{
    border: 1px solid #0061F5 !important;
    color:	rgba(217, 217, 217, 0.6) !important;
    @include dark {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }

  .reactEasyCrop_CropAreaGrid {
    &:before,
    &:after {
      border-color: $n5;
    }
  }

  .crop-controls {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-inline: 15px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 4px;
    background: $p1;
    width: 100%;
  }

  .zoom-range {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $p1;
      background: $p1;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .crop-controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .crop-controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.16);
  }

  .crop-image-action {
    padding: 16px;
    text-align: right;
    border-top: 1px solid $n5;

    .button {
      border-radius: 4px;
      font-weight: 500;
      width: 100px;
    }

    .crop-close-btn {
      color: $n37 !important;
      background-color: $n32 !important;

      &:hover {
        background: darken($n32, 8) !important;
      }
    }

    @include dark {
      border-top-color: $n4;

      .crop-close-btn {
        color: $white !important;
        background-color: $n4 !important;

        &:hover {
          background: darken($n4, 8) !important;
        }
      }
    }
  }
}
