@import "../../../../styles/helper.scss";


.linked-devices{
    width: 100%;
    margin-top: 30px;
    
    .device-wrapper{
        padding: 16px;
        border-bottom: 0.5px solid #CCD2E0;
            @include dark{
                border-bottom: 0.5px solid #36383D;
            }
        .devices{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .icons-arrow{
                color: #5D657A;
                font-size: 24px;
                
            }
            
        }
            .device-detail{
                display: flex;
                align-items: center;
                gap: 10px;
                .heading{
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600px;
                    color: black;
                    @include dark{
                        color: $white;
                    }
                }
                .para{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 500px;
                    color: #878C99;
                }
            }
    
        .logout-area{
            text-align: right;
            overflow: hidden;
            max-height: 0; 
            transition: max-height 0.5s ease-in-out;
        

            
            .logout-button{
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                padding: 8px 16px;
                background-color: rgba(0,0,0,.12);
                border-radius: 4px;   
                @include dark{
                    background-color: hsla(0,0%,100%,.12);
                    color:$white
                }
            }

        }
        .visibleBtn{
            max-height: 100px;
        }
    }
}

.empty-devices{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    .heading{
       color: black;
       font-weight: 600px;
       font-size: 20px;
       line-height: 28px;
       margin-top: 16px;
        @include dark{
            color: $white;
        }
    }
}