@import styles/helpers

.MpcSetupStatusModal
  height: 532px !important
  max-width: 768px !important
  border-radius: 8px
  display: flex
  justify-content: center
  align-items: center
  +dark
    background: $n21 !important    

.kycStatusModal
  max-width: 576px !important
  padding: 24px
  border-radius: 8px
  +dark
    background: $n21 !important    

.KYCReviewModal
  max-width: 768px !important
  padding: 24px
  border-radius: 8px
  +dark
    background: $n21 !important   