@import "../../styles/helper";

.report_btn{
.calender-btn{
  margin: 0;
  height: 40.5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer !important;
  @include dark {
      border: 1px solid rgba(255, 255, 255, 0.12);
  }
} 

  .date-range-picker{
    top: 130px !important;
  }
}

.disabled-button{
  opacity: 0.5 !important;
  cursor: not-allowed !important; 
  pointer-events: none !important;
}

.report_main_section{
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 16px;
}

.report_chart_container{
    position: sticky;
    top: 0;
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 16px;
    border-radius: 4px;
    z-index: 1;
    background-color: rgb(245, 245, 245);
    @include dark {
      background-color: #1D1E20;
    }

    justify-content: space-between;

    .reports_charts_data{
      flex-grow: 1;
      border-right: 1px solid rgba(black, 0.12);

      @include dark{
        border-right: 1px solid rgba(#ffffff, 0.12);
      }

      &:last-child{
        border-right-color: transparent !important;
      }

        small{
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: $n4;
          @include dark {
            color: $n9;
          }
        }

        p{
          font-size: 24px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: $n21;

          @include dark{
            color: $n64;
          }
        }

        .profit-negative{
          color: $n77 !important;
        }

        .profit-positive{
          color: #31C46C !important;
        }
      }
}