@import "../../../../styles/helper";

.mpc_setup_container {
    .header{
        padding: 12px;
    }
    .brand_logo {
        width: 210px;
        height: 100%;
    }
    .body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 540px;
        gap: 24px;
        margin-inline: auto;
        .heading {
            font-size: 24px;
            font-weight: 600;
        }
        .para{
            font-size: 16px;
            font-weight: 500;
            color: $n3;
            @include dark{
                color: $n5;
            }
        }
        .checkPoints{
            margin-top: 8px;
            display: flex;
            gap: 10px;
            align-items: flex-start;
        }
        .checkPoints-content{
            flex: 1 1;
        }
        .subHeading{
            font-size: 14px;
            font-weight: 500;
            color: $n3;
            @include dark{
                color: $n5;
            }
        }
        .iconWrapper{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $n73;
        }
        .icon{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
        }
        .checkPoint-heading{
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 4px;
        }
        .checkPoint-para{
            font-size: 14px;
            font-weight: 400;
            color: $n3;
            @include dark{
                color: $n5;
            }
        }
        .button {
            border-radius: 4px;
            height: 40px;
            width: 540px;
            font-weight: 500;
            font-size: 14px;
            padding-inline: 10px;
            margin-bottom: 10px;
        }
        .btn-outline-blue{
            background-color: rgba(0, 97, 245, 0.06)!important;
        }
        .qr_code{
            width: 180px;
            height: 180px;
        }
        .qr-wrapper{
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $n64;
            border-radius: 8px;
            @include dark{
                background-color: $n17;
            }
        }
    }
}
