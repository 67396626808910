@import "../../../../../../styles/helper.scss";

.wire-transfer-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  gap: 16px;
  padding: 24px;
  &__close-button{
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    color: #7A7A7A;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 4px;
    line-height: 1;
    @include dark{
      color: #999999;
    }
  }
  .transfer-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;

    .transfer-details {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      gap: 8px;
      &__copied{
        color: $n73 !important;
        margin-right: 4px;
      }
      &__copy{
        color: $n74;
        cursor: pointer;
      }
      &__copy-btn{
        background-color: $n74;
        border: none;
        color: #F5F5F5;
        padding: 13px 24px;
        border-radius: 5px;
        cursor: pointer;
        margin: 10px auto;
        display: block;
        text-align: center;
        font-size: 14px;
        transition: background-color 0.3s;
        font-weight: 500;
        line-height: 24px;
        @include dark {
          background-color: $n71;
        }

        &:hover {
          background-color: $n28;
            @include dark {
              background-color: $n35;
            }
        }
      }

      &__border-bottom{
        border-top: 1px solid #0000001f;
        margin-bottom: 8px;
        width: 75%;
        @include dark{
          border: .5px solid #36383d;
        }
      }
      &__copied-all{
        display: flex;
        align-items: center;
        justify-content: center;
        }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #1F1F1F;
        @include dark{
          color: #F5F5F5;
        }
      }

      .heading {
        text-align: right;
        color: #5C5C5C;
        width: 100%;
        @include dark {
          color: #B8B8B8;
        }
      }

      .details {
        text-align: left;
        width: 100%;
      }
      .skeleton_container{
        width: 100% !important;
      }
    }
  }
  .transfer-detail-note {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid #f5af45;
    padding: 12px 16px;
    border-radius: 4px;

    i.ri {
      font-size: 16px;
      color: #f5af45;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #bcc3d6;
    }
  }
}

.copy-alert {
  display: flex;
  gap: 6px;
  align-items: center;
  position: absolute;
  bottom: 570px;
  background: $white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000; 
  opacity: 0.9; 
  i{
    color: $n73;
  }
  @include dark {
    background-color: $n19;
    color: $white;
  }
}

