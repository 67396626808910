@import "../../../../styles/helper";

.portfolio-assets-container {
  margin-bottom: 20px;
  // height: 800px;
  // overflow-y: auto;

  .no-data-image {
    display: block;
    margin: 100px auto 0;
    width: 150px;
  }
  .no-data-msg {
    display: flex;
    margin: 10px auto 0;
    align-items: center;
    justify-content: center;
    color: $n4;
    font-size: 16px;
  }

  .portfolio-asset {
    padding: 8px 15px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 8px;
    border-radius: 8px;
    background-color: $white;
    border: 1px solid $n6;
    @include dark {
      background-color: $n19;
      border: 1px solid $n12;
      border-bottom-color: transparent !important
    }
    &:hover{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: .4s all ease-in-out;
    }
  
    &:last-child {
      border-bottom: 0;
    }
  }

  .portfolio-asset-label {
    font-size: 14px;
    font-weight: 500;
    color: $n11;

    &_value {
      color: $n3;
    }

    &_value_white {
      color: black;
      font-size: 16px;
    }

    .positive {
      color: $n13;
    }

    .negative {
      color: $p3;
    }

    .neutral {
      color: $n16;
    }
  }

  .portfolio-asset-prices {
    text-align: right;

    .portfolio-asset-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .portfolio-asset-details {
    display: flex;
    padding-block: 5px;
    align-items: center;

    .logo,
    .logo-name {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .logo-name {
      text-transform: capitalize;
      display: grid;
      justify-content: center;
      align-items: center;
      background-color: $n6;
      font-weight: 600;
    }

    .portfolio-asset-symbol {
      font-weight: 600;
      line-height: normal;
    }

    .portfolio-asset-name {
      font-size: 13px;
      font-weight: 500;
      color: $n11;
    }
  }

  .portfolio-asset-types {
    display: flex;
    gap: 4px;
    justify-content: flex-end;

    span {
      text-transform: capitalize;
      color: $n11;
      background-color: $n6;
      padding: 4px 8px;
      border-radius: 4px;
      @include dark{
        background-color: #6C707A;
        color: $white;
      }
    }
  }

  @include dark {
    .portfolio-asset {
      border-bottom-color: $n4;
    }

    .portfolio-asset-label {
      &_value {
        color: $n16;
      }
      &_value_white {
        color: $white;
      }
    }

    .portfolio-asset-details {
      .logo-name {
        background-color: $n19;
      }
    }
  }

  .order-lockup{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    flex-direction: row nowrap;
    align-items: center;
    color: $n15;
    margin: 0;
   
    .lockInCount {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 8px;
      gap: 4px;
      
      .countCircle {
        width: 16px !important;
     
        .CircularProgressbar-path {
          stroke: #58BD7D;
          stroke-linecap: butt;
        }
      }
  
      .timeColor{
        line-height: 16px;
      }
    }
  }
}

