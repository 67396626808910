@import styles/helpers

.wrapper
    width: 100%
    height: 100vh
    place-items: center
    display: grid
    background: $dark

.inner_wrapper
    width: 450px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    +s
        width: 375px

.inner
   display: flex
   flex-direction: column
   align-items: center
   gap: 16px


.title   
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: $white

.sub_title
     font-weight: 400
     font-size: 14px
     line-height: 20px
     color: $white
     text-align: center
    
.render_image
    width: 150px
    height: 160px


.back_btn
    display: flex
    gap: 8px
    width: 40%
    align-items: center
    background: gray
    border-radius: 8px
    padding: 16px
    text-align: center
    height: 40px
    align-self: center
    cursor: pointer
    color: $n13
    &:hover
       background: rgba(245, 175, 69, 0.06)
       color: $s5
.method_title
   font-weight: 600
   font-size: 16px
   line-height: 24px
   color: $n14
   flex: 1