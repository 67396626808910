@import "../../../../../../styles/helper";

.react-switch-handle-checked .react-switch-handle {
  background-color: var(--color-bg-100-mediumlight) !important;
}

.react-switch-handle-unchecked .react-switch-handle {
  background-color: var(--color-primary-medium) !important;
}

.asset-lock-in-time-container {
  margin-block: 16px;

  .asset-lock-in-des {
    color: $n9;
    text-align: center;
  }

  .asset-lock-in-count {
    font-size: 14px;
    font-weight: 500;
    color: $n4;
    display: flex;
    align-items: center;
    line-height: normal;
    min-width: 140px;
    gap: 16px;
    margin-top: 16px;

    & > span {
      font-weight: 500;
      font-size: 14px;
      display: inline-flex;
    }

    .time-red {
      color: $p3;
    }

    .time-color {
      font-size: 18px;
      color: black;
    }

    @include dark {
      color: $n5;

      .time-color {
        color: $white;
      }
    }

    .count-circle {
      width: 60px;

      .CircularProgressbar-path {
        stroke: $p4;
        stroke-linecap: butt;
      }

      &.time-red {
        .CircularProgressbar-path {
          stroke: $p3;
        }
      }

      &.time-over {
        .CircularProgressbar-trail {
          stroke: $p3;
        }
      }
    }
  }

}
