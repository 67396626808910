@import "../../../styles/helper.scss";
.chat-list {
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  height: 360px;


  .empty-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    margin-top: 30%;
  
    &__icon {
      color: $n63;
      @include dark {
        color: $n68;
      }
    }
  
    &__heading {
      font-size: 16px;
      font-weight: 600;
      color: $dark;
      @include dark {
        color: $n64;
      }
    }
  
    &__subheading {
      font-size: 14px;
      font-weight: 500;
      color: $n30;
      @include dark {
        color: $n9;
      }
    }
  }
  
  .chat-item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
    border-bottom: 1px solid #0000001F;
    @include dark {
      border-bottom: 1px solid #ffffff1f;
    }

    .item-image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 8px;
      border: 1px solid #0000001f;
      @include dark {
        border-bottom: 1px solid #ffffff1f;
      }
    }

    .item-name {
      flex-grow: 1;
      font-size: 16px;
      font-weight: 500;
    }

    .item-count {
      background: $n74;
      color: $white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex; 
      align-items: center; 
      justify-content: center; 
      font-size: 12px;  
      line-height: 20px; 
  }
  }
}

.chatAssetName{
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  height: 48px;
  margin-right: 12px;
  width: 48px;
  color: #1D1E21;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #D6D6D6;
  @include dark {
    color: #FFFFFF;
    background: #3D3D3D;
  }
}
    