@import "../../../../styles/helper";

.auction-bid-list-container {
  background-color: $n7;
  padding-top: 6px;
  overflow-y: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;

  .auction-bid-table {
    display: flex;
  }

  .auction-product-name {
    width: 23ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .auction-bid-list-tabs {
    display: flex;
    border-bottom: 1px solid $n6;
    gap: 10px;
    background-color: $white;

    &_item {
      font-size: 14px;
      font-weight: 500;
      color: $n4;
      cursor: pointer;
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      margin-bottom: -1px;
      text-transform: capitalize;


      &.active {
        color: $n10;
        border-bottom-color: $n10;
      }
    }
  }

  .auction-bid-buy-tab {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1 1;

    p {
      margin-top: 15px;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .auction__table-product {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    img {
      width: 40px;
      height: 40px;
      border-radius: 24px;
      margin-right: 8px;
      object-fit: fill;
      background-position: center;
    }

    .auction__table-product-desc {
      display: flex;
      flex-flow: column;
      gap: 4px;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: $n4;
        text-transform: capitalize;

        @include dark {
          color: $white;
        }
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        text-transform: capitalize;
        color: black;

        &::first-letter {
          text-transform: capitalize;
        }

        @include dark {
          color: $white;
        }
      }
    }
  }

  .react-table-responsive-wrapper {
    padding-inline: 10px;
    background-color: $white;
    height: auto !important;

    tr {
      border-bottom: 1px solid $n6 !important;
    }

    th {
      color: $n4;
    }

    th, td {
      background-color: $white;
    }
  }

  .auction-status {
    text-transform: capitalize;

    i {
      padding: 4px;
      border-radius: 50%;
      display: inline-flex;
      margin-right: 5px;
    }
  }

  .auction-button-groups {
    display: flex;
    gap: 8px;

    .button {
      font-weight: 500;
      font-size: 12px;
      width: 80px;
    }
  }

  .pagination-exchange {
    padding: 10px 15px;
  }


  @include dark {
    background-color: black;

    .auction-bid-list-tabs {
      border-bottom-color: $n4;
      background-color: $dark;
    }

    .react-table-responsive-wrapper {
      padding-inline: 10px;
      background-color: $dark;

      tr {
        border-bottom-color: $n4 !important;
      }

      th, td {
        background-color: $dark;
      }
    }

    .auction__table-product-desc {
      p {
        color: $white;
      }
    }

    .btn-light-grey {
      background-color: $n12 !important;
      color: $white !important;
    }
  }

}
