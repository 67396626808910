@import "../../../../styles/helper.scss";

.transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-left {
    display: flex;
    gap: 15.64px;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid rgba(108, 112, 122, 1);
      i {
        font-size: 24px;
        color: rgba(0, 0, 0, 1);
        @include dark {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
    }
  }
}
