@import "../../../../styles/helper";

.update-email-address-modal {
  max-width: 500px !important;

  .Modal__wrapper {
    padding-inline: 12px;
    padding-block: 0;

    .Modal__closeIcon {
      font-size: 24px;
    }
  }

  .input__label {
    @include dark {
      color: $n5;
    }
  }

  .Modal_title {
    font-size: 20px;
    text-align: left;
  }

  .AuthLayout-Card {
    padding-inline: 0;
    padding-bottom: 20px;
  }

  .signup-row-2 {
    width: 100%;
  }

  .compliance-btn-groups {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    border-top: 1px solid $n6;
    margin-inline: -22px;
    padding-inline: 22px;
    padding-top: 10px;

    .btn-cancel {
      width: 120px;
      padding-block: 12px;
      background-color: $n5;
      margin-bottom: 0;

      @include dark {
        background-color: $n37;
      }
    }

    .btn-save-verify {
      width: 160px;
      padding-block: 12px;
      margin-bottom: 0;
    }

    @include dark {
      border-top-color: $n3;
    }
  }
}

.accreditation-upgrade-modal {
  min-width: 700px;
  .Modal__wrapper {
    padding-inline: 12px;
    padding-block: 0;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px !important;

    .Modal__closeButton {
      top: -10px;
      right: 0;
    }

    .Modal__closeIcon {
      font-size: 24px;
      color: $n4 !important;
    }
  }
  .Modal_title {
    display: none;
  }

  .accreditation-btn {
    width: 300px;
  }

  .accreditation-iframe {
    height: 90vh;
    width: 80vw;
    margin: -10px -22px;
    background-color: $white;
  }

  .accreditation-iframe__loading {
    height: 90vh;
    width: 80vw;
    margin: -10px -22px;
    background-color: $white;
    display: grid;
    place-items: center;
  }
}
