@import "../../../../styles/helper";

.auction-details-container {
  padding: 10px;
  flex: 1 1;
  &.sba-details-container{
    .data-room-item {
      grid-template-columns: 50px auto 100px;
    }

    .auction-details-heading, .bid-sheet__heading {
      font-family: Ivar Display, serif !important;
      line-height: 1.1;
      font-size: 20px;
    }

    .line-tabs {
      margin-top: 0;
    }

    .bid-sheet__heading {

    }
   }
  .auction-details-heading {
    color: $s5;
    font-size: 18px;
    padding: 15px;
    font-weight: 600;
    background-color: $white;

    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 4px;
      background-color: $s5;
      border-radius: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }

    @include dark {
      background-color: $dark;
    }
  }
}
