@import ../../../styles/helpers

.header 
  min-height: 64px
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #E6E8EC 
  z-index: 10
  padding: 0 24px
  background: #FFFFFF
  +dark
    background: $n17
    border-bottom: 1px solid #43464d

.titleAndNav
  display: flex
  align-items: center



.headerName
  font-family: Poppins
  font-size: 16px
  font-weight: 600
  line-height: 24px
  +dark
    color: #E6EAF5

.user_container
    display: flex
    align-items: center
    gap: 10px  

.headerBell
   font-size: 19px
   line-height: 1
   +dark
      color:#747E99

  

.theme
    margin-right: 20px

.adminFooter
    position: relative
    width:100%
    height: 80px
    background: #FFFFFF
    padding: 16px 24px
    border-top: 1px solid #E6E8EC
    z-index: 100
    +dark
      background: $n17
      border-top: 1px solid #43464d


   
.backbtn
    background: #EBEEF5
    padding: 12px 12px 
    border-radius: 8px
    float: right
    font-size: 14px
    font-weight: 500
    line-height: 20px
    +dark
        background: #FFFFFF08
        color: #BCC3D6

.notificationIconContainer
    position: relative
    cursor: pointer
    &__dot
        height: 10px
        width: 10px
        border-radius: 24px
        background: $n13
        position: absolute
        left: 22px
        top: 0px
.notification_icon
    position: relative
    margin-right: 20px
    fill: $n2
    +dark
        fill: $n4
