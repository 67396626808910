@import "../../../../styles/helper";

.wallet-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  gap: 20px;
  background-color: $white;
  border: 1px solid $n6;

  @include dark {
    background-color: $n19;
    border-color: $n12;
  }

  .wallet-balance-cash-container{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }

  .wallet-balance-header {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $n4;

    @include dark {
      color: $n5;
    }
  }

  .wallet-processing-balance {
    font-size: 14px;
    width: 100%;
    margin-top: -10px;

    .processing-label {
      color: $n4;
      width: calc(50% + 15px);
      display: inline-block;
      text-align: right;

      &:after {
        content: ":";
        display: inline-block;
        padding-inline: 15px;
        font-weight: 600;
      }
    }

    .processing-amount {
      font-weight: 600;
    }

    @include dark {
      .processing-label {
        color: $n5;
      }
    }
  }



  .wallet-balance-body {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .wallet-balance-main {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }

  .wallet-balance-btn-container {
    display: flex;
    gap: 8px;

    .btn-withdraw {
      background: rgba(0, 0, 0, 0.12);
      color: $dark;
      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
      @include dark {
        background: rgba(255, 255, 255, 0.12);
        color: #e6eaf5;
        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }

    button {
      border-radius: 4px;
      font-weight: 500;
      width: 125px;
    }
  }
}
