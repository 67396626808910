@import "../../../../styles/helper";

.switch-account-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 480px;
    gap: 16px;
    margin-inline: auto;
    background: transparent;
    .heading {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 10px;
    }

    .para {
      font-size: 16px;
      font-weight: 500;
      color: $n3;
      @include dark {
        color: $n5;
      }
    }
  }

  .switch-account-list {
    display: flex;
    padding-right: 6px;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
    color: $white;
    &::-webkit-scrollbar-thumb {
      background-color: #363a45;
      border-radius: 4px;
    }
  }

  .user-details-container {
    display: flex;
    border: 1px solid $n6;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    background-color: $n17 !important;

    .user-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .user-details {
      padding-left: 10px;
      line-height: 24px;
      flex: 1 1;
      max-width: calc(100% - 64px);
    }

    .user-details-name {
      font-size: 16px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .custom-tooltip{
      max-width: 300px;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .user-details-acc-id {
      font-size: 14px;
      font-weight: 500;
      color: $n9;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .switch-tag {
      color: $n9;
      font-size: 24px;
      align-self: center;
    }

    @include dark {
      border-color: $n3;
    }

  }
}
