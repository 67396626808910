@import "../../styles/helper.scss";

.auction-container {
  padding: 5px;
  background-color: $n7;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;

  .bid-card-container {
    margin-bottom: 3px;
  }

  .auction-heading {
    font-size: 24px;
    font-weight: 600;
    color: black;

  }

  .pagination-exchange {
    position: fixed;
    bottom: 0;
    left: 5px;
    right: 5px;
    padding: 10px 15px;
  }

  &.user-wishlist {
    .pagination-exchange {
      position: static;
      padding: 10px 15px;
    }
  }

  @include dark {
    background-color: black;

    .auction-heading {
      color: $white;
    }
  }

  .no-data-found {
    flex: 1 1;
  }

}

.auction-filter-container {
  padding: 8px 15px;
  background-color: $white;
  display: grid;
  grid-template-columns: 6fr 4fr auto;
  gap: 15px;
  align-items: center;

  .input__text-fields {
    background-color: transparent;
    border-color: $n6;
  }

  .button {
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;

    .ri-filter-3-line {
      font-size: 18px;
    }
  }

  .input__group {
    height: 45px;
  }

  .input__view {
    font-size: 16px;
  }

  @include dark {
    background-color: $dark;
    .input__text-fields {
      border-color: $n18;

      .input__fields {
        color: $white;
      }
    }

    .input__view:hover {
      color: $n18;
    }

    .button {
      background-color: $n12;
      color: $white;

      &:hover {
        background-color: darken($n12, 8);
      }
    }
  }
}
