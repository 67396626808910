@import "../../../../styles/helper";

.main {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;
    height: 18px;
    width: 18px;
}

.main input[type=checkbox] {
    visibility: hidden;
}

.checkbox-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $n19;
    border: 2px solid $n43;
    &.checkbox-error{
        border-color: var(--color-error);
    }
}


/* Checkmark */
.checkbox-container::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid $n28;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Display checkmark when checked */
.main input:checked~.checkbox-container::after {
    display: block;
}