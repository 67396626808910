@import styles/helpers

.head
    display: flex
    align-items: center
    margin-bottom: 16px
    +t
        padding: 0 16px

.title
    margin-right: auto
    +body-bold-2
    color: $n4

.close
    svg
        fill: $n4

.row
    display: flex
    +m
        display: block
        margin: 0

.col
    flex: 0 0 calc(100% - 32px)
    width: calc(50% - 32px)
    margin: 0 16px
    display: flex
    flex-direction: column
    gap: 8px
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 24px
.colModal
    padding: 0 8px
.buttonContainer
    width: 100%
    display: flex
    padding: 8px 16px 8px 16px

    .button
        width: 100%
        color: #777E91
        border: 1px solid $n6
        border-radius: 4px 
        font-weight: 500
        font-size: 14px
        line-height: 20px
        height: 28px
        text-align: center
        +poppins
        background: rgba(255, 255, 255, 0.03)
        +dark
            border-color: $n3
       
        &.active
            &:first-child
                background: $p4
                color: $white
            &:nth-child(2)
                background: $p3
                color: $white
            



