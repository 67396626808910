@import "../../../../styles/helper";

.mobile-link {
  flex: 1 1;
  background-color: black;

  .mobile-verification__error,
  .mobile-verification__success {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .mobile-verification__success-message {
      font-size: 18px;
      color: $white;
    }
  }

  .mobile-approve {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &__container {
      display: flex;
      width: 350px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    &__header {
      color: $white;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }

    img {
      height: 144px;
      object-fit: contain;
      width: 144px;
    }

    &__description {
      color: var(--color-text-60-light);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      margin-top: 15px;

      button {
        background: rgba(51, 184, 122, 0.06);
        border-radius: 8px;
        padding: 16px;
        cursor: pointer;
        color: #33b87a;
        width: 100%;
      }
    }
  }
}
