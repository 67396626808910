@import ../../styles/helpers
.date_range_picker_dark 
    display: flex
    gap: calc(var(--base-margin) * 0.25)
    width: fit-content
    padding: calc(var(--base-padding) * 0.5)
    border-radius: calc(var(--base-border-radius) * 0.75)
    background-color: var(--color-bg-100-light)
    box-shadow: 0px 4px 60px rgb(0 0 0 / 10%)
    position: absolute
    top: 116px
    z-index: 4
    min-width: 450px
    +dark 
        background: black
        border-radius: calc(var(--base-border-radius) * 0.75)
.overlay_container_dark
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    gap: calc(var(--base-margin) * 0.25)
    padding-bottom: calc(var(--base-padding))
    padding-top: calc(var(--base-padding) * 0.75)
    +dark
        background-color: black
        

    .rdrDefinedRangesWrapper
        font-size: 12px
        width: 226px
        border-right: solid 1px #eff2f7
        background: #fff
        +dark
            background: black
            border-radius: 8px
            color: white
