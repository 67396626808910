@import "../../../../styles/helper.scss";

.hubSpot-modal{
    max-width: 800px !important;
    min-height: 500px;
    &__loader{
        display: grid;
        place-items: center;
        width: 732px;
        min-height: 630px;
    }
    &__container{
        width: 732px;
        min-height: 630px;
    }

 &.exchange-modal-wrapper .Modal__wrapper{
    padding-bottom: 12px !important;
    @include dark{
        background-color: #fff !important;
      }
 }
&.exchange-modal-wrapper.ReactModal__Content{
    @include dark{
        background-color: #fff !important;
      }
}
}

