@import "../../../../../../styles/helper";

.kyc-review-modal {
  max-width: 768px !important;
  border-radius: 0;

  .Modal__wrapper {
    padding-top: 10px;
  }

  .Modal__closeIcon {
    font-size: 24px;
  }

  @include dark {
    background-color: $n21 !important;

    .Modal__wrapper {
      background-color: $n21 !important;
    }
  }
}

.lockuptitle {
  padding: 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(226, 122, 19, 1);
  margin: 12px 24px;
  .lockupAlert {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    gap: 8px;

    .ri {
      font-size: 20px;
      color: #E27A13;
      margin-top: -5px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
   
      color: black;
        @include dark{
        color: #D6D6D6;
      }
    }
  }
}