@import "../../styles/helper.scss";
.country-label {
  width: 200px;
}
.country-code-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  height: 50px;
  @include dark {
    background-color: #282A2E;
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $n19;
    font-size: 22px;
    flex: 1;
    transition: transform 0.3s ease-in-out;
    @include dark {
      color: $white;
    }
  }
}

.input-error-background{
  background-color: hsl(0deg 88% 67% / 6%) !important
}

.country-input {
  position: absolute;
  background-color: transparent;
  width: 95px;
  height: 45px;
  color: $n19;
  padding-left: 10px;
  margin: 4px;
  @include dark {
    color: $white;
  }
}

.select-box {
  height: 50px;
  min-width: 140px;
  border-radius: 5px;
  display: flex;
  padding: 8px 1px;
  color: $n19;
}

.select-box.active {
  //outline: 1px solid #2684ff;
}

.option-container {
  z-index: 999;
  position: absolute;
  width: 140px;
  max-height: 300px;
  height: fit-content;
  border-radius: 5px;
  margin-top: 62px;
  overflow-y: scroll;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.5px solid $n74;
  box-shadow: 0px 0px 8px 0px hsla(0, 0%, 0%, 0.24);
  @include dark {
    background: $n19 !important;
  }
}

.option {
  cursor: pointer;
  border-bottom: 1px solid #36383d;
  padding: 10px;
  min-width: 130px;
  color: $n19;

  &:last-child {
    border-bottom: none;
  }
}

.option:hover {
  cursor: pointer;
  background-color: #3c65d6;
}

.selected{
  cursor: pointer;
  background-color: #3c65d6;
}

.option-selected {
  cursor: pointer;
  border-right: 0.5px solid $n3;
  display: flex;
  align-items: center;
  color: $n19;

  padding: 10px;
  min-width: 102px;

  &:last-child {
    border-bottom: none;
  }

  @include dark {
    border-right: 0.5px solid $n3;
    color: $white;
  }
}

.option-content {
  display: flex;
  align-items: center;
}

.option__flag {
  width: 32px;
  height: 24px;
  margin-right: 8px;
}

.option__label {
  color: $n19;
  @include dark {
    color: $white;
  }
}

.no-option {
  font-size: 14px;
  width: 117px;
  text-align: center;
}

.rotate180 {
  transform: rotate(180deg);
}
