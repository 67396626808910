@import "../../styles/helper";

.orders-container{
  padding-inline:20% ;
  padding-top: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;
    .orders-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .heading {
        font-size: 24px;
        font-weight: 600;
        margin-block: 15px;
        color:$n2;
        @include dark {
          color: $white;
        }
      }

    .order-filter{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 44px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid $n16;
        gap: 8px;
        cursor: pointer;
        &:hover{
            background: $n4;
            transition: 0.3s;
        }
        @include dark{
            color: $n16;
            background-color: $n50;
            &:hover{
                background: $n12;
                transition: 0.3s;
            }
        }

    } 
    .orders-tabs {
      display: flex;
      border-bottom: 1px solid $n49;
      gap: 10px;
  
      &_item {
        font-size: 14px;
        font-weight: 600;
        color: $n4;
        cursor: pointer;
        padding: 10px 15px;
        border-bottom: 2px solid transparent;
        margin-bottom: -1px;
        text-transform: capitalize;
        &.active {
          color: $blue;
          border-bottom-color: $blue;
          z-index: 100;
          @include dark{
            border-bottom-color: $n10;
            color: $n10;
          }
        }
        &:hover{
          color: $blue;
          border-bottom-color: $blue;
          transition: 0.4s all ease-in-out;
          @include dark{
            border-bottom-color: $n10;
            color: $n10;
          }
        }
      }
  
      @include dark {
        border-bottom-color: $n51;
      }
    } 
    
}

   
    