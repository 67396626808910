// overriding css in api-designer webcomponent

@import "../../styles/helper.scss";

#api-designer-root {
  top: 62px !important;
  position: "relative";
  left: 250px;
  width: calc(100% - 250px);
  width: 100%;
  height: calc(100vh - 64px);
}

.apidesignerContainer {
  overflow: auto;
  flex: 1 1;
  background: white;
  @include dark {
    background: #100c27;
  }

  &.collapsed #api-designer-root {
    left: 70px;
  }
}

.apidesignerbody {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.apidesigner {
  height: 10vh;
}

.api-designer-describe {
  width: 100%;
  padding-top: 20px;
}

.upload-screen {
  height: auto;
  padding-top: 20px;
}

.api-designer-header-navigator {
  padding-block: 16px;
  padding-inline: 15px;
  background-color: var(--adf-color-nav-bg-90-dark);
  margin-left: 10px;

  .nav {
    position: relative;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #747e99;
    display: inline-block;
    margin-right: 20px;

    &.active {
      color: #3772ff;
    }

    &.active:after {
      color: #747e99;
    }
  }

  .nav:hover {
    color: #3772ff;
    cursor: pointer;
  }

  .nav:not(:last-child):after {
    content: "|";
    display: inline-block;
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    transform: translate(-50%, 0);
  }

  .nav:hover:not(:last-child):after {
    color: #747e99;
    cursor: default;
  }
}

.empty-screen {
  inset: unset !important;
  height: calc(100vh - 64px) !important;
  justify-content: center !important;
  position: static !important;
}

.api-settings__input-wrapper {
  height: calc(100vh - 296px) !important;
}

.resource-config__inner__tab-body {
  height: calc(100vh - 290px) !important;
}
