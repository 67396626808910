@import "../../../../styles/helper";

.filter__container_sba {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
  align-items: end;
  .selected-btn {
    display: flex;
    gap: 8px;
    button {
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
    }
    &__select-count {
      width: 100px;
      background: #00000007;
      color: var(--color-primary-70);
    }
    &__delet {
      width: 140px;
      background: #f5535310;
      color: #f55353;
    }
  }
  .group-bulk {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    margin-top: 16px;
  }
  &-search {
    display: flex;
    flex: 402px 0 0;
    flex-flow: row nowrap;
    gap: 10px;
    .filter-container {
      height: auto;
    }
    .input__group {
      height: 44px;
    }
  }

  @include dark {
    .input__text-fields {
      border-color: $n18;
      background-color: transparent;
      &:hover {
        border: inherits;
      }
    }

    .btn-grey {
      background-color: $n12 !important;
      color: $white !important;

      &:hover {
        background-color: darken($n12, 8) !important;
      }
    }

    .input__fields {
      color: $white;
      caret-color: $white;
    }
  }

  &-create {
    .asset-btn {
      border-radius: 8px;
    }
  }
}
