@import "../../../../../../styles/helper.scss";
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  @include dark {
    color: $n64;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.modal {
  background-color: #fff;
  border-radius: 12px;
  padding: 74px 24px;
  width: 100%;
  max-width: 768px;
  position: relative;
  color: $n78;
  @include dark {
    color: $n64;
    background-color: #1d1e20;
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 12px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 24px;
    padding: 4px;
    @include dark{
      color: #999999;
    }

    &:hover {
      color: #999;
    }
  }

  &__icon {
    width: 100%;
    height: 120px;
    margin-bottom: 16px;
  }

  &__content {
    text-align: center;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__amount {
    color: #29A359;
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
  }

  &__confirmation {
    color: #3d3d3d;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 24px;
    @include dark {
      color: #D6D6D6;
    }
  }

  &__note {
    background-color: #00000008;
    max-width: 598px;
    margin: auto;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 12px;
    @include dark {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &-icon {
      color: #ed8927;
    }

    &-text {
      color: #3D3D3D;
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      @include dark{
        color: #D6D6D6;
      }
    }
  }

  &__button {
    height: 40px;
    color: rgb(23, 24, 27);
    background: #f5f5f5;
    padding: 10px 24px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    @include dark {
      color: #d6d6d6;
      background: #3a3b41;
    }

    &:hover {
      background-color: #e9e6e6;
      @include dark {
        background: #2b2c2f;
      }
    }
  }
}
