@import "../../../../styles/helper";

.signin-input-wrapper{
  .country-code-container{
    background: #FFFFFF10;

    .arrow{
      color: $white;
    }
  }

  .country-code-container > .option-container{
      //  background: $n19 !important;
       color: white;
    }

  .option-selected {
    border-right: 0.5px solid $n3;
  }

  .option-content > span{
    color: white !important;
  }

  .input__error {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $n5;
  }
}

.login-divider {
  text-align: center;
  border-bottom: 1px solid $n2;
  width: 100%;
  line-height: 0.1rem;
  span {
    padding: 0px 20px;
    background: black;
    font-size: 16px;
    color: #a2a7b8;
    font-weight: 500;
  }
}
.header_nav {
  ul{
    display: flex;
    list-style: none;
    gap: 15px;
    li > span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
        color: #1472FF;
      cursor: pointer;
        &:hover{
        color: #7398fc;
        transition: 0.4s all ease-in-out;
      }
    }
  }
 
}
.bannerImage {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
  padding: 0;
}


.exchange-modal-wrapper.signin-modal-container-wrapper {
  width: 768px;
  border-radius: 8px;

  .Modal__wrapper {
    padding: 0;
  }

  .country-code-container {
    .arrow {
      color: black;
      @include dark {
        color: $white;
      }
    }
  }

  .country-code-container > .option-container {
    color: black;
    @include dark {
      color: $white;
    }
  }

  .option-selected {
    border-right-color: $n4;
  }

  .option-content {

    & > span {
      color: black !important;
      @include dark {
        color: $white !important;
      }
    }
  }

  .option-container {
    max-height: 250px;
    .option {
      border-bottom-color: $n5;
      &:hover {
        background-color: $p1_12
      }
    }
    @include dark {
      .option {
        border-bottom-color: $n3;
      }
    }
  }


  .checkbox-container {
    border-color: $n5;
    background: $n6;

    &.checkbox-error {
      border-color: var(--color-error) !important;
    }

    @include dark {
      border-color: #FFFFFF1F;
      background: #FFFFFF0F;
    }
  }

  .login-divider {
    width: 480px;
    border-bottom-color: $n5;
    span {
      background: white;
      color: $n4;
    }

    @include dark {
      border-bottom-color: $n3;
      span {
        background: $n21;
        color: #a2a7b8;
      }
    }
  }

  .input__error {
    color: $n4;
    @include dark {
      color: $n5;
    }
  }

  .social-login-options-wrapper {
    width: 480px;
  }
}
