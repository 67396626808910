@import styles/helpers

.actions
    height: 100%
    background: $n8
    border: 1px solid $n6
    border-radius: 8px
    +dark
        background: $dark
        border-color: $n2

.disabled
    pointer-events: none
    opacity: 0.6

.head
    display: flex
    align-items: center
    +t
        margin-bottom: 16px

.info
    +caption-2
    font-weight: 500
    color: $n4
    +m
        display: none
    a
        margin-left: 8px
        color: $n2
        transition: color .2s
        svg
            transition: fill .2s
        +dark
            color: $n6
            svg
                fill: $n6
        &:hover
            color: $p1
            svg
                fill: $p1
.nav
    width: 100%
    display: flex
    overflow-x: scroll
    border-bottom: 1px solid $n6
    &::-webkit-scrollbar
        display: none
    +dark
        border-bottom: 1px solid $n2

.navbar
    width: 100%
    padding: 16px 24px
    font-weight: 600
    font-size: 14px
    line-height: 20px
    border-bottom: 1px solid $n6
    margin-bottom: 16px
    +dark
        border-bottom: 1px solid $n2

.link
    display: flex
    padding: 10px 16px
    background: none
    text-transform: capitalize
    min-width: max-content
    line-height: 1.6
    +poppins
    font-weight: 500
    font-size: 12px
    color: #777E91
    transition: all .2s
    border-bottom: 1px solid transparent
    &:hover
        color: $n10
        +dark
            color: $s3
    &.active
        color: $n10
        border-bottom: 1px solid $n10
        +dark
            color: $s3
            border-bottom: 1px solid $s3

.btns
    display: none

    .button
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px
