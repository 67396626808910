@import "../../styles/helper.scss";

.react-table-responsive-container {
  height: 100%;
  width: 100%;
  flex: 1 1;
  overflow-y: auto;
  .collapsible_row {
    padding: 0;
  }
  .disabled-column{
    cursor: not-allowed;
    pointer-events: none;
    background-color: $n7;
    color: $n4;
    @include dark{
      background-color: $n36;
    }
  }
  .disabled-row{
    cursor: not-allowed;
    pointer-events: none;
    
    td{
      background-color: $n7;
      color: $n4;
      @include dark{
        background-color: $n36;
      }
    }
  }
}



.table-responsive-actions {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: var(--font-size-5);
    color: var(--adf-color-text-90-light);
    font-weight: 500;
  }
  &__btns {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    justify-content: flex-end;
  }
}

.table-visible-scroll {
  direction: rtl;

  &::-webkit-scrollbar {
      width: 20px;
      height: 20px;
    }
  
  &::-webkit-scrollbar-track {
    background: $n6; 
    @include dark{
      background: $n3;
    }
  }
  
  &::-webkit-scrollbar-thumb {
    background: $n4;
    border-radius: 0;
    @include dark{
      background: $n5;
    }
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  table {
    direction: ltr; /* Correct the table content direction */
  }
}

.react-table-responsive {
  border-collapse: collapse;
  width: 100%;
  cursor: default;
  overflow: auto;

  &__head {
    position: sticky;
    top: -1px;
    z-index: 1;
    height: 52px;
    background: var();
    tr {
      height: 52px;
      th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--adf-color-text-60-light);
        font-size: var(--font-size-3);
        font-weight: 600;
        max-width: 128px;
        // padding: 4px 0;
        padding-top: 10px;
        padding-bottom: 4px;
        text-align: left;
        height: 100%;
        vertical-align: middle;
        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }

  &__body {
    tr {
      border-left: 1.5px solid transparent;
      min-height: 52px;
      &.row__hover:hover {
        background-color: var(--adf-color-hover-light-100);
        transition: 0.3s ease-in all;
        border-left: 1.5px solid var(--adf-color-primary-light);
        cursor: pointer;
      }
      // td:before {
      //   content: "&nbsp;";
      //   visibility: hidden;
      // }
      td {
        padding-top: 8px;
        padding-bottom: 8px;
        // padding: var(--base-padding);
        color: var(--adf-color-text-90-light);
        // position: relative;
        border-right: none;
        font-size: var(--font-size-3);
        font-weight: 500;
        height: 100%;
        vertical-align: middle;
        > div {
          // position: absolute;
          // left: 16px;
          // right: 5px;
          // top: 16px;
          white-space: nowrap;
        }
        &.btn__text {
          > div {
            display: flex;
            align-items: center;
          }
          .text_cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .btn__Onhover {
          display: none;
        }
      }
      &:hover {
        .btn__Onhover {
          display: block;
        }
        .btn__text {
          > div {
            top: 6px;
          }
        }
      }
    }
  }
  &__sort-icon {
    margin-left: calc(var(--margin) * 0.5);
    transition: transform 0.5s, border-color 0.5s, -webkit-transform 0.5s;
  }
}
.loader-container {
  width: 100%;
  min-height: 200px;
  max-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--margin);
  &__message {
    font-weight: 600;
    text-align: center;
    font-size: var(--font-size-4);
    color: var(--adf-color-text-90-light);
  }
}
.empty-table-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--margin);
  height: calc(100% - 62px);
  &__message {
    text-align: center;
    font-size: var(--font-size-4);
    color: var(--adf-color-text-80-light);
    font-weight: 500;
  }
  &__sub-message {
    text-align: center;
    font-size: var(--font-size-2);
    color: var(--adf-color-text-60-light);
    margin-bottom: 24px;
  }
}

.react-table-responsive-wrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
}
.react-table-responsive-wrapper.react-table-responsive-fund-details-wrapper {
  height: calc(100vh - 252px);
}
.react-table-responsive-sessions-wrapper {
  height: calc(100vh - 222px);
}
.react-table-responsive-wrapper::-webkit-scrollbar {
  max-height: 10px;
  width: 8px;
  background: transparent;
}
.react-table-responsive-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
}
.react-table-responsive-wrapper:hover::-webkit-scrollbar-thumb {
  background: #51545c;
}
.table-responsive-actions__btns {
  display: flex;
  justify-content: flex-end;
  input::placeholder {
    color: var(--adf-color-text-40-light);
  }
  .input__text-field {
    background-color: var(--adf-color-bg-100-light);
  }
  .ri-search-line {
    color: var(--adf-color-text-40-light);
  }
  .input {
    width: 200px;
    margin-top: -4px;
    .input__value-container {
      .input__group {
        height: 46px;
      }
    }
  }
}
.table__head-data--label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  // margin-left: 8px;
  span {
    width: min-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-left: 2px;
  }
  &__true {
    transform: rotate(180deg);
    transition: transform 0.1s ease-out;
  }
  &__false {
    margin-left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s ease-out;
    font-size: 20px;
  }
}
.filter-btn-wrapper {
  // position: relative;
  height: 48px;
  display: flex;
}
.filters-indicator {
  width: 20px;
  height: 20px;
  border-radius: 25px;
  background: var(--adf-color-primary-light);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--adf-color-bg-100-light);
  // position: absolute;
  // right: -6px;
  // top: -7px;
}

// @AWADHESH

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  // min-height: 200px;
  // white-space: nowrap;
}

.fl-table td,
.fl-table th {
  text-align: left;
  padding: 0 8px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.fl-table thead th {
  color: #464c5c;
  // background-color: #d9e1f2;
}

.fl-table tr {
  background-color: var(--adf-color-input-background);
  border-bottom: 1px solid #d9e0f2;
}

.fl-table tr:last-child {
  border-bottom: none;
}
.email__contact {
  overflow: hidden;
  text-overflow: ellipsis;
}

.check-box {
  width: 16px;
  height: 16px;
  margin: auto 6px;
  cursor: pointer;
  accent-color: var(--adf-color-primary-light);
}

/* Responsive */

// @media (max-width: 767px) {
//   .fl-table {
//     display: block;
//     width: 100%;
//   }
//   .table-wrapper:before {
//     content: "Scroll horizontally >";
//     display: block;
//     text-align: right;
//     font-size: 11px;
//     color: white;
//     padding: 0 0 10px;
//   }
//   .fl-table thead,
//   .fl-table tbody,
//   .fl-table thead th {
//     display: block;
//   }
//   .fl-table thead th:last-child {
//     border-bottom: none;
//   }
//   .fl-table thead {
//     float: left;
//   }
//   .fl-table tbody {
//     width: auto;
//     position: relative;
//     overflow-x: auto;
//   }
//   .fl-table td,
//   .fl-table th {
//     padding: 20px 0.625em 0.625em 0.625em;
//     height: 60px;
//     vertical-align: middle;
//     box-sizing: border-box;
//     overflow-x: hidden;
//     overflow-y: auto;
//     width: 120px;
//     font-size: 13px;
//     text-overflow: ellipsis;
//   }
//   .fl-table thead th {
//     text-align: left;
//   }
//   .fl-table tbody tr {
//     display: table-cell;
//   }
//   // .fl-table tbody tr:nth-child(odd) {
//   //   background: none;
//   // }
//   // .fl-table tr:nth-child(even) {
//   //   background: transparent;
//   // }
//   // .fl-table tr td:nth-child(odd) {
//   //   background: #f8f8f8;
//   //   border-right: 1px solid #e6e4e4;
//   // }
//   // .fl-table tr td:nth-child(even) {
//   //   border-right: 1px solid #e6e4e4;
//   // }
//   .fl-table tbody td {
//     display: block;
//     text-align: center;
//   }
// }

.text-elipsis {
  max-width: 100px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.text-50px-elipsis {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-elipsis-pipeline {
  max-width: 400px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.react-table-responsive-agreement-document-modal-sessions-wrapper {
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
