.noasset-container {
  position: relative;
  left: 10%;
  transform: translatey(36%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 80%;
  padding: 2%;

    .no-data {
      margin-bottom: 20px;
      width: 150px;
    
  }

  .asset-details {

    .text-url {
      text-align: center;
      font-size: 1.5vw;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .asset-url-invalid {
      text-align: center;
      font-size: 1vw;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .contact-email {
      padding: 2px;
      font-size: 1vw;
      font-weight: 500;
      line-height: 1;
      text-align: center;


      .email-color {
        padding: 1%;
        text-align: center;
        color: #3c65d6;
      }
    }

  }
}