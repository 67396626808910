:root {
  --color-primary-default: #f2f3f7;
  --color-primary-light: #0051cc;
  --color-primary-dark: #478df5;
  --color-primary-medium: #3772FF;
  --color-accent-light: #e5ae40;
  --color-accent-dark: #f5c462;
  --color-danger-dark: #f56262;
  --color-bg-100-light: #ffffff;
  --color-bg-90-light-: #e6e8ec;
  --color-bg-100-mediumlight:#FCFCFD;
  --color-bg-110-light: #f0f0f0;
  --color-bg-90-light: #f0f4ff;
  --color-bg-80-light: #e6e6e6;
  --color-bg-100-dark: #0d0e0f;
  --color-bg-90-dark: #1b1c1f;
  --color-bg-80-dark: #282a2e;

  --color-text-80-light: #464c5c;
  --color-text-50-light: #8c97b8;
  --color-text-60-light: #747e99;
  --color-text-100-light: #17191f;
  --color-text-90-light: #2f323d;
  --color-text-70-light: #5d657a;
  --color-text-40-light: #a3b1d6;
  --color-text-110-light: #747e99;
  --color-text-120-light: #8c97b8;

  --color-input-border-light: #d7dff5;
  --color-border-90-light: #cad1e5;
  --color-input-border-focus-light: #3d7ad6;
  --color-input-border-dark: #36383d;
  --color-input-border-focus-dark: #a2a7b8;
  --color-input-background: #f5f8ff;
  --color-delete: #ef4388;
  --color-rejected: #f55353;
  --color-orange: #f5af45;
  --color-purple: #8b45f5;
  --color-pink: #f54585;
  --color-table-header: #f5f8ff;
  --color-blue: #458bf5;
  --color-gray: #878c99;
  --color-table-header-text: #1a4f9e;

  --color-label-dark: #6c707a;

  --color-header-label-dark: #1f1f1f;

  --color-white-border: #ebeef5;
  --color-success: #4aaf54;
  --color-green: #49B876;
  --color-transparent-light-100: hsla(216, 12%, 24%, 0.06);

  --color-error: #f56262;
  --color-black: #000000;
  --color-bg-modal: #ebeef5;
  --color-cancel-btn: #51545c;
  --color-bg-cancel-btn: hsla(224, 6%, 23%, 0.06);
  --color-transparent: transparent;
  --color-text-modal: #3d3d3d;
  --color-bg-copy: #7a7a7a;
  --color-input-border-light-1: #ced2de;
  --color-water-border: #c8e1f8;
  --color-outer-space-border: #3f4b5b;
  --color-waldon-blue-border: #819abb;
  --color-dodger-blue: #1e90ff;
  --color-primary-dark-blue: #0c87fd;
  --color-gray-dark: #111111;
  --color-gray-very-dark: #222222;
  --color-charcoal-dark: #333333;
  --color-gray-medium-dark: #444444;
  --color-gray-medium-light: #999999;
  --color-dark-pastel-blue: #81aace;
  --color-beau-blue: #b9d2e8;
  --color-beau-blue: #b9d2e8;
  --color-tart-orange: #f54545;
  --color-lavender-gray: #bcc3d6;
  --color-darkgunmetal: #212529;
  --color-davy-grey: #565656;
  --color-cultured: #f7f7f7;

  --color-button-bg-light-blue: #3c65d61f;
  --color-table-head-bg: #e6eaf5;
  --color-hover-light-100: hsla(224, 32%, 36%, 0.06);
  --color-davy-grey-100-light: #5c5c5c;
  --color-light-grey: #d3d3d3;

  --color-turquois: #17a2b8;
  --color-table-header: #999999;
}
