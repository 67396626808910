@import "../../../../styles/helper.scss";

.switch-account-popup {
  max-width: 430px;
  min-width: 430px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 !important;
  border: 1px solid $n6;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 1000;
  background-color: $n7 !important;

  .ri-close-line {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    color: $n4;
    cursor: pointer;
  }

  @include dark {
    border-color: $n3;
    background-color: $n17 !important;
  }

  .user-details-container {
    display: flex;
    padding-inline: 16px;
    padding-top: 10px;
    gap: 8px;

    .user-img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .user-details {
      line-height: 24px;
      flex: 1 1;
    }
    .switch-tag-wrapper{
      max-width: calc(100% - 116px);
      min-width: calc(100% - 116px);
      }

    .user-details-name {
      font-size: 20px;
      font-weight: 600;
      
    }
    .custom-tooltip{
      max-width: 300px;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .user-details-acc-id {
      font-size: 14px;
      font-weight: 500;
      color: $n4;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.create-btn {
        max-width: 100%;
        white-space: unset;
      }
    }

    .switch-tag {
      align-self: center;
      color: $n4;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      width: 100px;

      i {
        color: $n74;
        font-size: 20px;
      }
    }

    &.add-user-account {
      border: 1px solid $n6;
      border-radius: 8px;
      padding: 10px;
      cursor: pointer;

      .user-details-name {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @include dark {
        border-color: $n3;
      }
    }

    &.create-new {
      align-items: center;
      border-color: $p1 !important;

      .user-img {
        width: 48px;
        height: 48px;
      }
    }

    &.admin {
      border-radius: 0;
      border-inline: 0;
      padding-inline: 16px;
      align-items: flex-start;

      .icon {
        font-size: 24px;
        color: $n4;
        line-height: 24px;
      }
    }

    &.user-section {
      flex-direction: column;
      align-items: center;
      padding-block: 16px;

      .user-img {
        width: 72px;
        height: 72px;
        margin-bottom: 16px;
        position: relative;
        object-fit: cover;

        .ri-pencil-fill {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 12px;
          width: 24px;
          height: 24px;
          border: 1px solid $n6;
          border-radius: 50%;
          text-align: center;
          background-color: $n7 !important;
          cursor: pointer;
        }

        @include dark {
          .ri-pencil-fill {
            border-color: $n3;
            background-color: $n17 !important;
          }
        }
      }

      .user-details {
        text-align: center;
      }
    }

  }

  .user-details-account-email {
    font-size: 14px;
    font-weight: 500;
    color: $n4;
    padding-inline: 16px;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
  }

  .switch-account-list-container {
    background-color: $white;
    padding: 12px 16px;

    @include dark {
      background-color: $n1;
    }

    .switch-account-heading {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      button {
        min-height: 24px;
        height: 24px;
        font-size: 12px;
        font-weight: 500;
        gap: 2px;
        border-radius: 4px;
        padding-inline: 14px;

        i {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .account-btn-group {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding-bottom: 16px;

    .btn-divider {
      border: 1px solid #E6E8EC;
      display: inline-block;
      margin-block: 4px;
    }

    button {
      width: 183px;
      min-height: 40px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 0;

      i {
        font-size: 24px;
      }
    }

    .gray-btn {
      background-color: $n6;
      color: $n3;
    }

    @include dark {

      .btn-divider {
        border-color: $n3;
      }

      .gray-btn {
        color: $n5;
        background-color: $n72;
      }
    }
  }

  .switch-account-list {
    border-top: 1px solid $n6;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 250px;
    padding-inline: 16px;
    margin-inline: -16px;
    padding-right: 14px;

    &::-webkit-scrollbar-thumb {
      background-color: #363a45;
      border-radius: 4px;
    }

    .skeleton_Wallet {
      margin-bottom: 0;
    }

    @include dark {
      border-color: $n3;
    }

    .user-details-container {
      background-color: $n7 !important;

      @include dark {
        background-color: $n17 !important;
      }
    }
  }

}
