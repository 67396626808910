@import "../../styles/helper.scss";

.circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.loader-blue {
  border: 3px solid #3c65d6;
  border-top: 3px solid #8c97b8;
}
.loader-white {
  border: 3px solid #fff;
  border-top: 3px solid #3c65d6;
}
.loader-grey {
  border: 3px solid #8c97b8;
  border-top: 3px solid  #4574f5;
}

.white-transparent {
  border: 3px solid #fff;
  border-top-color: transparent;
}

.green-transparent {
  border: 3px solid $p4;
  border-top-color: transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
