@import "../../../../styles/common.scss";

.payment-unavailable {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid $n49;
  @include dark {
    background-color: $n19;
    border-color: $n12;
  }
  .wallet-icon {
    margin-bottom: 20px;
  }

  h2 {
    font-size: var(--font-size-5);
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: $n21;
    margin-bottom: 4px;
    @include dark {
      color: $n64;
    }
  }

  p {
    color: $n30;
    font-size: var(--font-size-4);
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    @include dark {
      color: $n9;
    }
  }
}
