@import styles/helpers

.action
    height: 100%
    display: flex
    flex-direction: column
    gap: 6px
    margin-bottom: 8px
    +m 
        padding: 0 8px
.actionSba7
    gap: 2px      

.head
    display: flex
    align-items: center
    justify-content: space-between
   
.title
    font-weight: 600
    font-size: 14px
    line-height: 20px

.counter
    +caption-bold-2
    svg
        margin-right: 4px
        fill: $n1
        +dark
            fill: $white    

.field
    display: flex
    align-items: center
    padding: 0 16px
    border-radius: 4px
    box-shadow: inset 0 0 0 1px $n6
    margin-bottom: 2px
    height: 40px
    +dark
        box-shadow: inset 0 0 0 1px $n2
   
    .inputDisable
        display: flex
        justify-content: flex-end
        align-items: center
        cursor: default
        color: $n4

.field_error
    box-shadow: inset 0 0 0 1px $n29_5 !important
    background-color: $n79

.field_error_msg
    color: $n29
    font-size: 12px
    line-height: 24px

.input
    width: 100%
    height: 40px
    padding: 0 10px
    background: none
    text-align: right
    +poppins
    font-size: 14px
    font-weight: 500
    color: $n2
    +dark
        color: $n8
    &::-webkit-inner-spin-button
        -webkit-appearance: none
    &:disabled
        color:$n4    
        +dark
            color:$n4    

.total
    flex-grow: 1
    display: flex
    justify-content: flex-end
    align-items: center
    height: 48px
    padding: 0 10px
    background: none
    text-align: right
    +poppins
    font-size: 14px
    font-weight: 500
    color: $n4
    +dark
        color: $n4

.label,
.currency
    white-space: nowrap
    font-weight: 500
    color: $n4
    pointer-events: none

.button
    width: 100%
   
   

.renderThumb
    position: absolute
    top: -27px
    color: #FCFCFD
    font-weight: 600
    font-size: 13px
    line-height: 16px
    font-family: Poppins
    padding: 2px 6px
    border-radius: 6px
    background-color:$n4

.goodTillCancel
    margin-top: 12px
    display: flex
    justify-content: space-between
.goodTillCancelTitle
    font-size: 14px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0px
    color: #141416
    display: flex
    margin-bottom: 2px
    +dark
        color: $n5
    i
        margin-left: 15px
        font-size: 16px
.goodTillCancelSubTitle    
    font-weight: 100
    font-size: 12px
    line-height: 20px
    letter-spacing: 0px
    color: #141416
    +dark
        color: $n5        
.walletSection
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-direction: column
    gap: 4px
.modifyingWalletSection
    gap: 4px    
.walletHeading
    font-size: 12px
    font-weight: 500
    line-height: 16px
    color: $n1
    display: flex
    align-items: center
    gap: 4px
    +dark
        color: $n15

.modifyingWalletHeading
    font-size: 14px
    line-height: 20px

.walletSvg
    font-size: 16px !important
    margin-right: 4px !important
    color: $n1
    +dark
        color: $n15
.walletBalance
    display: flex
    gap: 4px
    align-items: center
.modifyingWalletBalance
    display: flex
    flex-direction: row
    align-items: center

.modifyingAssetQty
    color: $n1 !important
    +dark
        color: $n14 !important

.balanceValue
    font-size: 14px
    font-weight: 500
    line-height: 20px
    height: 20px
    color: $n1
    +dark
        color: $n14
    
.kycStatusModal
  max-width: 576px !important
  padding: 24px
  border-radius: 8px
  +dark
    background: $n21 !important
   
.fees
    display: flex
    justify-content: flex-end
    font-size: 12px
    font-weight: 500
    line-height: 20px
    +dark
        color: $n9   
