@import ../../styles/helpers

.exchange
  flex: 1 1
  display: flex
  overflow-y: auto
  +t
    padding: 8px 8px
  +m
    padding: 0
    overflow: auto

  +dark
    background: $n1

.nav
  display: none
  +t
    display: none
  +m
    justify-content: space-between

.link
  display: flex
  padding: 6px 12px
  border-radius: 14px
  background: none
  +button-2
  color: $n3
  transition: all .2s

  &.active
    background: $n3
    color: $n8
    +dark
      background: $n2
      color: $n8

  &:not(:last-child)
    margin-right: 8px
    +m
      margin-right: 0

.col
  &:first-child
    flex: 1 1
    min-width: 288px
    max-width: 384px
    display: flex
    +d
      float: left
    +t
      //display: none
      float: none
      width: 100%

  &:nth-child(2)
    overflow-y: auto
    display: flex
    flex-direction: column
    flex: 1 1
    +d
      float: right
      width: calc(100% - 260px)
    +t
      float: none
      min-width: 100%
      margin: 0
    +m
      max-height: 100%

.chat_dropdown
  position: absolute
  bottom: 0
  right: 0

.col2_row2
  width: 100%
  padding: 8px
  display: flex
  flex-direction: column
  gap: 8px
  overflow-y: scroll
  +scroll
  +t
    overflow-y: unset

  .row2_row2
    display: grid
    gap: 8px
    padding: 0px 8px
    +t
      gap: 8px
      +m
        display: flex
        flex-direction: column
  .row2_row2_grid
    grid-template-columns: 2fr 1fr
    +t
      grid-template-columns: repeat(2, 1fr)

  .loaderContainer
    margin: 0 8px
    padding: 300px 0
    border: 1px solid $n6
    border-radius: 8px
    +dark
        border-color: $n2
  .sbaAuction
    padding: 10px
    flex: 1 1
  .row2_row3
    width: 100%
    padding: 0px 8px
    +t
      padding: 0 8px

  .row2_row6
    width: 100%
    padding: 0px 8px
    +t
      padding: 0 8px

.overview
  width: 100%
  padding: 0px 8px


.wrapper
  padding: 0px
  position: relative
  flex: 1 1
  +t
    overflow-y: auto
    overflow-x: hidden

.tab_balance_wraper
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 8px
  padding-top: 8px
  +m
    display: flex
    flex-direction: column

.tab_scroll_wraper
  overflow-y: auto
  overflow-x: hidden
  +m
    overflow: unset

.row2_row1
  padding: 8px 8px 0px
  .prodImg
    width: 100%
    height: 293px
    img
      width: 100%
      height: 100%
  .prodLoader
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%

.logout_btn
  background-color: #3c65d6
  color: white
  font-size: 14px
  font-weight: 500
  width: 85%
  max-width: 70px
  margin-top: -20px
  align-self: center
  border-radius: 8px
  padding: 16px 24px

.Modal__wrapper
  width: 100%
  display: flex
  justify-content: center
  height: 95vh
  padding: 0px

.music_chart
  width: 100%
  padding: 0px 8px 8px

.disclaimerFooter
  font-size: 10px
  padding: 16px 16px 16px
  line-height: 12px
  +dark
    background: #141416

  &__containerDiv
      margin-bottom:4px

  span
    line-height: 16px
    a
      color: blue

  .dsLink
    line-height: 14px
    
@media ((max-device-width: 480px))
  .row2_row1
    padding: 8px 16px 0
  .row2_row2, .row2_row3
    padding: 0 16px !important
  .wrapper
    overflow-x: unset !important
    overflow-y: unset !important
  .overview
    padding: 0
  .disclaimerFooter
    padding-bottom: 100px
