@import styles/helpers

.orders
    height: 100%
    max-height: 384px
    border: 1px solid $n6
    background: $n8
    border-radius: 8px
    transition: all 0.3s ease-out
    +dark
        background: $dark
        border-color: $n2

.head    
    position: sticky
    top: 0
    z-index: 2
    background: $white
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    +dark
        background: $dark
    
.nav
    display: flex
    overflow-x: scroll
    border-bottom: 1px solid $n6
    &::-webkit-scrollbar
        display: none
    +dark
        border-bottom: 1px solid $n2

.nav_header
    justify-content: space-between
    align-items: center

.link
    display: flex
    padding: 16px 16px
    background: none
    text-transform: capitalize
    min-width: max-content
    +poppins
    font-weight: 600
    font-size: 12px
    color: #777E91
    transition: all .2s
    border-bottom: 2px solid transparent
    &:hover
        color: $blue
        +dark
            color: $n10
    &.active
        color: $blue
        border-bottom: 2px solid $blue
        +dark
            color: $n10
            border-bottom: 2px solid $n10

.collapse
    width: 28px
    height: 28px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    font-size: 20px
    cursor: pointer
    margin-right: 16px

.collapse_true
    transition: transform 0.3s ease-out
    transform: rotate(180deg)

.collapse_false
    transition: transform 0.3s ease-out
    
.collapse_orders
    height: 100%
    min-height: 168px
    max-height: 168px
    border: 1px solid $n6
    background: $n8
    border-radius: 8px
    +dark
        background: $dark
        border-color: $n2

@media ((max-device-width: 480px))
    .head
        top: 58px !important