@import styles/helpers

.outerTable
    display: grid
    gap: 8px
    +t
      gap: 8px
      +m
        display: flex
        flex-direction: column
.outerTableGrid
    grid-template-columns: 1.3fr 3fr !important
    +t
    grid-template-columns: repeat(2, 1fr)

.balance
    height: 100%
    overflow: hidden
    background: $n8
    border: 1px solid $n6
    border-radius: 8px
    +dark
        background: $dark
        border-color: $n2
    +t
        height: 100%

.head
    display: flex
    align-items: center
    padding: 8px 16px
    gap: 8px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n2

.container
    width: 100%
    display: flex
    .contents
        width: 50%

.orderBook
    display: flex
    align-items: center
    margin-right: auto
    color: $n4
    font-weight: 600
    font-size: 14px

.direction
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 32px
    height: 32px
    border-radius: 4px
    transition: all .2s
    span
        width: 12px
        height: 2px
        border-radius: 1
        &:not(:last-child)
            margin-bottom: 2px
    &:hover,
    &.active
        background: #F1F2F4
        +dark
            background: $n2
    &:not(:last-child)
        margin-right: 12px

.balance
    .dropdownOrderBook
        width: 100px
    .dropdownHead
        height: 24px
        padding: 0 36px 0 8px
        background: #F1F2F4
        border-radius: 4px
        box-shadow: none
        font-size: 12px
        line-height: 24px
        +dark
            box-shadow: inset 0 0 0 2px $n3
            background: none

.dropdownArrow
    right: 0
    svg
        fill: $n4

.dropdownBody
    border-radius: 4px

.dropdownOption
    padding: 4px 8px
    font-size: 12px

.top
    display: flex
    margin-bottom: 4px
    padding: 6px 16px
    gap: 10px
    +caption-bold-2
    color: $n4
    border-bottom: 1px solid $n6
    +dark
        border-color: $n2

.list
    min-height: 270px
    max-height: 270px
    overflow: auto
    border-bottom: 1px solid $n6
   
    +dark
        border-color: $n2
    +scroll

.item
    position: relative
    display: flex
    gap: 4px
    padding: 4px 16px 0
    +caption-2
    font-weight: 500
    &.negative
        .line
            background: rgba($p3, .15)
        .price
            color: $p3
            display: flex
            line-height: 22px
    &.positive
        .line
            background: rgba($p4, .15)

        .price
            color: $p4
            display: flex
            line-height: 22px
    &:last-child
        padding-bottom: 4px 
    &:nth-child(n+10)
        +d
            display: none
        +t
            display: flex

.price
    font-weight: 600
    min-width: 120px
    display: flex
    align-items: center
    justify-content: flex-start

.price,
.amount
    flex-shrink: 0

.bidChatbtn
    color: #1472FF
    margin-left: 10px
    display: inline-flex
    align-items: center
    gap: 5px
    font-size: var(--font-size-3)
    font-weight: 500
    &[disabled]
        cursor: not-allowed
    i
        font-size: 20px
    
.askChatbtn
    color: #1472FF
    margin-left: 10px
    display: inline-flex
    align-items: center
    gap: 5px
    font-size: var(--font-size-3)
    font-weight: 500
    &[disabled]
        cursor: not-allowed
    i
        font-size: 20px

.primaryIssuance
    display: inline-block
    padding: 0px 8px
    background-color: #9537F31F
    color: #9537F3
    font-size: 12px
    font-weight: 500
    border-radius: 4px
    text-align: center
    white-space: nowrap
    margin-left: 10px

.quantity
    display: flex
    gap: 4px
    justify-content: flex-end
    width: 100%
    align-items: center
    flex-flow: wrap

.total
    display: flex
    justify-content: space-between
    padding: 8px 16px
    gap: 10px
    font-weight: 600
    color: $n4

.totalValue
    color: $n1
    +dark
        color: $white

.line
    position: absolute
    top: 0
    right: 0
    bottom: 0

.statistics
    display: flex
    align-items: center
    margin-top: 12px
    padding: 12px 16px
    +body-bold-2
    +d
        margin-top: 4px
    +t
        margin-top: 12px
    svg
        margin-left: 8px
    &:not(:last-child)
        margin-bottom: 12px
        +d
            margin-bottom: 0
        +t
            margin-bottom: 12px
    &.positive
        .currency
            color: $p4
        svg
            fill: $p4
    &.negative
        .currency
            color: $p3
        svg
            fill: $p3

.money
    margin-left: 8px
