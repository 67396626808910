@import "../../../styles/helper";

.react-table-responsive {
  .startTime {
    font-size: 12px;
    font-weight: 500;
    color: #747e99;
  }
  .auction-delete-btn {
    background: none;
    color: var(--color-primary-60);
    font-size: 17px;
    padding: 12px;
  }
  .row-arrow {
    font-size: 18px;
    color: #747e99;
    padding-right: 12px;
  }

  .collapsible_row {
    background: #d7dff5;
    border-top: 1px solid #e6f7ff;
    border-bottom: 1px solid #e6f7ff;
    @include dark {
      border-top: 1px solid #e6f7ff;
      border-bottom: 1px solid #e6f7ff;
      background: #36383d;
    }
    .react-table-responsive-wrapper {
      min-height: unset;
    }
  }
}

.auction-list {
  .react-table-responsive__head-row {
    background: #f5f5f5 !important;
    @include dark {
      background-color: #36383d !important;
    }
    .table__head-data {
      color: var(--color-gray) !important;
      padding: 0;
    }
  }
  .react-table-responsive__body {
    tr {
      background: transparent;
      td {
        padding: 0;
        &.arrow {
          padding-left: 12px;
        }
        .text-elipsis {
          max-width: unset;
        }
      }
    }
    .collapssed_row {
      background: #f3f6fd;
      cursor: pointer;
      @include dark {
        background: #282a2e;
      }
    }
  }
}

.collapssed_row {
  .right-arrow {
    display: block;
  }
  .down-arrow {
    display: none;
  }
  &.open_row {
    .right-arrow {
      display: none;
    }
    .down-arrow {
      display: block;
    }
  }
}

.auction__container_list {
  display: flex;
  flex-direction: column;
  padding: 24px 8px 10px 16px;
  gap: 16px;
  flex: 1 1;
  overflow-y: auto;
}
