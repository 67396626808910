@import "../../../../styles/helper.scss";

.latest-bids-container {
  flex: 1 1;
  border-radius: 4px;

  background-color: $white;
  @include dark {
    background-color: $dark;
  }

  .latest-bids-heading {
    color: $s5;
    font-size: 18px;
    padding: 15px;
    font-weight: 600;

    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 4px;
      background-color: $s5;
      border-radius: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .bids-list {
    padding-inline: 10px;
    overflow-y: auto;
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  .no-data-found {
    flex: 1 1;
    font-size: 16px;

    img {
      width: 130px;
    }
  }

  .latest-bid-card {
    display: flex;
    align-items: center;
    padding-block: 5px;
    margin-bottom: 10px;

    &.align-top {
      align-items: flex-start;
    }

    .bid-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .bid-detail {
      flex: 1 1;
      padding-left: 10px;
      text-transform: capitalize;

      &.right {
        text-align: right;
        flex: 0;
        white-space: nowrap;
      }

      .name {
        font-size: 14px;
        line-height: normal;
        font-weight: 500;
      }

      .time {
        font-size: 12px;
        font-weight: 500;
        color: $n4;
      }

      @include dark {
        .name {
          color: $white;
        }

        .time {
          color: $n5;
        }
      }
    }

    .bid-price {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
