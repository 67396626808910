@import ../../../../../../styles/helpers
.manage_removalContainer
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    +dark
        background: #282a2e
        

.manage_removal
    padding: 16px 24px
    font-size: 16px
    font-weight: 600
    line-height: 24px
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    border-bottom: 1px solid #e6e8ec
    +dark
        background: #282a2e
        border-bottom: 1px solid #43464d
   
    
.manage_removalSection
    margin: 16px 24px
    
    &__details
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 30px
        font-size: 14px
        font-weight: 600
        line-height: 24px
        border-bottom: 1px solid lightgray
        
        +dark
            background: #282a2e
            border-bottom: 1px solid #36383D
    &__dateHeading
        display: flex
        justify-content: space-between
        align-items: center
        font-size: 14px
        font-weight: 500
        line-height: 20px
        margin-top: 12px
        color: #777E90
        +dark
            color: #BCC3D6
    &__date
        color: #777E90
        +dark
            color: #BCC3D6        











.documentHeader
    display: flex
    justify-content: space-between
    align-items: center
    margin: 16px 24px
    border-bottom: 1px solid lightgray

    +dark
        background: #282a2e
        border-bottom: 1px solid #36383D
    
    &__document
    font-size: 14px
    font-weight: 600
    line-height: 24px
    color: #353945
    +dark
        color: #E6EAF5
    &__file
        font-size: 12px
        font-weight: 400
        line-height: 16px
        color: #A2A7B8
.fileLogo
    background: #e6e8ec
    padding: 8px
    height: 40px
    width: 40px
    border-radius: 32px
    display: flex
    justify-content: center
    align-items: center
    +dark
        background:#36383D
    img
        width: 22px
  

.fileSection
    height:100px
    overflow-y: scroll
    display: flex
    flex-direction: column
    gap: 8px
.sectionHeight
    // height: 150px    
        
.fileContainer
    display: flex
    justify-content: space-between
    gap: 8px
    align-items: center  
    margin: 0px 24px

.fileLeft
    display: flex
    justify-content: space-between
    align-items: center 
    gap: 12px        

.fileDetails
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    font-size: 14px
    font-weight: 500
    line-height: 24px
    &__name
        color: #777E90
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        max-width: 180px
        +dark
            color: #E6EAF5
    &__size
        color: #777E90
        +dark
            color :#A2A7B8
       
.viewButton
    display: flex
    gap:8px
    margin-right: 5px
    justify-content: center
    align-items: center    
    width: 77px
    height: 32px
    padding: 8px 12px 8px 12px
    border-radius: 4px
    border: 1px solid #4574F5
    font-size: 14px
    font-weight: 500
    color: #4574F5
